/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import {
  reservationId,
  mediaUrl,
  pickupId,
  deliveryId,
  cateringId,
  noimage470,
  CountryTxt,
  mapcountry,
  dineinId,
  language,
} from "../Helpers/Config";
import { timeToConv12 } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";
var Parser = require("html-react-parser");
class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      outlets_count: 0,
      globalsettings: [],
      starttime: "",
      endtime: "",
    };

    var site_maintenance_verify =
      cookie.load("site_maintenance_verify") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_verify") !== undefined
        ? cookie.load("site_maintenance_verify")
        : "";
    var site_maintenance_popup_open =
      cookie.load("site_maintenance_popup_open") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_popup_open") !== undefined
        ? cookie.load("site_maintenance_popup_open")
        : "";

    if (
      site_maintenance_popup_open === "1" &&
      (site_maintenance_verify === "" || site_maintenance_verify === "0")
    ) {
      this.props.history.push("/");
      return;
    }
  }

  componentDidMount() {
    var availability =
      pickupId +
      ";" +
      deliveryId +
      ";" +
      reservationId +
      ";" +
      cateringId +
      ";" +
      dineinId;
    // var availability = "";
    this.props.getAllOutlets(availability);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      console.log(nextProps.outlets, "outlets");
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        });
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  tConvert(time) {
    if (
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    }
  }

  allInOnepopup() {
    cookie.save("defaultAvilablityId", reservationId, { path: "/" });
    this.props.history.push("/reservation");
  }

  gotoContacus(outletId) {
    cookie.save("contusOutletId", outletId, { path: "/" });
    this.props.history.push("/contact-us");
  }

  callPhoneOptn(phoneTxt, type) {
    var resultTxt = "";
    if (type == 1) {
      //for <a> tag
      if (phoneTxt !== "") {
        if (mapcountry === "sg") {
          if (phoneTxt.indexOf("+65") !== -1) {
            resultTxt = "tel:" + phoneTxt;
          } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
            resultTxt = "tel:+" + phoneTxt;
          } else {
            resultTxt = "tel:+65" + phoneTxt;
          }
        } else {
          if (phoneTxt.indexOf("+63") !== -1) {
            resultTxt = "tel:" + phoneTxt;
          } else if (phoneTxt.indexOf("63") !== -1 && phoneTxt.length >= 10) {
            resultTxt = "tel:+" + phoneTxt;
          } else {
            resultTxt = "tel:+63" + phoneTxt;
          }
        }
      } else {
        resultTxt = "javascript:void(0);";
      }
    } else {
      //for view
      if (phoneTxt !== "") {
        if (mapcountry === "sg") {
          if (phoneTxt.indexOf("+65") !== -1) {
            resultTxt = "" + phoneTxt;
          } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
            resultTxt = "+" + phoneTxt.replace(" ", "");
            resultTxt = resultTxt.replace("+65", "+65 ");
          } else {
            resultTxt = "+65 " + phoneTxt;
          }
        } else {
          if (phoneTxt.indexOf("+63") !== -1) {
            resultTxt = "" + phoneTxt;
          } else if (phoneTxt.indexOf("63") !== -1 && phoneTxt.length >= 10) {
            resultTxt = "+" + phoneTxt.replace(" ", "");
            resultTxt = resultTxt.replace("+63", "+63 ");
          } else {
            resultTxt = "+63 " + phoneTxt;
          }
        }
        resultTxt = resultTxt.replace(/\d{4}(?=.)/g, "$& ");
      } else {
        resultTxt = "";
      }
    }

    return resultTxt;
  }

  timingCnt() {
    return <span>{language.outlettime}</span>;
  }

  getOutletData = (dataProp) => {
    if (dataProp) {
      var existOutlet = [];
      return dataProp.map((item, index) => {
        if (
          existOutlet.indexOf(item.outlet_id) < 0 &&
          item.outlet_id !== "321" &&
          item.outlet_id !== "582"
        ) {
          existOutlet.push(item.outlet_id);
          return (
            <li key={index}>
              <div className="ourrest_row">
                <div className="ourrest_img">
                  {item.outlet_image !== "" &&
                  typeof item.outlet_image !== undefined &&
                  typeof item.outlet_image !== "undefined" ? (
                    <img
                      src={mediaUrl + "outlet/" + item.outlet_image}
                      alt={stripslashes(item.outlet_name)}
                    />
                  ) : (
                    <img
                      className="media-object"
                      src={noimage470}
                      alt={stripslashes(item.outlet_name)}
                    />
                  )}
                </div>
                <div className="ourrest_info">
                  <h4>{stripslashes(item.outlet_name)}</h4>
                  <p className="ourrest_info_address">
                    {item.outlet_address_line1}
                    {item.outlet_unit_number2 !== ""
                      ? " #" +
                        item.outlet_unit_number1 +
                        "-" +
                        item.outlet_unit_number2
                      : item.outlet_unit_number1 !== ""
                      ? " #" + item.outlet_unit_number1
                      : ""}{" "}
                    {CountryTxt} - {item.outlet_postal_code}
                  </p>
                  <p>
                    {item.outlet_phone !== "" && item.outlet_phone !== null && (
                      <div className="outlet_unit_phone">
                        <strong>
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          {language.tel}:
                        </strong>{" "}
                        {item.outlet_phone !== "" && (
                          <a href={this.callPhoneOptn(item.outlet_phone, 1)}>
                            {this.callPhoneOptn(item.outlet_phone, 2)}
                          </a>
                        )}
                      </div>
                    )}{" "}
                    {item.outlet_fax_no !== "" &&
                      item.outlet_fax_no !== null && (
                        <div className="outlet_unit_fax">
                          <strong>
                            <i className="fa fa-fax" aria-hidden="true"></i>{" "}
                            {language.fax}:
                          </strong>{" "}
                          {item.outlet_fax_no}
                        </div>
                      )}{" "}
                  </p>

                  <p className="ourrest_info_ohours">
                    {item.outlet_email !== "" && (
                      <div className="outlet_unit_mail">
                        <strong>
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          {language.email} :
                        </strong>
                        <span>
                          <a href={"mailto:" + item.outlet_email}>
                            {item.outlet_email}
                          </a>
                        </span>
                      </div>
                    )}
                  </p>

                  <p>
                    {" "}
                    <strong>{language.operatinghour} : </strong>
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.mon}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_monday_end_time !== null &&
                        item.da_monday_start_time !== null
                          ? timeToConv12(item.da_monday_start_time) +
                            " - " +
                            timeToConv12(item.da_monday_end_time)
                          : language.closed}
                      </div>
                    </div>
                    <br />
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.tue}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_tuesday_end_time !== null &&
                        item.da_tuesday_start_time !== null
                          ? timeToConv12(item.da_tuesday_start_time) +
                            " - " +
                            timeToConv12(item.da_tuesday_end_time)
                          : language.closed}
                      </div>
                    </div>
                    <br />
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.wed}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_wednesday_end_time !== null &&
                        item.da_wednesday_start_time !== null
                          ? timeToConv12(item.da_wednesday_start_time) +
                            " - " +
                            timeToConv12(item.da_wednesday_end_time)
                          : language.closed}
                      </div>
                    </div>
                    <br />
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.thu}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_thursday_end_time !== null &&
                        item.da_thursday_start_time !== null
                          ? timeToConv12(item.da_thursday_start_time) +
                            " - " +
                            timeToConv12(item.da_thursday_end_time)
                          : language.closed}
                      </div>
                    </div>
                    <br />
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.fri}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_friday_end_time !== null &&
                        item.da_friday_start_time !== null
                          ? timeToConv12(item.da_friday_start_time) +
                            " - " +
                            timeToConv12(item.da_friday_end_time)
                          : language.closed}
                      </div>
                    </div>
                    <br />
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.sat}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_saturday_end_time !== null &&
                        item.da_saturday_start_time !== null
                          ? timeToConv12(item.da_saturday_start_time) +
                            " - " +
                            timeToConv12(item.da_saturday_end_time)
                          : language.closed}
                      </div>
                    </div>
                    <br />
                    <div className="ohrs-main">
                      <div className="ohrs-lhs">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        {language.sun}
                      </div>
                      <div className="ohrs-rhs">
                        :{" "}
                        {item.da_sunday_end_time !== null &&
                        item.da_sunday_start_time !== null
                          ? timeToConv12(item.da_sunday_start_time) +
                            " - " +
                            timeToConv12(item.da_sunday_end_time)
                          : language.closed}
                      </div>
                    </div>
                  </p>

                  <ul className="ourrest_infolinks">
                    <li className="media-links-b li-full-width">
                      <a
                        href="/menu"
                        rel="nofollow"
                        className="readmore font-headings"
                      >
                        {language.ordernow}{" "}
                      </a>
                    </li>
                  </ul>
                  {item.outlet_informations !== "" &&
                  item.outlet_informations !== null
                    ? Parser(item.outlet_informations)
                    : ""}
                </div>
              </div>
            </li>
          );
        }
      });
    }
  };

  getOutletCount(dataProp) {
    var outlets_count = 0;
    if (dataProp) {
      var existOutlet = [];

      dataProp.map((item, index) => {
        if (
          existOutlet.indexOf(item.outlet_id) < 0 &&
          item.outlet_id !== "321"
        ) {
          existOutlet.push(item.outlet_id);
          outlets_count = existOutlet.length;
        }
      });
    }

    return outlets_count;
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>
              {" "}
              {this.getOutletCount(this.state.outlets) > 1
                ? language.ourlocations
                : language.Ourlocation}{" "}
            </p>
          </div>
        </div>
        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <ul className="outletul">
                {this.getOutletData(this.state.outlets)}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
