import React, { Component } from "react";
import cookie from "react-cookies";
import { showAlert } from "../Helpers/SettingHelper";
import { language } from "../Helpers/Config";

class Rfcode extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
    cookie.remove("rfcodeAction");
    cookie.remove("referCode");

    var userIdTxt =
      cookie.load("UserId") === "" ||
      cookie.load("UserId") === undefined ||
      cookie.load("UserId") === "undefined"
        ? ""
        : cookie.load("UserId");

    if (userIdTxt !== "") {
      showAlert(language.error, language.accountactivenow);
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    } else if (slugtext !== "") {
      cookie.save("rfcodeAction", "Yes");
      cookie.save("referCode", slugtext);
    } else {
      cookie.remove("rfcodeAction");
      cookie.remove("referCode");
    }

    this.props.history.push("/");
  }

  render() {
    return <div className="rfcode-main-div"> {language.loading}...</div>;
  }
}

export default Rfcode;
