/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { baseUrl, language } from "../Helpers/Config";
import { GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL } from "../../actions";
import cookie from "react-cookies";
import rewardImg from "../../common/images/reward-wrk.png";
import facebook from "../../common/images/facebookreward.svg";
import instagram from "../../common/images/instagramreward.svg";
import iconshare from "../../common/images/icon-share.png";
class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerdetails: "",
    };
    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }
  componentDidMount() {
    this.getActivityCounts();
    $("#dvLoading").fadeOut(2000);
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["reward", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
    $("#dvLoading").fadeOut(2000);
    var params = "&status=A&refrence=" + cookie.load("UserId");
    this.props.getCustomerDetail(params);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customerdetail !== this.props.customerdetail) {
      if (nextProps.customerdetail[0].status === "ok") {
        this.setState({
          customerdetails: nextProps.customerdetail[0].result_set,
        });
      }
    }
  }

  copyCurrentURL(text, type, e) {
    e.stopPropagation();
    e.preventDefault();
    var dummy = document.createElement("textArea");
    document.body.appendChild(dummy);
    dummy.value = type === "code" ? text : baseUrl + "rfcode/" + text;
    var range, selection;
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      range = document.createRange();
      range.selectNodeContents(dummy);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      dummy.setSelectionRange(0, 999999);
    } else {
      dummy.select();
    }

    document.execCommand("copy");
    document.body.removeChild(dummy);
    var toolBx = type === "code" ? "copied-code" : "copied-url";
    $("." + toolBx)
      .fadeIn()
      .delay(1000)
      .fadeOut();
  }

  render() {
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>{language.myaccount}</h3>
            <p class="acc-para">{language.myaccmsg}</p>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menuout"></div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section account_sec rewards-newdesign">
                    <Sidebar pageName="referral" />
                    <div class="box_in accsec_right">
                      <div class="account_sec">
                        {cookie.load("userAccountType") !== "2" ? (
                          <div className="accsec_right">
                            <div className="mainacc_mobrow">
                              <div className="tab_sec main_tabsec_inner">
                                <div className="myacc_filter">
                                  <div className="reward-maindiv">
                                    <div className="reward-innerdiv">
                                      <h3>{language.refermsg1}</h3>
                                      <div className="reward-innerdiv-lhs">
                                        <ul>
                                          <li>
                                            <div className="reward-inner-number">
                                              {" "}
                                              <span>1</span>
                                            </div>
                                            <div className="reward-inner-content">
                                              {" "}
                                              <p>{language.refermsg2}</p>{" "}
                                            </div>
                                          </li>

                                          <li>
                                            <div className="reward-inner-number">
                                              {" "}
                                              <span>2</span>
                                            </div>
                                            <div className="reward-inner-content">
                                              {" "}
                                              <p>{language.refermsg3}</p>{" "}
                                            </div>
                                          </li>

                                          <li>
                                            <div className="reward-inner-number">
                                              {" "}
                                              <span>3</span>
                                            </div>
                                            <div className="reward-inner-content">
                                              {" "}
                                              <p>{language.refermsg4}</p>{" "}
                                            </div>
                                          </li>
                                        </ul>
                                        <div className="reward-bottom-content">
                                          <p>{language.refermsg5}</p>
                                        </div>
                                      </div>

                                      <div className="reward-innerdiv-rsh">
                                        <div className="reward-rsh-imgdiv">
                                          <img src={rewardImg} />
                                        </div>
                                      </div>

                                      {this.state.customerdetails !== "" && (
                                        <div className="reward-overall-bottom">
                                          <div className="reward-bottom-buttondiv reward-bottom-leftdiv">
                                            <ul>
                                              <li className="reward-code-heading">
                                                {language.yourreferralcode}
                                              </li>
                                              <li className="reward-bottom-span">
                                                <span className="reward-bottom-left">
                                                  {
                                                    this.state.customerdetails
                                                      .customer_referrar_code
                                                  }
                                                </span>
                                                <span
                                                  className="reward-bottom-right"
                                                  onClick={this.copyCurrentURL.bind(
                                                    this,
                                                    this.state.customerdetails
                                                      .customer_referrar_code,
                                                    "code"
                                                  )}
                                                >
                                                  {language.copy}
                                                </span>
                                              </li>
                                              <li
                                                className="copied-code"
                                                style={{ display: "none" }}
                                              >
                                                {language.copied}
                                              </li>
                                            </ul>
                                          </div>

                                          <div className="reward-bottom-buttondiv reward-bottom-rightdiv hidden">
                                            <ul>
                                              <li className="reward-bottom-heading">
                                                {language.invitefriendsnow}
                                              </li>
                                              <li className="reward-bottom-icon">
                                                <span className="icon-link">
                                                  <a
                                                    href="https://www.instagram.com/"
                                                    target="_blank"
                                                  >
                                                    <img
                                                      alt="Facebook"
                                                      src={instagram}
                                                    />
                                                  </a>
                                                </span>
                                              </li>

                                              <li className="reward-bottom-icon">
                                                <span className="icon-link">
                                                  <a
                                                    href="https://www.facebook.com/"
                                                    target="_blank"
                                                  >
                                                    <img
                                                      alt="Facebook"
                                                      src={facebook}
                                                    />
                                                  </a>
                                                </span>
                                              </li>

                                              <li className="reward-bottom-icon">
                                                <span className="icon-link">
                                                  <a
                                                    href=""
                                                    onClick={this.copyCurrentURL.bind(
                                                      this,
                                                      this.state.customerdetails
                                                        .customer_referrar_code,
                                                      "url"
                                                    )}
                                                  >
                                                    <img
                                                      alt="Copy Link"
                                                      src={iconshare}
                                                    />
                                                  </a>
                                                </span>
                                              </li>
                                            </ul>
                                            <ul>
                                              <li
                                                className="copied-url"
                                                style={{ display: "none" }}
                                              >
                                                {language.copied}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="accsec_right">
                            <div className="mainacc_mobrow">
                              <div className="tab_sec main_tabsec_inner">
                                <div className="profile-info-div">
                                  <h4 className="form_grouptt">
                                    {language.guestloginpermisonerror}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
    customerdetail: state.customerdetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};
Referral.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Referral)
);
