/* eslint-disable */
/* global google */
import React from "react";
import { language } from "../Helpers/Config";
class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orderHandled: "" };
  }

  componentDidMount() {
    var input = document.getElementById("pac-input");
    var options = { componentRestrictions: { country: this.props.mapcountry } };

    var autocomplete = new google.maps.places.Autocomplete(input, options);
    var currnetThis = this;
    autocomplete.addListener("place_changed", function () {
      document.getElementById("location-error").style.display = "none";
      var place = autocomplete.getPlace();
      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();
      var locationAddress = place.formatted_address;
      var locationname = place.name !== "" ? place.name + " " : "";
      var addressdetails =
        locationname +
        "" +
        locationAddress +
        "~~" +
        latitude +
        "~~" +
        longitude;
      currnetThis.props.sateValChange("address", addressdetails);
    });
  }

  render() {
    return (
      <div className="pac-cardinfo" id="pac-card">
        <div id="pac-containerinfo">
          <input
            id="pac-input"
            className="location_address_filed"
            name="location_address"
            type="text"
            placeholder={language.searchplaces}
          />
          <div
            id="location-error"
            className="error"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Autocomplete;
