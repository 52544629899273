/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { stripslashes } from "../Helpers/SettingHelper";
import { cateringId, language, productNoImg } from "../Helpers/Config";
import cookie from "react-cookies";
import { GET_CATEGORY_LIST } from "../../actions";
var Parser = require("html-react-parser");

class Maincategory extends Component {
  constructor(props) {
    super(props);
    this.state = { categorylist: [], imagepath: [], displayCategory: "" };
  }
  componentDidMount() {
    this.props.getCategoryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categorylist.length > 0) {
      if (this.state.categorylist !== nextProps.categorylist) {
        if (Object.keys(nextProps.categorylist).length > 0) {
          if (nextProps.categorylist[0].status === "ok") {
            this.setState(
              {
                categorylist: nextProps.categorylist[0].result_set,
                imagepath: nextProps.categorylist[0].common,
              },
              function () {
                this.displayCategory();
              }
            );
          }
        }
      }
    }
  }
  gotoCatering(link, event) {
    event.preventDefault();
    if (
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
    ) {
      if (cookie.load("defaultAvilablityId") !== cateringId) {
        cookie.save("defaultAvilablityId", cateringId, { path: "/" });
        this.props.PropsData.history.push(link);
      } else {
        this.props.PropsData.history.push(link);
      }
    } else {
    }
  }
  displayCategory() {
    let cat,
      firstcategory = "";
    if (this.state.categorylist !== "") {
      if (this.state.categorylist.length > 0) {
        cat = this.state.categorylist.map((item, index) => {
          if (item.subcategory.length > 0) {
            return (
              <li key={index}>
                <Link
                  to={
                    "/catering/" +
                    item.pro_cate_slug +
                    "/" +
                    item.subcategory[0].pro_subcate_slug
                  }
                >
                  <div className="home-option-parent">
                    <div className="home-option-img">
                      {item.pro_cate_image !== "" &&
                      item.pro_cate_image !== null ? (
                        <img
                          src={
                            this.state.imagepath.cat_image_source +
                            "/" +
                            item.pro_cate_image
                          }
                        />
                      ) : (
                        <img src={productNoImg} />
                      )}
                    </div>
                    <div className="home-option-desc">
                      <h5>{stripslashes(item.pro_cate_name)}</h5>
                      {item.pro_cate_short_description !== "" &&
                      item.pro_cate_short_description !== null
                        ? Parser(item.pro_cate_short_description)
                        : ""}
                    </div>
                  </div>
                </Link>
                <div className="mobile-choose-option-hover">
                  <ul className="imp-button-mob">
                    <li>
                      <ul>
                        {item.subcategory !== "" &&
                          item.subcategory.map((subitem, index1) => {
                            return (
                              <li key={index1}>
                                <Link
                                  to="/"
                                  onClick={this.gotoCatering.bind(
                                    this,
                                    "/catering/" +
                                      item.pro_cate_slug +
                                      "/" +
                                      subitem.pro_subcate_slug
                                  )}
                                >
                                  {subitem.pro_subcate_name}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
            );
          }
        });
      }
    }
    if (firstcategory !== "") {
    }
    this.setState({ displayCategory: cat });
  }
  render() {
    return (
      <>
        <Header />
        <div className="product_container">
          <div className="common-inner-blckdiv">
            <div className="common-inner-banner">
              <h1>{language.ourcateringmenu}</h1>
            </div>
          </div>
          <section className="catering-options textcenter">
            <div className="search-product">
              <div className="container">
                <div className="catering-options-inner">
                  <ul>{this.state.displayCategory}</ul>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    categorylist: state.categorylist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Maincategory);
