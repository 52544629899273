/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import { GET_REQUESTPAGEDATA, GET_RES_ORDER_SUBMIT } from "../../actions";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import moment from "moment";
import { appId, reservationId, language } from "../Helpers/Config";

class Reservationsummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagedetail: "",
      reservation_date: cookie.load("reservation_date"),
      reservation_time: cookie.load("reservation_time"),
      reservation_adult: cookie.load("reservation_adult"),
      reservation_child: cookie.load("reservation_child"),
      reservation_outlet_name: cookie.load("reservation_outlet_name"),
      reservation_user_name: cookie.load("UserFname"),
      reservation_user_mobile: cookie.load("reservation_mobile_no"),
      reservation_user_email: cookie.load("UserEmail"),
      reservation_notes: cookie.load("reservation_notes"),
      reservation_show_date: cookie.load("reservation_show_date"),
      reservation_show_time: cookie.load("reservation_show_time"),
      reservation_end_time: cookie.load("reservation_end_time"),
      RerservationStatus: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.RerservationStatus !== this.state.RerservationStatus) {
      hideLoader("res-cont-div", "class");

      if (
        nextProps.RerservationStatus === "success" &&
        nextProps.RerservationOrderlocalId !== ""
      ) {
        this.props.history.push("/reservation-thankyou");
      }

      if (nextProps.RerservationStatus === "failure") {
        showAlert(language.error, language.sorrytryagain);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }

      this.setState({ RerservationStatus: nextProps.RerservationStatus });
    }
  }

  reservation_order_submit() {
    showLoader("showLoader", "class");
    var reservation_order = Array();
    reservation_order["app_id"] = appId;
    reservation_order["order_source"] = "Web";
    reservation_order["time_slot_type_advanced"] = "2";
    reservation_order["reservation_status"] = 0;
    reservation_order["availability_id"] = reservationId;
    reservation_order["customer_fname"] = cookie.load("UserFname");
    reservation_order["customer_lname"] = cookie.load("UserLname");
    reservation_order["customer_email"] = cookie.load("UserEmail");
    reservation_order["customer_mobile_no"] = cookie.load(
      "reservation_mobile_no"
    );
    reservation_order["customer_id"] = cookie.load("UserId");
    reservation_order["reservation_datetime"] = cookie.load("reservation_date");
    reservation_order["reservation_outlet_id"] = cookie.load(
      "reservation_outlet_id"
    );
    reservation_order["reservation_outlet_time"] =
      cookie.load("reservation_time_type") +
      "-" +
      cookie.load("reservation_time");
    reservation_order["reservation_time_slot"] = cookie.load(
      "reservation_time_type"
    );
    reservation_order["reservation_start_time"] =
      cookie.load("reservation_time");
    reservation_order["reservation_end_time"] = cookie.load(
      "reservation_end_time"
    );
    reservation_order["reservation_outlet_name"] = cookie.load(
      "reservation_outlet_name"
    );
    reservation_order["reservation_no_of_adult_pax"] =
      cookie.load("reservation_adult");
    reservation_order["reservation_no_of_children_pax"] =
      cookie.load("reservation_child");
    reservation_order["reservation_instruction"] =
      cookie.load("reservation_notes");

    showLoader("res-cont-div", "class");
    this.props.getResOrdersubmit(reservation_order);
  }

  render() {
    var show_reservation_time = moment(
      this.state.reservation_show_time,
      "HH:mm:ss"
    ).format("hh:mm A");

    if (
      this.state.reservation_end_time != "" &&
      this.state.reservation_end_time != "undefined"
    ) {
      show_reservation_time =
        "(" +
        moment(this.state.reservation_show_time, "HH:mm:ss").format("hh:mm A") +
        " - " +
        moment(this.state.reservation_end_time, "HH:mm:ss").format("hh:mm A") +
        ")";
    }

    return (
      <div>
        <div className="common-top-div pagesList-main-div">
          <Header />
          <div className="innersection_wrap">
            <div className="reservation-full">
              <div className="row reservation-summary-row">
                <div className="col-md-12 col-sm-12 reservation-summary-all">
                  <div className="col-md-6 col-sm-6 col-md-offset-3 col-md-width">
                    <h2 class="reservation_text">{language.reservation}</h2>
                    <div className="reservation-summary-full">
                      <h3 class="reservation_sum_text">
                        {language.reservationsummary}
                      </h3>
                      <div className="reservation-summary">
                        <ul>
                          <li>
                            <span>{language.reservationdatetime} : </span>

                            <span>
                              {this.state.reservation_show_date},{" "}
                              {show_reservation_time}{" "}
                            </span>
                          </li>
                          <li>
                            <span>{language.noofpax} : </span>

                            <span>
                              {this.state.reservation_adult} {language.adults},{" "}
                              {this.state.reservation_child} {language.child}
                            </span>
                          </li>
                          <li>
                            <span>{language.selectedoutlet} : </span>

                            <span>{this.state.reservation_outlet_name}</span>
                          </li>
                          <li>
                            <span>{language.name} : </span>

                            <span>{this.state.reservation_user_name}</span>
                          </li>
                          <li>
                            <span>{language.phoneno} : </span>

                            <span>{this.state.reservation_user_mobile}</span>
                          </li>
                          <li>
                            <span>{language.email} : </span>

                            <span className="summary-email">
                              {this.state.reservation_user_email}
                            </span>
                          </li>
                          <li>
                            <span>{language.specialrequest} : </span>

                            <span className="summary-childseat">
                              {this.state.reservation_notes}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="reservation-summary-button">
                      <div className="go-back">
                        <Link className="button" to={"/reservation"}>
                          {language.goback}
                        </Link>
                        <a
                          href="javascript:void(0)"
                          className="button showLoader"
                          onClick={this.reservation_order_submit.bind(this)}
                        >
                          {language.confirm}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 col-sm-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var RerservationOrderlocalId = "";
  var RerservationOrderprimaryId = "";
  var RerservationOrderOn = "";
  var RerservationStatus = "";

  if (Object.keys(state.reservationordersubmit).length > 0) {
    if (
      state.reservationordersubmit[0].status === "ok" &&
      Object.keys(state.reservationordersubmit[0].common).length > 0
    ) {
      RerservationStatus = "success";
      RerservationOrderlocalId =
        state.reservationordersubmit[0].common["local_order_no"];
      RerservationOrderprimaryId =
        state.reservationordersubmit[0].common["order_primary_id"];
      RerservationOrderOn = moment(new Date()).format("DD-MM-YYYY h:m A");

      cookie.save("RerservationOrderlocalId", RerservationOrderlocalId, {
        path: "/",
      });
      cookie.save("RerservationOrderprimaryId", RerservationOrderprimaryId, {
        path: "/",
      });
      cookie.save("RerservationOrderOn", RerservationOrderOn, { path: "/" });
    } else {
      RerservationStatus = "failure";
    }
  }

  return {
    pagedata: state.pagedata,
    RerservationOrderlocalId: RerservationOrderlocalId,
    RerservationOrderprimaryId: RerservationOrderprimaryId,
    RerservationOrderOn: RerservationOrderOn,
    RerservationStatus: RerservationStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },

    getResOrdersubmit: (resOrderFlg) => {
      dispatch({ type: GET_RES_ORDER_SUBMIT, resOrderFlg });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reservationsummary)
);
