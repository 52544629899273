
/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Accordion, AccordionItem } from "react-light-accordion";
import cookie from "react-cookies";
import "react-light-accordion/demo/css/index.css";
import { companyname, defaultLanguage } from "../Helpers/Config";
import { appId, apiUrl, language } from "../Helpers/Config";

import { GET_STATIC_BLOCK, GET_GLOBAL_SETTINGS } from "../../actions";
var Parser = require("html-react-parser");
import axios from "axios";
var qs = require("qs");
import { showAlert } from "../Helpers/SettingHelper";

import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerBlocks: "",
      footerlink: "",
      newsLetterEmail: "",
      isChecked: false,
      globalsettings: [],
    };
    this.props.getGlobalSettings();
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        var currentLang =
          cookie.load("currentLang") !== "" &&
          typeof cookie.load("currentLang") !== undefined &&
          typeof cookie.load("currentLang") !== "undefined"
            ? cookie.load("currentLang")
            : defaultLanguage;
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = (currentLang && currentLang !== "en" && data[`staticblocks_description_${currentLang}`]) || data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  checkEmail() {
    if (this.state.newsLetterEmail !== "" && this.state.isChecked == true) {
      var postObject = {
        app_id: appId,
        email: this.state.newsLetterEmail,
      };
      axios
        .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            var magnfPopup = $.magnificPopup.instance;
            showAlert(
              language.success,
              language.newslettersubscribed,
              magnfPopup
            );
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          } else {
            showAlert(language.error, language.entervalidemail, magnfPopup);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    }
  }

  handleChangeemail(event) {
    this.setState({ newsLetterEmail: event.target.value });
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();
    var settingsArr = this.props.globalsettings;
    var disableFooterNewsLetter = "0";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        disableFooterNewsLetter =
          settingsArr[0].result_set.disable_footer_news_letter !== "" &&
          settingsArr[0].result_set.disable_footer_news_letter !== undefined &&
          settingsArr[0].result_set.disable_footer_news_letter !== "undefined"
            ? settingsArr[0].result_set.disable_footer_news_letter
            : "0";
      }
    }

    const footerTextMapping = {
      "5AF0459E-1CD7-45A8-8B49-7202E2A401FC": language.chulop,
      "E7BDA6C4-4385-4D17-A398-2474140F26CE": language.bakin,
    };

    return (
      <footer className="footer-main">
        <div className="container">
          <div className="footer-top">
            {disableFooterNewsLetter === "0" && (
              <div className="footer-newletter">
                {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2" ? (
                  <>
                    <span>{language.joinmailinglist}</span>
                    <h4>{language.latestdeals}</h4>
                  </>
                ) : (
                  <>
                    <span>{language.signupnewsletter}</span>
                    <h4>
                      {footerTextMapping[appId]
                        ? footerTextMapping[appId]
                        : language.launchesrecipes}
                    </h4>
                  </>
                )}
                <div className="f-newletter-form">
                  <input
                    type="text"
                    onChange={this.handleChangeemail.bind(this)}
                    value={this.state.newsLetterEmail}
                    placeholder={language.emailaddress}
                  />
                  <button
                    type="submit"
                    onClick={this.checkEmail.bind(this)}
                    className="button"
                  >
                    {language.subscribe}
                  </button>
                </div>
                <div className="f-newletter-bottom">
                  <input
                    type="checkbox"
                    onChange={this.toggleChange}
                    className="subscribe_accept"
                  />
                  {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" ? (
                    <label>
                      {" "}
                      By signing up, I agree to receive communications about{" "}
                      {companyname}’s services store events and matters via
                      email and I have also acknowledge & agreed to{" "}
                      {companyname}’s Privacy Policy and Terms & Conditions
                    </label>
                  ) : (
                    <label>
                      {" "}
                      { language.newsletter1 }{" "}
                      {companyname}{ language.newsletter2 }{" "}
                      {companyname}{language.newsletter3}
                    </label>
                  )}
                </div>
              </div>
            )}

            {this.state.footerlink}
            <div className="iview-copy">
              <p>
                Copyright {yearSp} {companyname}. All rights reserved.
              </p>
            </div>
            <div className="mobile-footer-nav">
              <Accordion>
                <AccordionItem title={language.aboutus}>
                  <ul>
                    <li>
                      <a href="/page/about-us">{language.aboutus}</a>
                    </li>
                    <li>
                      <a href="/locations">{language.visitus}</a>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Helpful Links">
                  <ul>
                    <li>
                      <Link to={"/faq"}>{language.faq}</Link>
                    </li>
                    <li>
                      <Link to={"/terms-and-conditions"}>
                        {language.termsconditions}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>
                        {language.privacypolicy}
                      </Link>
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>
            Copyright {yearSp} {companyname}. All rights reserved.
          </p>
        </div>

        <div
          className="scrolltop"
          id="scrollbutton"
          style={
            appId === "5AF0459E-1CD7-45A8-8B49-7202E2A401FC"
              ? {
                  bottom: 120,
                }
              : {}
          }
        >
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>

        {appId === "5AF0459E-1CD7-45A8-8B49-7202E2A401FC" && (
          <div style={{ position: "relative", zIndex: 1000 }}>
            <WhatsAppWidget
              phoneNumber={
                settingsArr[0]?.result_set.ninja_pro_phone_number
                  ? `+65${settingsArr[0]?.result_set.ninja_pro_phone_number}`
                  : "+6598477407"
              }
            />
          </div>
        )}
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
