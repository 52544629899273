/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl, language } from "../Helpers/Config";
import { removePromoCkValue } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import tickImage from "../../common/images/tick_popup.png";
import loadingImage from "../../common/images/loading_popup.gif";

var qs = require("qs");
class Ewalletplaceorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: language.processingorder,
    };

    setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );

    var urlParams = new URLSearchParams(this.props.location.search);
    if (
      urlParams.get("referenceId") != "" &&
      urlParams.get("referenceId") == cookie.load("xenditewalletReferenceId")
    ) {
      setTimeout(
        function () {
          this.checkEwallet(urlParams.get("referenceId"));
        }.bind(this),
        5000
      );
    } else {
      setTimeout(
        function () {
          this.deleteOrderCookie();
          this.setState({ processingText: language.checkinmyorders });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location.href = "/myorders";
      }, 5000);
    }
  }

  checkEwallet(referenceId) {
    var callbackobj = {
      reference_id: cookie.load("xenditewalletReferenceId"),
    };
    axios
      .post(
        apiUrl + "xenditpayment/xenditEwalletResponse",
        qs.stringify(callbackobj)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ validateimage: tickImage });
          this.showSuccessPage(captureRes.data.common.local_order_no);
          return;
        } else if (captureRes.data.status === "error") {
          this.deleteOrderCookie();
          this.setState({ processingText: language.checkinmyorders });
          setTimeout(function () {
            window.location.href = "/myorders";
          }, 5000);
        } else {
          this.deleteOrderCookie();
          this.setState({ processingText: language.checkinmyorders });
          setTimeout(function () {
            window.location.href = "/myorders";
          }, 5000);
        }
      })
      .catch((error) => {
        this.deleteOrderCookie();
        this.setState({ processingText: language.checkinmyorders });
        setTimeout(function () {
          window.location.href = "/myorders";
        }, 5000);
      });
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    $.magnificPopup.close();
    window.location.href = "/thankyou/" + localOrderNo;
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("orderOutletId");
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("xenditewalletReferenceId", { path: "/" });
    cookie.remove("ocbcCaptureId", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("customer_tower_number", { path: "/" });

    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="placeorder-main-div checkout-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>{language.order}</p>
          </div>
        </div>

        <div className="container">
          <div className="common-inner-blckdiv">
            <div className="common-inner-banner">
              <p>{language.placingorder}</p>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{this.state.processingText}</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_left">
                  <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                  <h5>{language.waitingpaymentconfir}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

export default Ewalletplaceorder;
