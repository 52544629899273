/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  appId,
  apiUrl,
  stripeReference,
  baseUrl,
  language,
} from "../Helpers/Config";
import {
  removePromoCkValue,
  showAlert,
  getReferenceID,
} from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import tickImage from "../../common/images/tick_popup.png";
import loadingImage from "../../common/images/loading_popup.gif";

var qs = require("qs");
class Placeorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: language.processingorder,
      allinpayStatusCheckCount: 0,
    };

    setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );

    var urlParams = new URLSearchParams(this.props.location.search);
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");
    var referenceNewId = getReferenceID();

    if (
      (urlParams.get("redirect_status") == "succeeded" ||
        urlParams.get("redirect_status") == "processing") &&
      urlParams.get("payment_intent") == cookie.load("payment_intent")
    ) {
      var postObject =
        window.sessionStorage.getItem("postObject") +
        "&connect_stripe_account_id=" +
        cookie.load("stripe_account_id") +
        "&connect_total_amount=" +
        cookie.load("connect_total_amount") +
        "&connect_platform_fee=" +
        cookie.load("platform_fee") +
        "&login_type=" +
        userAccountType +
        "&reference_new_id=" +
        referenceNewId;

      axios
        .post(apiUrl + "ordersv1/submit_order", postObject)
        .then((res) => {
          if (res.data.status === "ok") {
            var localOrderNo = res.data.common.local_order_no;
            var orderID = res.data.common.order_id;

            var customerId =
              typeof cookie.load("UserId") === "undefined"
                ? ""
                : cookie.load("UserId");

            var postObject = {
              app_id: appId,
              order_id: orderID,
              payment_intent: urlParams.get("payment_intent"),
              customerId: customerId,
              payment_reference: stripeReference,
            };

            axios
              .post(apiUrl + "paymentv4/statusUpdate", qs.stringify(postObject))
              .then((captureRes) => {
                if (captureRes.data.status === "ok") {
                  this.setState({ validateimage: tickImage });
                  this.showSuccessPage(localOrderNo);
                  return;
                } else {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText: language.checkinmyorders,
                  });
                  setTimeout(function () {
                    window.location.href = "/myorders";
                  }, 5000);
                }
              })
              .catch(
                function (error) {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText: language.checkinmyorders,
                  });
                  setTimeout(function () {
                    window.location.href = "/myorders";
                  }, 5000);
                }.bind(this)
              );
          } else {
            this.deleteOrderCookie();
            this.setState({
              processingText: language.checkinmyorders,
            });
            setTimeout(function () {
              window.location.href = "/myorders";
            }, 5000);
          }
        })
        .catch(
          function (error) {
            this.deleteOrderCookie();
            this.setState({
              processingText: language.checkinmyorders,
            });
            setTimeout(function () {
              window.location.href = "/myorders";
            }, 5000);
          }.bind(this)
        );
    } else if (
      urlParams.get("payment_status") == "success" &&
      urlParams.get("referenceId") != "" &&
      urlParams.get("referenceId") !== null &&
      urlParams.get("referenceId") == cookie.load("ocbcreferenceID")
    ) {
      setTimeout(
        function () {
          this.checkOcbc(urlParams.get("referenceId"));
        }.bind(this),
        5000
      );
    } else if (
      urlParams.get("two_c_two_p_status") !== "" &&
      urlParams.get("referenceId") != "" &&
      urlParams.get("referenceId") !== null &&
      urlParams.get("referenceId") == cookie.load("2c2preferenceID") &&
      urlParams.get("sessionid") == ""
    ) {
      if (urlParams.get("two_c_two_p_status") == "success") {
        setTimeout(
          function () {
            this.check2C2PPaymentStatus(urlParams.get("referenceId"));
          }.bind(this),
          5000
        );
      } else {
        this.props.history.push("/checkout");
        var magnfPopup = $.magnificPopup.instance;
        $.magnificPopup.close;
        showAlert(language.alert, language.paymentfails, magnfPopup);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    } else if (
      urlParams.get("status") == "success" &&
      urlParams.get("sessionid") == cookie.load("paymentSession")
    ) {
      var postObject =
        window.sessionStorage.getItem("postObject") +
        "&payment_session=" +
        cookie.load("paymentSession");

      axios
        .post(apiUrl + "ordersv1/submit_order", postObject)
        .then((res) => {
          if (res.data.status === "ok") {
            var localOrderNo = res.data.common.local_order_no;
            var subscription = res.data.common.subscription;
            var orderID = res.data.common.order_id;

            var statusObject = {};
            statusObject = {
              app_id: appId,
              order_primary_id: res.data.common.order_primary_id,
              payment_reference: stripeReference,
              customer_id: cookie.load("UserId"),
              customer_email: cookie.load("UserEmail"),
              payment_session: urlParams.get("sessionid"),
            };

            axios
              .post(
                apiUrl + "orders/statusUpdateSession",
                qs.stringify(statusObject)
              )
              .then((captureRes) => {
                if (captureRes.data.status === "ok") {
                  this.setState({ validateimage: tickImage });
                  this.showSuccessPage(localOrderNo);
                  return;
                } else {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText: language.checkinmyorders,
                  });
                  setTimeout(function () {
                    window.location.href = "/myorders";
                  }, 5000);
                }
              })
              .catch(
                function (error) {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText: language.checkinmyorders,
                  });
                  setTimeout(function () {
                    window.location.href = "/myorders";
                  }, 5000);
                }.bind(this)
              );
          } else {
            this.deleteOrderCookie();
            this.setState({ processingText: res.data.message });
            showAlert(language.error, res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            setTimeout(function () {
              window.location.href = "/myorders";
            }, 5000);
          }
        })
        .catch(
          function (error) {
            console.log(error, "errorerror");
            this.deleteOrderCookie();
            this.setState({
              processingText: language.checkinmyorders,
            });
            setTimeout(function () {
              window.location.href = "/myorders";
            }, 5000);
          }.bind(this)
        );
    } else if (urlParams.get("accessOrderId")) {
      this.checkAllinpayStatus(urlParams.get("accessOrderId"));
    } else if (urlParams.get("status") == "failure") {
      var statusObject = {};
      statusObject = {
        app_id: appId,
        payment_reference: stripeReference,
        customer_id: cookie.load("UserId"),
        customer_email: cookie.load("UserEmail"),
        orderOutletId: cookie.load("orderOutletId"),
        payment_session: urlParams.get("sessionid"),
      };

      axios
        .post(
          apiUrl + "orders/statusUpdateCancelSession",
          qs.stringify(statusObject)
        )
        .then((captureRes) => {})
        .catch();

      setTimeout(
        function () {
          this.setState({
            processingText: language.checkinmyorders,
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location = baseUrl + "checkout";
      }, 5000);
    } else {
      setTimeout(
        function () {
          this.deleteOrderCookie();
          this.setState({
            processingText: language.checkinmyorders,
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location.href = "/myorders";
      }, 5000);
    }
  }

  checkOcbc(referenceId) {
    var cabtureObjects = {
      app_id: appId,
      referenceId: referenceId,
      payment_attempt: this.state.payment_attempt,
    };
    axios
      .post(
        apiUrl + "paymentv1/checkocbcfinalStatus",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ validateimage: tickImage });
          this.showSuccessPage(captureRes.data.common.local_order_no);
          return;
        } else if (captureRes.data.status === "error") {
          this.deleteOrderCookie();
          this.setState({
            processingText: language.checkinmyorders,
          });
          setTimeout(function () {
            window.location.href = "/myorders";
          }, 5000);
        } else {
          this.deleteOrderCookie();
          this.setState({
            processingText: language.checkinmyorders,
          });
          setTimeout(function () {
            window.location.href = "/myorders";
          }, 5000);
        }
      })
      .catch((error) => {
        this.deleteOrderCookie();
        this.setState({
          processingText: language.checkinmyorders,
        });
        setTimeout(function () {
          window.location.href = "/myorders";
        }, 5000);
      });
  }

  check2C2PPaymentStatus(referenceId) {
    console.log(referenceId, "referenceId");

    var cabtureObjects = {
      app_id: appId,
      order_id: referenceId,
    };
    axios
      .post(
        apiUrl + "payment2c2p/checkTwoCTwoPStatus",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.showSuccessPage(captureRes.data.common.local_order_no);
        } else {
          this.props.history.push("/checkout");
          var magnfPopup = $.magnificPopup.instance;
          showAlert(language.alert, captureRes.data.message, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        this.props.history.push("/myorders");
        var magnfPopup = $.magnificPopup.instance;
        showAlert(language.alert, language.checkinmyorders, magnfPopup);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      });
  }

  checkAllinpayStatus(accessOrderId) {
    var allinpayStatusCheckCount =
      parseInt(this.state.allinpayStatusCheckCount) + 1;
    if (allinpayStatusCheckCount <= 100) {
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      this.setState({ allinpayStatusCheckCount: allinpayStatusCheckCount });
      var statusObject = {};
      statusObject = {
        app_id: appId,
        accessOrderId: accessOrderId,
        customer_id: customerId,
      };

      axios
        .post(
          apiUrl + "paymentAllinpay/checkPaymentStatus",
          qs.stringify(statusObject)
        )
        .then((res) => {
          console.log(res, "resresres");

          if (res.data.status === "success") {
            if (res.data.resultCode === "0000") {
              this.setState({ validateimage: tickImage });
              this.showSuccessPage(res.data.order_local_no);
            } else {
              if (res.data.message === "Pending") {
                var currentThis = this;
                setTimeout(function () {
                  currentThis.checkAllinpayStatus();
                }, 3000);
              } else {
                this.props.history.push("/myorders");
                var magnfPopup = $.magnificPopup.instance;
                showAlert(language.alert, language.checkinmyorders, magnfPopup);
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
              }
            }
          } else {
            this.props.history.push("/myorders");
            var magnfPopup = $.magnificPopup.instance;
            showAlert(language.alert, language.checkinmyorders, magnfPopup);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      this.props.history.push("/myorders");
      var magnfPopup = $.magnificPopup.instance;
      showAlert(language.alert, language.checkinmyorders, magnfPopup);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    $.magnificPopup.close();
    window.location.href = "/thankyou/" + localOrderNo;
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("ocbcreferenceID", { path: "/" });
    cookie.remove("ocbcCaptureId", { path: "/" });
    cookie.remove("payment_intent", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("customer_tower_number", { path: "/" });

    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    window.sessionStorage.removeItem("postObject");
    window.sessionStorage.removeItem("paymentSession");

    cookie.remove("2c2preferenceID", { path: "/" });
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="placeorder-main-div checkout-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>{language.order}</p>
          </div>
        </div>

        <div className="container">
          <div className="common-inner-blckdiv">
            <div className="common-inner-banner">
              <p>{language.placingorder}</p>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{this.state.processingText}</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_left">
                  <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                  <h5>{language.waitingpaymentconfir}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

export default Placeorder;
