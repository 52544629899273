/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { GET_BANNER_LIST } from "../../../actions";
import { appId } from "../../Helpers/Config";

var Parser = require("html-react-parser");

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }
  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: appId !== "9C56E15B-CEE2-4A23-B333-09C458896BC3" ? true : false,
    };

    return (
      <div
        className={
          appId !== "9C56E15B-CEE2-4A23-B333-09C458896BC3"
            ? "home-banner"
            : "home-banner-universal"
        }
      >
        {Object.keys(bannerlist).length > 0 ? (
          <Slider {...settingsGallery}>
            {bannerlist.map((banner, index) => {
              return (
                <div key={index}>
                  <a
                    href={
                      banner.banner_link !== "" && banner.banner_link !== null
                        ? banner.banner_link
                        : void 0
                    }
                    target={
                      banner.banner_link !== "" && banner.banner_link !== null
                        ? "_blank"
                        : "_self"
                    }
                  >
                    <img
                      src={bannerimagesource + banner.banner_image}
                      alt="Banner"
                    />
                  </a>
                  {banner.banner_description !== "" &&
                  banner.banner_description !== null
                    ? Parser(banner.banner_description)
                    : ""}
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="loader-main-cls">
            <div className="banner loader-sub-div"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
