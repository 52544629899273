/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import ProductList from "./FavouriteProductList";
import { language } from "../Helpers/Config";
import { GET_GLOBAL_SETTINGS } from "../../actions";
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategoryName: "",
      cartTriggerFlg: "No",
      searchProResult: [],
      selectedProResult: [],
      openOutletTime: false,
    };
    var UserId = cookie.load("UserId");
    if (UserId === "" || UserId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }
    this.props.getSettings();
  }

  componentDidMount() {
    /*SCROLL FIXED PRODUCT PAGE*/
    $(document).click(function (e) {
      if (!$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")) {
        if ($(".product_search_result").is(":visible")) {
          $(".product_search_result").hide();
          $("#searchKeyWord").val("");
        }
      }
    });

    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    $(".product_search_result").hide();
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    } else if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    } else if (field === "openOutletTime" && value !== "") {
      this.setState(
        {
          openOutletTime: value,
        },
        function () {
          this.props.sateValChange("openOutletTime", value);
        }
      );
    }
  };

  render() {
    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="container">
            <div class="common-inner-product">
              <h3>{language.favouriteproducts}</h3>
            </div>
          </div>
        </div>
        <section className="product-menu-listing favourite-main-list">
          <div className="container">
            <div className="product-lhs">
              <ProductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
              />
            </div>
          </div>
        </section>
        <div id="dvLoading" className="dvLoadrCls"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
