/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import { defaultLanguage, language } from "../Helpers/Config";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
    };
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    
    var currentLang = cookie.load("currentLang") !== "" &&
      typeof cookie.load("currentLang") !== undefined &&
      typeof cookie.load("currentLang") !== "undefined"
        ? cookie.load("currentLang")
        : defaultLanguage;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index <= 7) {
          if (loaddata.menu_type == "main") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/products/category/" + loaddata.pro_cate_slug}
                  className="menu-title-link"
                  onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                  }}
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata[`menu_custom_title_${currentLang}`] || loaddata.menu_custom_title)}
                </Link>
              </div>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                  className="menu-title-link"
                  onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_subcate_slug);
                  }}
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata[`menu_custom_title_${currentLang}`] || loaddata.menu_custom_title)}
                </Link>
              </div>
            );
          }
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }

  navigateMobileMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    
    var currentLang = cookie.load("currentLang") !== "" &&
      typeof cookie.load("currentLang") !== undefined &&
      typeof cookie.load("currentLang") !== "undefined"
        ? cookie.load("currentLang")
        : defaultLanguage;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/menu/category/" + loaddata.pro_cate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata[`menu_custom_title_${currentLang}`] || loaddata.menu_custom_title)}
              </Link>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/menu/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                onClick={(e) => {
                  this.goCategoryProducts(loaddata.pro_subcate_slug);
                }}
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata[`menu_custom_title_${currentLang}`] || loaddata.menu_custom_title)}
              </Link>
            </div>
          );
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }
  navigateMenuListHidden() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    var currentLang = cookie.load("currentLang") !== "" &&
      typeof cookie.load("currentLang") !== undefined &&
      typeof cookie.load("currentLang") !== "undefined"
        ? cookie.load("currentLang")
        : defaultLanguage;
    
    var i = 0;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index > 7) {
          i++;
          if (loaddata.menu_type == "main") {
            return (
              <li
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/menu/category/" + loaddata.pro_cate_slug}
                  className="menu-title-link"
                  onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                  }}
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata[`menu_custom_title_${currentLang}`] || loaddata.menu_custom_title)}
                </Link>
              </li>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <li
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/menu/subcategory/" + loaddata.pro_subcate_slug}
                  className="menu-title-link"
                  onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_subcate_slug);
                  }}
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata[`menu_custom_title_${currentLang}`] || loaddata.menu_custom_title)}
                </Link>
              </li>
            );
          }
        }
      });
      setTimeout(function () {
        console.log(
          $(".more-menu .menu-list-intvl.active a span").length,
          "length"
        );
        if ($(".more-menu .menu-list-intvl.active a").length > 0) {
          /* $(".more-menu .more-menu-name").html(
            $(".more-menu .menu-list-intvl.active a").text()
          ); */
          $(".more-menu .more-menu-name").html(language.language);
        } else {
          $(".more-menu .more-menu-name").html(language.language);
        }
      }, 100);
      if (i > 0) {
        $(".more-menu").show();
      } else {
        $(".more-menu").hide();
        $(".menu-section-left-inner").addClass("more-menu-avai");
      }
      return mainMenu;
    } else {
      return;
    }
  }

  goCategoryProducts(selectedNavigation) {
    $(document).find(".closeProductDetails").trigger("click");
    this.setState({ selectedNavigation: selectedNavigation });
    if ($(document).find("#pro_cat_" + selectedNavigation).length > 0) {
      $("html, body").animate(
        {
          scrollTop:
            $(document)
              .find("#pro_cat_" + selectedNavigation)
              .offset().top - 180,
        },
        1000
      );
      $(document).find("body").click();
    }
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }

  render() {
    return (
      <>
        <div className="menu-section-left">
          <div className="menu-section-left-inner">
            {this.navigateMenuList()}
          </div>

          <div className="more-menu" style={{ display: "none" }}>
            <div className="more-menu-parent">
              <span className="more-menu-name">{language.language}</span>
              <label className="open_more_category">
                {" "}
                <i className="fa fa-angle-double-down"></i>{" "}
              </label>
            </div>
            <ul style={{ display: "none" }} className="more_categor_info">
              {this.navigateMenuListHidden()}
            </ul>
          </div>
        </div>
        <div className="menu-section-mobile-inner"></div>
      </>
    );
  }
}

export default MenuNavigation;
