/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { fbPixelCustomEvent } from "../Layout/withTracker";
import { format } from "date-fns";
import {
  stripslashes,
  addressFormat,
  showAlert,
  showPriceValueNew,
} from "../Helpers/SettingHelper";
import {
  appId,
  deliveryId,
  apiUrlNotify,
  dineinId,
  cateringId,
  noimage,
  currencySybmol,
  stripeCurrency,
  language,
} from "../Helpers/Config";
import thnkyou_tick_img from "../../common/images/tick.png";
import scootyImg from "../../common/images/delivery-bike.svg";
import cartMpImg from "../../common/images/cart-map.png";
import thanku from "../../common/images/Thankyou.gif";
import { GET_ORDER_DETAIL, DESTROY_CART_DETAIL } from "../../actions";

var Parser = require("html-react-parser");

class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderdetails: [],
    };
    this.props.destroyCartDetail();
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    if (orderId !== "") {
      this.props.getOrderDetail(orderId);
      /*this.sendNotification();*/
    } else {
      showAlert(language.error, language.invalidorderdetail);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(PropsDt) {
    if (this.state.orderdetails !== PropsDt.orderdetail) {
      this.setState({ orderdetails: PropsDt.orderdetail }, function () {
        if (PropsDt.orderdetail.length > 0) {
          if (PropsDt.orderdetail[0].status === "ok") {
            if (
              cookie.load("fb_pixel_id") !== "" &&
              typeof cookie.load("fb_pixel_id") !== undefined &&
              typeof cookie.load("fb_pixel_id") !== "undefined"
            ) {
              var orderResul = PropsDt.orderdetail[0].result_set[0];
              fbPixelCustomEvent("Purchase", {
                currency: stripeCurrency,
                value:
                  typeof orderResul.order_total_amount !== "undefined"
                    ? orderResul.order_total_amount
                    : 0,
              });
            }
          }
        }
      });
    }
  }

  sendNotification() {
    axios.get(apiUrlNotify + "order_notify").then((res) => {});
  }

  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];
      return cartItems.map((item, index) => (
        <div key={index}>
          <div className="cart_row product-details">
            <div className="cart_img">
              {item.item_image !== "" ? (
                <img src={item.item_image} alt={stripslashes(item.item_name)} />
              ) : (
                <img src={noimage} alt={stripslashes(item.item_name)} />
              )}
            </div>
            <div className="col-sm-cls cart_left">
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>

                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}

                {item.item_specification !== "" && (
                  <p className="help-block">
                    {stripslashes(item.item_specification)}
                  </p>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>
                  {currencySybmol}
                  {parseFloat(item.item_total_amount).toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          {item.setup && (
            <div className="cart-settup-list">
              <ul>
                {this.checkSettup(item.setup, "Buffet") && (
                  <li>
                    <div className="pkg-list-lhs">
                      <label>{language.buffet}:</label>
                      <div>
                        <span className="price">
                          {showPriceValueNew(
                            this.settuptotalPrice(item.setup, "Buffet")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="pkgslist_addition">
                      {this.loadSettup(item.setup, "Buffet")}
                    </div>
                  </li>
                )}
                {this.checkSettup(item.setup, "Delivery") && (
                  <li>
                    <div className="pkg-list-lhs">
                      <label>{language.delivery}:</label>
                      <div>
                        <span className="price">
                          {showPriceValueNew(
                            this.settuptotalPrice(item.setup, "Delivery")
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="pkgslist_addition">
                      {this.loadSettup(item.setup, "Delivery")}
                    </div>
                  </li>
                )}
                {item.equipment !== "" &&
                  item.equipment !== undefined &&
                  item.equipment.length > 0 && (
                    <li>
                      <div className="pkg-list-lhs">
                        <label>{language.equipment}:</label>
                        <div>
                          <span className="price">
                            {showPriceValueNew(
                              this.settuptotalPrice(item.equipment, "Equipment")
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="pkgslist_addition">
                        {this.loadSettup(item.equipment, "Equipment")}
                      </div>
                    </li>
                  )}
                {item.addons_setup !== "" &&
                  item.addons_setup !== undefined &&
                  item.addons_setup.length > 0 && (
                    <li>
                      <label>{language.addon}:</label>
                      <div className="pkgslist_addition">
                        {this.loadSettup(item.addons_setup, "Addons")}
                      </div>
                    </li>
                  )}
              </ul>
            </div>
          )}
        </div>
      ));
    } else {
    }
  }

  checkSettup(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.os_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }
  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map((settup) => {
        totalPrice += parseFloat(settup.oe_equipment_total_price);
      });
    } else {
      setup.map((settup) => {
        if (settup.os_setup_type === type) {
          totalPrice += parseFloat(settup.os_setup_total_price);
        }
      });
    }
    return totalPrice;
  }
  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.oe_equipment_qty} X {settup.oe_equipment_description} (+
            {showPriceValueNew(settup.oe_equipment_price)})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="pkgslist_additionrow" key={index}>
            <div className="pkg-list-lhs">
              <label>{settup.as_setup_title}</label>
              <div>
                <span className="price">
                  {showPriceValueNew(settup.as_setup_amount)}
                </span>
              </div>
            </div>
            <div className="pkgslist_subaddition">
              {this.loadAddonValues(settup.addons_setup_values)}
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.os_setup_type === type) {
            return (
              <p key={index}>
                {settup.os_setup_name} (
                {showPriceValueNew(settup.os_setup_total_price)}){" "}
              </p>
            );
          }
        });
      }
    }
  }

  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.asv_setup_val_title} ({addonval.asv_setup_val_qty}X)
        </p>
      );
    });
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  loadSuccessSection(settingDetails, availability_id) {
    var normalmsg = 1;
    if (Object.keys(settingDetails).length > 0) {
      if (
        settingDetails.client_enable_auto_push_delivery !== "" &&
        typeof settingDetails.client_enable_auto_push_delivery !== undefined &&
        typeof settingDetails.client_enable_auto_push_delivery !==
          "undefined" &&
        availability_id === deliveryId
      ) {
        if (settingDetails.client_enable_auto_push_delivery === "1") {
          normalmsg = 0;
          return (
            <div className="product-step-inner">
              <div className="product-step-top textcenter">
                <div className="product-step-top3">
                  <img className="complete-tick" src={thanku} alt="plane" />
                  <h2>{language.orderiscomplete}</h2>
                  <p>{language.verifyingpayment}</p>
                </div>
              </div>
              <div className="product-step">
                <ul className="progressbar">
                  <li className="active">
                    <a href={void 0}>
                      <figure>1</figure>
                    </a>
                  </li>
                  <li className="active">
                    <a href={void 0}>
                      <figure>2</figure>
                    </a>
                  </li>
                  <li className="active">
                    <a href={void 0}>
                      <figure>3</figure>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          );
        }
      }
    }
    if (normalmsg === 1) {
      return (
        <div className="mainacc_toptext tick">
          <img src={thnkyou_tick_img} alt={language.thankyou} />
          <h2>{language.thankyou}</h2>
          <p>{language.orderplacedsuccess}</p>
          {appId == "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380" &&
            availability_id === dineinId && (
              <p>{language.payatcashiercounter}</p>
            )}
          {appId == "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380" &&
            availability_id === dineinId && <p>{language.keepcalmenjoy} </p>}
        </div>
      );
    }
  }

  paymentType(order_availability_id, order_company_app_id, order_method_name) {
    if (
      order_availability_id === dineinId &&
      order_company_app_id == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2"
    ) {
      return <p>{language.payatcashier}</p>;
    } else {
      if (
        order_company_app_id == "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380" &&
        order_availability_id === dineinId
      ) {
        return "";
      } else {
        return (
          <p>
            {language.payby} : {order_method_name}
          </p>
        );
      }
    }
  }

  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    let globalSettings = this.props.settingsArr;
    var settingDetails = [];
    var enableDeliveryAuto = false;
    if (
      Object.keys(globalSettings).length > 0 &&
      Object.keys(orderDetail).length > 0
    ) {
      settingDetails = globalSettings;
      if (
        settingDetails.client_enable_auto_push_delivery !== "" &&
        typeof settingDetails.client_enable_auto_push_delivery !== undefined &&
        typeof settingDetails.client_enable_auto_push_delivery !==
          "undefined" &&
        orderDetail.order_availability_id === deliveryId
      ) {
        if (settingDetails.client_enable_auto_push_delivery === "1") {
          enableDeliveryAuto = true;
        }
      }
    }

    var availability_label = language.pickup;

    if (orderDetail.order_availability_id === deliveryId) {
      availability_label = language.delivery;
    } else if (orderDetail.order_availability_id === dineinId) {
      availability_label =
        appId === "D93083AF-FD2F-4037-AD20-44BD976DC5E0"
          ? language.instoreordering
          : language.dinein;
    } else if (orderDetail.order_availability_id === cateringId) {
      availability_label = language.event;
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              <div
                className={
                  enableDeliveryAuto === true ? "product-step-inner-white" : ""
                }
              >
                {this.loadSuccessSection(
                  settingDetails,
                  orderDetail.order_availability_id
                )}

                {/* order-detail-maindiv - start */}
                <div className="thank-order-detaildiv">
                  <div className="tnk-detail text-center">
                    <h2 className="text-uppercase">
                      {language.yourorderdetails}
                    </h2>
                    <div className="tnk-order">
                      <h3>
                        {language.orderno} - {orderDetail.order_local_no}
                      </h3>
                      <p>
                        {language.orderplacedat} :{" "}
                        {Moment(orderDetail.order_created_on).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                        {orderDetail.order_availability_id === cateringId ? (
                          ""
                        ) : (
                          <>
                            {this.paymentType(
                              orderDetail.order_availability_id,
                              orderDetail.order_company_app_id,
                              orderDetail.order_method_name
                            )}{" "}
                          </>
                        )}
                      </p>
                      {orderDetail.order_qnumber != "" && (
                        <p>
                          {language.qno} - {orderDetail.order_qnumber}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="tnk-delivery">
                    {orderDetail.order_availability_id === deliveryId ||
                    orderDetail.order_availability_id === cateringId ? (
                      <div className="delivery-cart-div">
                        <div className="cart-direction">
                          <img
                            className="cart-direction-left"
                            src={scootyImg}
                            alt="Outlet Location"
                          />
                          <img
                            className="cart-direction-right"
                            src={cartMpImg}
                            alt="Delivery Location"
                          />
                        </div>
                        <div className="cart_row tnkorder-first">
                          <div className="order-hlhs text-left">
                            <h5>{language.orderhandlingby}</h5>
                            <p>{stripslashes(orderDetail.outlet_name)}</p>
                            {orderDetail.order_outlet_category_id !== "2" && (
                              <p>
                                {addressFormat(
                                  orderDetail.outlet_unit_number1,
                                  orderDetail.outlet_unit_number2,
                                  orderDetail.outlet_address_line1,
                                  orderDetail.outlet_address_line2,
                                  orderDetail.outlet_postal_code
                                )}
                              </p>
                            )}
                          </div>
                          <div className="order-hrhs text-right">
                            <h5>
                              {" "}
                              {availability_label} {language.location}
                            </h5>
                            <p>
                              {addressFormat(
                                orderDetail.order_customer_unit_no1,
                                orderDetail.order_customer_unit_no2,
                                orderDetail.order_customer_address_line1,
                                orderDetail.order_customer_address_line2,
                                orderDetail.order_customer_postal_code
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="pickup-cart-div">
                        {/*<div className="cart-direction">
                        <img
                          className="cart-direction-left"
                          src={scootyImg}
                          alt="Outlet Location"
                        />
                        <img
                          className="cart-direction-right"
                          src={cartMpImg}
                          alt="Delivery Location"
                        />
                      </div>*/}
                        <div className="cart_row cart-header-first">
                          <div className="pickup-thankfull text-center">
                            <h4>
                              {availability_label} {language.location}
                            </h4>
                            <p>{stripslashes(orderDetail.outlet_name)}</p>
                            <p>
                              {addressFormat(
                                orderDetail.outlet_unit_number1,
                                orderDetail.outlet_unit_number2,
                                orderDetail.outlet_address_line1,
                                orderDetail.outlet_address_line2,
                                orderDetail.outlet_postal_code
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {orderDetail.order_availability_id === dineinId ? (
                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <h2>
                            {availability_label} {language.datetime}
                          </h2>
                          <h4 className="checkoutDate">
                            {Moment(orderDetail.order_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            {this.showOrdTimeSlot(orderDetail)}
                          </h4>
                        </div>
                        <div className="delivery_total_left delivery_total_right">
                          <h2>{language.tableno}</h2>
                          <h4 className="checkoutTime">
                            {orderDetail.order_table_number}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <h2>
                            {availability_label} {language.date}
                          </h2>
                          <h4 className="checkoutDate">
                            {Moment(orderDetail.order_date).format(
                              "DD/MM/YYYY"
                            )}
                          </h4>
                        </div>
                        <div className="delivery_total_left delivery_total_right">
                          <h2>
                            {availability_label} {language.time}
                          </h2>
                          <h4 className="checkoutTime">
                            {this.showOrdTimeSlot(orderDetail)}
                          </h4>
                        </div>
                      </div>
                    )}

                    {orderDetail.order_availability_id === deliveryId &&
                      orderDetail.rider_details &&
                      orderDetail.rider_details.length > 0 && (
                        <div className="delivery_total delivery_total_number delivery_datetime_div">
                          <div className="delivery_total_left">
                            <h2>{language.ridername}</h2>
                            <h4 className="checkoutDate">
                              {orderDetail.rider_details[0].driver_name}
                            </h4>
                          </div>
                          <div className="delivery_total_left delivery_total_right">
                            <h2>{language.ridermobile}</h2>
                            <h4 className="checkoutTime">
                              {orderDetail.rider_details[0].driver_contactno}
                            </h4>
                          </div>
                        </div>
                      )}

                    <div className="orderitem_body_div">
                      <div className="overall-parent">
                        <div className="order-details-with-clear">
                          <h5>{language.youritems}</h5>
                        </div>
                        {this.loadItems(orderDetail)}
                        {orderDetail.order_remarks !== "" && (
                          <div className="remark_notesec text-left">
                            <h4>{language.remarks}</h4>
                            <p>{orderDetail.order_remarks}</p>
                          </div>
                        )}

                        {orderDetail.order_customer_send_gift === "Yes" && (
                          <>
                            <div className="remark_notesec text-left">
                              <h4>{language.recipientname}</h4>
                              <p>{orderDetail.order_recipient_name}</p>
                            </div>
                            <div className="remark_notesec text-left">
                              <h4>{language.recipientnamerecipientname}</h4>
                              <p>{orderDetail.order_recipient_contact_no}</p>
                            </div>
                            {orderDetail.order_gift_message !== "" &&
                              orderDetail.order_gift_message !== null && (
                                <div className="remark_notesec text-left">
                                  <h4>{language.giftmessage}</h4>
                                  <p>{orderDetail.order_gift_message}</p>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="cart_footer tnk_cart_footer">
                      <div className="cart_row">
                        <p className="text-uppercase">{language.subtotal}</p>

                        <span>
                          {currencySybmol}
                          {orderDetail.order_sub_total}
                        </span>
                      </div>

                      {parseFloat(orderDetail.order_delivery_charge) > 0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">
                            {language.deliverycharges}
                          </p>
                          <span>
                            {currencySybmol}
                            {parseFloat(
                              orderDetail.order_delivery_charge
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {parseFloat(orderDetail.order_additional_delivery) >
                        0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">
                            {language.additionaldeliverycharges}
                          </p>
                          <span>
                            {currencySybmol}
                            {parseFloat(
                              orderDetail.order_additional_delivery
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {parseFloat(orderDetail.order_service_charge_amount) >
                        0 && (
                        <div className="cart_row">
                          {parseFloat(orderDetail.order_service_charge) > 0 ? (
                            <p className="text-uppercase">
                              {orderDetail.order_servicecharge_displaylabel !==
                              ""
                                ? orderDetail.order_servicecharge_displaylabel
                                : language.servicecharge}{" "}
                              ({parseFloat(orderDetail.order_service_charge)}%)
                            </p>
                          ) : (
                            <p className="text-uppercase">
                              {orderDetail.order_servicecharge_displaylabel !==
                              ""
                                ? orderDetail.order_servicecharge_displaylabel
                                : language.servicecharge}
                            </p>
                          )}
                          <span>
                            {currencySybmol}
                            {parseFloat(
                              orderDetail.order_service_charge_amount
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {parseFloat(orderDetail.order_subcharge_amount) > 0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">{language.surcharge}</p>
                          <span>
                            {currencySybmol}
                            {parseFloat(
                              orderDetail.order_subcharge_amount
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {parseFloat(orderDetail.order_discount_amount) > 0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">
                            {orderDetail.order_discount_type === "redeem"
                              ? language.discount + " (-)"
                              : language.promocode + " (-)"}
                          </p>
                          <span>
                            {currencySybmol}
                            {parseFloat(
                              orderDetail.order_discount_amount
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {parseFloat(orderDetail.order_tax_calculate_amount) >
                        0 && (
                        <div className="cart_row gst-row">
                          <p className="text-uppercase">
                            {language.gst}(
                            {parseFloat(orderDetail.order_tax_charge)}%)
                          </p>
                          <span>
                            {currencySybmol}
                            {parseFloat(
                              orderDetail.order_tax_calculate_amount
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                      <div className="cart_row grant-total-cls">
                        <p className="text-uppercase">{language.total}</p>

                        <span>
                          <sup>{currencySybmol}</sup>
                          {parseFloat(orderDetail.order_total_amount).toFixed(
                            2
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="tnk-chk-order">
                      {orderDetail.order_availability_id === cateringId ? (
                        <Link to={"/myorders/catering"} className="button">
                          {language.checkorderstatus}
                        </Link>
                      ) : (
                        <Link to={"/myorders"} className="button">
                          {language.checkorderstatus}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* order-detail-maindiv - end */}
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
