/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import {
  Elements,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "./CheckoutForm";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "../Layout/OrderAdvancedDatetimeSlot";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  dineinId,
  cateringId,
  CountryTxt,
  stripeReference,
  stripeCurrency,
  stripeImage,
  companyname,
  noimage,
  currencySybmol,
  mapcountry,
  baseUrl,
  language,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  getCalculatedAmount,
  showAlert,
  showCustomAlert,
  showCustomCenterAlert,
  getPromoCkValue,
  removePromoCkValue,
  smoothScroll,
  getOrderDateTime,
} from "../Helpers/SettingHelper";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_ACTIVITYCOUNT,
  GET_ADDONPRODUCT,
} from "../../actions";
import mofifyimage from "../../common/images/modify-white.png";
import mofifydeleteimage from "../../common/images/delete-white.png";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import paymentImage from "../../common/images/payment.png";
import paymentoffdel from "../../common/images/offline-delivery.png";
import paymentoff_rbliciouz from "../../common/images/pay-by-onlytext.png";
import paybystripe from "../../common/images/pay-by-stripe.png";
import paybykoomipay from "../../common/images/pay-by-koomipay.png";
import paybyomise from "../../common/images/pay-by-omise.png";
import paybyomisepaynow from "../../common/images/pay-by-omise-paynow.png";
import paybycod from "../../common/images/pay-by-cod.png";
import paybycod2 from "../../common/images/pay-by-cash2.png";
import paybylater from "../../common/images/pay-by-later.png";
import paybylaterhans from "../../common/images/hans-pay-later.png";

import xendit from "../../common/images/payment.png";
import payanyonenow from "../../common/images/pay-by-payanyone.png";
import fomopay from "../../common/images/fomo-pay-logo.png";

import gcash from "../../common/images/gcash.png";
import paymaya from "../../common/images/paymaya.png";

import backImg from "../../common/images/back.png";

import hiHndImage from "../../common/images/hi.png";
import crossImg from "../../common/images/cross1.png";
import twoctwop from "../../common/images/pay-by-2c2p.png";
import allinpay from "../../common/images/pay-by-allinpay.png";

import OwlCarousel from "react-owl-carousel2";
import { ReactSession } from "react-client-session";

import KoomipayCheckout from "./KoomipayCheckout";

var base64 = require("base-64");
var Parser = require("html-react-parser");
var qs = require("qs");
var stripePromise = "";

var startTimePay;
var settingsourclient = {
  items: 1,
  nav: false,
  autoplay: true,
  dots: true,
  margin: 10,
  autoplayTimeout: 2000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    680: {
      items: 4,
    },
    1198: {
      items: 3,
    },
  },
};
class Checkout extends Component {
  constructor(props) {
    super(props);
    /* Rounding to the nearest 15 minute interval */
    const start = moment();
    const remainder = 15 - (start.minute() % 15);
    const currentTime = moment(start).add(remainder, "minutes");
    /* To change the date after 2 PM */

    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var orderPostalCode =
      typeof cookie.load("orderPostalCode") === "undefined"
        ? ""
        : cookie.load("orderPostalCode");
    var orderDeliveryAddress =
      typeof cookie.load("orderDeliveryAddress") === "undefined"
        ? ""
        : cookie.load("orderDeliveryAddress");
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");

    var billingPostalCode =
      typeof cookie.load("billingPostalCode") === "undefined"
        ? ""
        : cookie.load("billingPostalCode");
    var billingDeliveryAddress =
      typeof cookie.load("billingDeliveryAddress") === "undefined"
        ? ""
        : cookie.load("billingDeliveryAddress");
    var billunitNoOne =
      typeof cookie.load("billunitNoOne") === "undefined"
        ? ""
        : cookie.load("billunitNoOne");
    var billunitNoTwo =
      typeof cookie.load("billunitNoTwo") === "undefined"
        ? ""
        : cookie.load("billunitNoTwo");

    var orderDateTime =
      typeof cookie.load("orderDateTime") === "undefined"
        ? ""
        : cookie.load("orderDateTime");
    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");

    /* For Advanced Slot */
    var isAdvanced =
      typeof cookie.load("isAdvanced") === "undefined"
        ? ""
        : cookie.load("isAdvanced");
    var slotType =
      typeof cookie.load("slotType") === "undefined"
        ? ""
        : cookie.load("slotType");

    var getPromoCkVal = getPromoCkValue();

    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);

    var servicesurchargeArr = Array();
    servicesurchargeArr["servicechrg_displaylabel"] = "Service Charge";
    servicesurchargeArr["servicechrg_type"] = "percentage";
    servicesurchargeArr["servicechrg_per"] = 0;
    servicesurchargeArr["servicechrg_amount"] = 0;
    servicesurchargeArr["surcharge_amount"] = 0;
    servicesurchargeArr["surcharge_type"] = "";
    servicesurchargeArr["surcharge_date"] = "";
    servicesurchargeArr["surcharge_frmtime"] = "";
    servicesurchargeArr["surcharge_totime"] = "";

    this.state = {
      globalSettings: [],
      Maxdate: Maxdate,
      staticblacks: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      cartTotalItmCount: 0,
      order_postcode: orderPostalCode,
      order_delivery_address: orderDeliveryAddress,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      billing_postcode: billingPostalCode,
      billing_delivery_address: billingDeliveryAddress,
      billunitnumber1: billunitNoOne,
      billunitnumber2: billunitNoTwo,
      billing_addrs_sameas: "yes",
      alternative_phoneno: "",
      order_specil_note: "",

      /* For Advanced Slot */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: isAdvanced,
      slotType: slotType,

      activitycount_arr: [],
      promotion_count: 0,
      reward_point_count: 0,
      used_reward_point: getPromoCkVal["usedPoints"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
      promo_code_val: getPromoCkVal["promoCodeVal"],
      promotion_applied: getPromoCkVal["promotionApplied"],
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: getPromoCkVal["promotionAmount"],
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],
      paymentmodevalue: "Stripe",
      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      orderValidFail: 0,
      cod_payment_enable: 0,
      offline_payment_enable: 0,
      gcash_payment_enable: 0,
      paymaya_payment_enable: 0,
      stripe_payment_enable: 0,
      stripe_express_payment_enable: 0,
      koomipay_payment_enable: 0,
      postorder_triggered: "no",
      chk_tarms_contn: "No",
      termsAndConditions: "",
      qrCode: "",
      seletedAvilablityId: avilablityId,
      seletedOutletId: orderOutletId,
      order_tat_time: orderTAT,
      viewProductSlug: "",
      cust_birthdate_update: "no",
      cust_birthdate: "",
      cust_gender: "",
      ocbc_payment_enable: 0,
      omise_payment_enable: 0,
      omise_log_id: "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      payment_ref_id: "",
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      xendit_payment_enable: 0,
      xenditcodeerror: "",
      xendityearerror: "",
      xenditmontherror: "",
      xenditcardrror: "",
      xenditnameerror: "",
      xenditholdername: "",
      xenditCardNumber: "",
      xendit_expiration_month: "",
      xendit_expiration_year: "",
      xendit_security_code: "",
      xendit_public_key: "",
      xendit_tokken_response: "",
      xendit_tokken_id: "",
      xendit_authentication_id: "",
      xendit_paid_amount: 0,
      omise_chain_payment_enable: 0,
      omise_chain_log_id: "",
      omise_chain_codeerror: "",
      omise_chain_yearerror: "",
      omise_chain_montherror: "",
      omise_chain_cardrror: "",
      omise_chain_nameerror: "",
      chain_holdername: "",
      chain_cardNumber: "",
      chain_expiration_month: "",
      chain_expiration_year: "",
      chain_security_code: "",
      omise_chain_tokken_response: "",
      omise_chain_tokken_card_id: "",
      omise_chain_tokken_id: "",
      omise_chain_public_key: "",

      order_customer_send_gift: "No",
      recipient_name: "",
      recipient_contact_no: "",
      gift_message: "",
      product_lead_time: 0,
      omisePayNowResponse: [],
      omisePayNowChainResponse: [],
      omisepaynow_chain_payment_enable: "",
      omise_paynow_chain_public_key: "",
      updateCartResponse: [],
      ocbcResponse: [],
      ocbcAmount: 0,
      ocbcTime: 600,
      orderRequest: [],
      startMsg: 0,
      clientSecret: "",
      servicesurchargeval: servicesurchargeArr,
      fomopay_payment_enable: 0,
      isCashButtonDisabled: false,
      isStripeButtonDisabled: false,
      delivery_self_collection: 0,
      onlyCOD: 0,

      showKoomipayCheckout: false,

      enabletwoctwop: 0,
      twoctwopMerchantID: "",
      enableallinpay: 0,
      allinpayMerchantID: "",
    };

    var site_maintenance_verify =
      cookie.load("site_maintenance_verify") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_verify") !== undefined
        ? cookie.load("site_maintenance_verify")
        : "";
    var site_maintenance_popup_open =
      cookie.load("site_maintenance_popup_open") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_popup_open") !== undefined
        ? cookie.load("site_maintenance_popup_open")
        : "";

    if (
      site_maintenance_popup_open === "1" &&
      (site_maintenance_verify === "" || site_maintenance_verify === "0")
    ) {
      this.props.history.push("/");
      return;
    }

    this.getActivityCounts();
    this.props.getAddonProList(orderOutletId);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date();
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;
    var nd = new Date(utc + 3600000 * offset);
    return nd;
  }

  changeBillingAddrChk() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var tempvlu = "";
      this.setState({
        billing_postcode: tempvlu,
        billing_delivery_address: tempvlu,
        billunitnumber1: tempvlu,
        billunitnumber2: tempvlu,
        billing_addrs_sameas: "no",
      });
    } else {
      this.setState(
        { billing_addrs_sameas: "yes" },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    }
  }

  changeGiftChk() {
    var enableGift = this.state.order_customer_send_gift;
    if (enableGift === "Yes") {
      this.setState({ order_customer_send_gift: "No" });
    } else {
      this.setState({ order_customer_send_gift: "Yes" });
    }
  }
  setBillingAddrs() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var postalCode = this.state.order_postcode;
      var custAddress = this.state.order_delivery_address;
      var floorNo = this.state.unitnumber1;
      var unitNo = this.state.unitnumber2;
      this.setState({
        billing_postcode: postalCode,
        billing_delivery_address: custAddress,
        billunitnumber1: floorNo,
        billunitnumber2: unitNo,
      });
    }
  }

  CheckBillingAddrChk(type) {
    var isSame = this.state.billing_addrs_sameas;
    var chkBox = false;
    var diplayTxt = "block";
    if (isSame === "yes") {
      chkBox = true;
      diplayTxt = "none";
    }

    return type === "checkbox" ? chkBox : diplayTxt;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState(
        { unitnumber1: event.target.value },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState(
        { unitnumber2: event.target.value },
        function () {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "alternative_phoneno") {
      this.setState({ alternative_phoneno: event.target.value });
    } else if (event.target.name === "specil_note") {
      this.setState({ order_specil_note: event.target.value });
    } else if (event.target.name === "reward_point") {
      this.setState({ reward_point_val: event.target.value });
    } else if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "recipient_name") {
      this.setState({ recipient_name: event.target.value });
    } else if (event.target.name === "recipient_contact_no") {
      this.setState({ recipient_contact_no: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    }
  }

  /* Validate Float Value */
  validateFloatval(e) {
    const re = /[0-9.]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* post code */
  changePostalCode(isFrm, event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;

    if (postalCode !== "" && parseInt(postCdLth) === 6) {
      var urlShringTxt =
        apiUrl +
        "outlets/find_postal_details?app_id=" +
        appId +
        "&postal_code=" +
        postalCode;

      axios.get(urlShringTxt).then((res) => {
        var custAddress = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet).length > 0) {
            var outletAddress =
              outltResulSet.zip_buno != ""
                ? outltResulSet.zip_buno + ", " + outltResulSet.zip_sname
                : outltResulSet.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
        }

        this.setState({
          billing_postcode: postalCode,
          billing_delivery_address: custAddress,
        });
      });
    } else {
      var temparrs = "";
      this.setState({
        billing_postcode: postalCode,
        billing_delivery_address: temparrs,
      });
    }
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["promotionwithoutuqc", "reward", "testtxt"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillMount() {
    /*this.props.getCartDetail();*/
  }
  componentWillReceiveProps(nextProps) {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      var paymentmode = "Stripe";
      var codPayment = 0;
      var stripePayment = 0;
      var koomipayPayment = 0;
      var omisePayment = 0;
      var omisePaynowPayment = 0;
      var omiseChainPayment = 0;
      var omisePaynowChainPayment = 0;
      var omise_chain_public_key = "";
      var xenditPayment = 0;
      var xendit_public_key = "";
      var offlinePayment = 0;
      var gcashPayment = 0;
      var paymayaPayment = 0;
      var omise_paynow_chain_public_key = "";
      var ocbcPayment = 0;
      var stripeexpressPayment = 0;
      var fomopayPayemnt = 0;
      var enabletwoctwop = 0;
      var twoctwopMerchantID = "";
      var enableallinpay = 0;
      var allinpayMerchantID = "";
      if (Object.keys(tampStArr).length > 0) {
        if (
          tampStArr.client_offline_enable == 1 &&
          tampStArr.client_offline_availability == 1
        ) {
          offlinePayment = 1;
          //paymentmode = "Offline";
        }

        if (
          tampStArr.stripe_express_enable == 1 &&
          tampStArr.stripe_user_id != ""
        ) {
          stripeexpressPayment = 1;
        }
        if (
          tampStArr.client_stripe_enable == 1 &&
          tampStArr.client_stripe_availability == 1
        ) {
          stripePayment = 1;
        }

        if (tampStArr.client_enable_koomipay == "1") {
          koomipayPayment = 1;
        }

        if (
          tampStArr.client_omise_enable == 1 &&
          tampStArr.client_omise_availability == 1
        ) {
          omisePayment = 1;
          if (paymentmode !== "Cash" && paymentmode !== "Stripe") {
            paymentmode = "Omise";
          }
        }
        if (
          tampStArr.client_ocbc_enable == 1 &&
          tampStArr.client_ocbc_availability == 1
        ) {
          ocbcPayment = 1;
          paymentmode = "Ocbc";
        }

        if (
          tampStArr.client_enable_omise_paynow == 1 &&
          tampStArr.client_omisepaynow_availability == 1
        ) {
          omisePaynowPayment = 1;
          if (paymentmode !== "Cash" && paymentmode !== "Omise") {
            paymentmode = "OmisePayNow";
          }
        }

        if (
          tampStArr.client_enable_omise_chain == "1" &&
          tampStArr.client_enable_omise_chain_card === "1"
        ) {
          omiseChainPayment = 1;
          if (tampStArr.client_omise_chain_mode === "0") {
            omise_chain_public_key = tampStArr.omise_main_acc.omise_public_test;
          } else {
            omise_chain_public_key = tampStArr.omise_main_acc.omise_public_live;
          }

          if (
            paymentmode !== "Cash" &&
            paymentmode !== "Omise" &&
            paymentmode !== "OmisePayNow"
          ) {
            paymentmode = "OmiseChain";
          }
        }

        if (
          tampStArr.client_fomopay_enable == 1 &&
          tampStArr.client_fomopay_availability == 1
        ) {
          fomopayPayemnt = 1;
          paymentmode = "Fomopay";
        }

        if (
          tampStArr.client_enable_omise_chain == "1" &&
          tampStArr.client_enable_omise_chain_paynow === "1"
        ) {
          omisePaynowChainPayment = 1;
          if (tampStArr.client_omise_chain_mode === "0") {
            omise_paynow_chain_public_key =
              tampStArr.omise_main_acc.omise_public_test;
          } else {
            omise_paynow_chain_public_key =
              tampStArr.omise_main_acc.omise_public_live;
          }

          if (
            paymentmode !== "Cash" &&
            paymentmode !== "Omise" &&
            paymentmode !== "OmisePayNow" &&
            paymentmode !== "OmisePayNowChain" &&
            paymentmode !== "Ocbc"
          ) {
            paymentmode = "OmiseChain";
          }
        }

        if (tampStArr.client_xendit_enable == "1") {
          xenditPayment = 1;
          xendit_public_key = tampStArr.omise_main_acc.client_xendit_public_key;
          paymayaPayment = 1;
          gcashPayment = 1;
          if (
            paymentmode !== "Cash" &&
            paymentmode !== "Omise" &&
            paymentmode !== "OmisePayNow" &&
            paymentmode !== "OmisePayNowChain" &&
            paymentmode !== "OmiseChain"
          ) {
            paymentmode = "Xendit";
          }
        }

        if (
          tampStArr.enable_2c2p_payment !== "" &&
          typeof tampStArr.enable_2c2p_payment !== undefined &&
          typeof tampStArr.enable_2c2p_payment !== "undefined"
        ) {
          if (tampStArr.enable_2c2p_payment == 1) {
            enabletwoctwop = 1;
            paymentmode = "2C2P";
            twoctwopMerchantID = tampStArr.client_two_c_two_p_merchant_id;
          }
        }

        if (
          tampStArr.enable_allinpay !== "" &&
          typeof tampStArr.enable_allinpay !== undefined &&
          typeof tampStArr.enable_allinpay !== "undefined"
        ) {
          if (tampStArr.enable_allinpay == 1) {
            enableallinpay = 1;
            paymentmode = "ALLINPAY";
            allinpayMerchantID = tampStArr.allinpay_merchant_id;
          }
        }

        if (
          tampStArr.client_cod_enable == 1 &&
          tampStArr.client_cod_availability == 1
        ) {
          codPayment = 1;
          paymentmode = "Cash";
        }
      }

      var onlyCOD = 0;
      if (
        stripePayment === 0 &&
        stripeexpressPayment === 0 &&
        ocbcPayment === 0 &&
        omisePayment === 0 &&
        omisePayment === 0 &&
        omisePaynowPayment === 0 &&
        omiseChainPayment === 0 &&
        omisePaynowChainPayment === 0 &&
        xenditPayment === 0 &&
        offlinePayment === 0 &&
        gcashPayment === 0 &&
        paymayaPayment === 0 &&
        fomopayPayemnt === 0 &&
        enabletwoctwop === 0 &&
        enableallinpay === 0
      ) {
        onlyCOD = 1;
      }

      this.setState({
        globalSettings: nextProps.settingsArr,
        cod_payment_enable: codPayment,
        koomipay_payment_enable: koomipayPayment,
        stripe_payment_enable: stripePayment,
        stripe_express_payment_enable: stripeexpressPayment,
        ocbc_payment_enable: ocbcPayment,
        omise_payment_enable: omisePayment,
        omisepaynow_payment_enable: omisePaynowPayment,
        omise_chain_payment_enable: omiseChainPayment,
        omisepaynow_chain_payment_enable: omisePaynowChainPayment,
        omise_chain_public_key: omise_chain_public_key,
        omise_paynow_chain_public_key: omise_paynow_chain_public_key,
        xendit_payment_enable: xenditPayment,
        xendit_public_key: xendit_public_key,
        paymentmodevalue: paymentmode,
        offline_payment_enable: offlinePayment,
        gcash_payment_enable: gcashPayment,
        paymaya_payment_enable: paymayaPayment,
        fomopay_payment_enable: fomopayPayemnt,
        onlyCOD: onlyCOD,
        enabletwoctwop: enabletwoctwop,
        twoctwopMerchantID: twoctwopMerchantID,
        enableallinpay: enableallinpay,
        allinpayMerchantID: allinpayMerchantID,
      });
    }

    if (this.state.activitycount_arr !== nextProps.activitycountArr) {
      this.setState({
        activitycount_arr: nextProps.activitycountArr,
        promotion_count: nextProps.activitycountArr.promotionwithoutuqc,
        reward_point_count: nextProps.activitycountArr.reward_ponits,
      });
    }

    if (this.state.overAllcart !== nextProps.overAllcart) {
      this.setState({
        overAllcart: nextProps.overAllcart,
        cartItems: nextProps.cartItems,
        cartDetails: nextProps.cartDetails,
        cartStatus: nextProps.cartStatus,
        cartTotalItmCount: nextProps.cartTotalItmCount,
        product_lead_time: nextProps.product_lead_time,
      });
    }

    if (nextProps.staticblack !== this.state.staticblacks) {
      var termsConditions = "";
      var qrCode = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "terms-and-conditions") {
            termsConditions = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "qr-code") {
            qrCode = Parser(data.staticblocks_description);
            return "";
          }
        });
      }
      termsConditions =
        termsConditions !== "" ? Parser(termsConditions) : termsConditions;
      this.setState({
        staticblacks: nextProps.staticblack,
        termsAndConditions: termsConditions,
        qrCode: qrCode,
      });

      setTimeout(function () {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }, 800);
    }

    if (this.state.updateCartResponse !== nextProps.updateCartResponse) {
      if (Object.keys(nextProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: nextProps.updateCartResponse },
          function () {
            var Response = nextProps.updateCartResponse;
            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct(
                    language.error,
                    Response[0].message
                  );
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  offlinePaymentPopup() {
    var magnfPopup = $.magnificPopup.instance;
    $.magnificPopup.open({
      items: {
        src: "#otherpayment-popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {
    hideLoader("proceedallinpaypayment");
    var top = $(".chk-payment").offset().top;
    $("html, body").animate({ scrollTop: top }, 1000);

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);
    smoothScroll(100, 70);

    const xenditscript = document.createElement("script");
    xenditscript.src = "https://js.xendit.co/v1/xendit.min.js";
    xenditscript.async = true;
    document.body.appendChild(xenditscript);
    cookie.remove("uniterror");

    var availabilityIdTxt = cookie.load("defaultAvilablityId");

    if (dineinId === availabilityIdTxt) {
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime("", orderTAT);
      var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
      var OrderHours = orderDateTmTxt.getHours();
      var OrderMunts = orderDateTmTxt.getMinutes();
      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = formatedDate;
      OrdDateTimeArr["OrdTime"] = orderTime;
      this.getServiceChargeAmt(OrdDateTimeArr);
    }

    $(document).on(
      "click",
      ".mfp-close",
      function (e) {
        e.preventDefault();
        this.setState({ isStripeButtonDisabled: false });
      }.bind(this)
    );
  }

  getServiceChargeAmt(OrdDateTimeArr) {
    var availabilityIdTxt = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var orderDate = "";
    var orderTime = "";
    if (availabilityIdTxt === deliveryId || availabilityIdTxt === dineinId) {
      orderDate = OrdDateTimeArr["OrdDate"];
      orderTime = OrdDateTimeArr["OrdTime"];
    }

    var servicesurchargeArr = this.state.servicesurchargeval;
    var online_servicecharge_type = servicesurchargeArr["servicechrg_type"];
    var onlineservice_displaylabel =
      servicesurchargeArr["servicechrg_displaylabel"];
    var online_servicecharge_per = 0,
      online_servicecharge_amount = 0,
      subchr_amount = 0;
    var subchr_type = "",
      subchr_date = "",
      subchr_frmtime = "",
      subchr_totime = "";

    var urlPrms =
      "&availabilityid=" +
      availabilityIdTxt +
      "&outletid=" +
      orderOutletId +
      "&orderdate=" +
      orderDate +
      "&ordertime=" +
      orderTime +
      "&";
    this.props.updateCartDetail();
    var urlShringTxt =
      apiUrl + "outlets/outletsubchargenew?app_id=" + appId + urlPrms;
    axios.get(urlShringTxt).then((res) => {
      if (res.data.status === "ok") {
        online_servicecharge_per =
          res.data.online_service_charge !== ""
            ? parseFloat(res.data.online_service_charge)
            : 0;
        online_servicecharge_amount = 0;

        if (
          (res.data.subcharge_apply === "Yes" &&
            availabilityIdTxt === deliveryId) ||
          availabilityIdTxt === dineinId
        ) {
          var subchr_data = res.data.result_set[0];
          if (res.data.subcharge_apply === "Yes") {
            subchr_amount =
              subchr_data.subchr_value !== ""
                ? parseFloat(subchr_data.subchr_value)
                : 0;
            subchr_type = subchr_data.subchr_type;
            if (subchr_data.subchr_type === "Time") {
              subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time);
              subchr_totime = this.tmConv24(subchr_data.subchr_to_time);
            } else {
              subchr_date = subchr_data.subchr_date;
            }
          }
        }
      }

      servicesurchargeArr["servicechrg_displaylabel"] =
        res.data.onlineservice_displaylbl;
      servicesurchargeArr["servicechrg_type"] = res.data.onlineservice_type;
      servicesurchargeArr["servicechrg_per"] = res.data.online_service_charge;
      servicesurchargeArr["surcharge_amount"] = subchr_amount;
      servicesurchargeArr["surcharge_type"] = subchr_type;
      servicesurchargeArr["surcharge_date"] = subchr_date;
      servicesurchargeArr["surcharge_frmtime"] = subchr_frmtime;
      servicesurchargeArr["surcharge_totime"] = subchr_totime;

      this.setState(
        { servicesurchargeval: servicesurchargeArr },
        function () {
          this.postOrder("initial", "Yes");
        }.bind(this)
      );
    });
  }

  tmConv24(time24) {
    var ts = time24;
    if (ts !== "") {
      var H = +ts.substr(0, 2);
      var h = H % 12 || 12;
      h = h < 10 ? "0" + h : h;
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
    }
    return ts;
  }

  componentDidUpdate() {
    this.checkOutAuthentication();
    var modevalue = this.state.paymentmodevalue;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );
    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var grandTotalAmount = parseFloat(calculatedAmount["grandTotalAmount"]);
    if (subTotalAmount > 0 && grandTotalAmount === 0) {
      modevalue = "promotion";
    }
    if (this.state.paymentmodevalue !== modevalue) {
      this.setState({ paymentmodevalue: modevalue });
    }

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    if (
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined &&
      cookie.load("UserMobile") != "" &&
      cookie.load("UserMobile") != undefined &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(settingsArr).length > 0 &&
      this.state.postorder_triggered === "no"
    ) {
      this.setState(
        { postorder_triggered: "yes", getDateTimeFlg: "yes" },
        function () {
          setTimeout(
            function () {
              //this.postOrder("initial", "Yes");
            }.bind(this),
            600
          );
          this.omeiseSecureCallback();
        }.bind(this)
      );
    }

    var sltdOrdDate = this.state.seleted_ord_date;
    var sltdOrdTime = this.state.seleted_ord_time;
    if (sltdOrdDate !== "" && sltdOrdTime !== "") {
      this.updateOrderDateTimeCookie();
    }

    var cartItemsArr = this.props.cartItems;
    var itmsArrrr = cartItemsArr.map((cartItms, itmsIndex) => {
      if (
        cartItms.pro_current_datetime_avl === "no" ||
        cartItms.pro_fullfill_datetime_avl === "no" ||
        cartItms.pro_current_stock_avl === "no" ||
        cartItms.pro_fullfill_stock_avl === "no"
      ) {
        $.magnificPopup.close();
        showAlert(language.error, language.productnotavailable);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.props.history.push("/");
        return false;
      }
      return "";
    });
  }

  /* For Advanced Slot */
  updateOrderDateTimeCookie() {
    var sltdOrdDate = this.state.seleted_ord_date;
    var sltdOrdTime = this.state.seleted_ord_time;
    var OrdDate = format(sltdOrdDate, "yyyy-MM-dd");
    var OrdHours = sltdOrdTime.getHours();
    var OrdMunts = sltdOrdTime.getMinutes();
    var OrdSecnd = sltdOrdTime.getSeconds();
    var ordDateTime = new Date(OrdDate);
    ordDateTime.setHours(OrdHours);
    ordDateTime.setMinutes(OrdMunts);
    ordDateTime.setSeconds(OrdSecnd);

    var deliveryDate = format(sltdOrdDate, "dd/MM/yyyy");
    var deliveryTime =
      this.pad(OrdHours) + ":" + this.pad(OrdMunts) + ":" + this.pad(OrdSecnd);

    cookie.save("orderDateTime", ordDateTime, { path: "/" });
    cookie.save("deliveryDate", deliveryDate, { path: "/" });
    cookie.save("deliveryTime", deliveryTime, { path: "/" });

    var isAdvanced = this.state.isAdvanced;
    var slotType = this.state.slotType;
    var orderSlotVal = "",
      orderSlotTxt = "",
      orderSlotStrTime = "",
      orderSlotEndTime = "";
    if (slotType === "2") {
      orderSlotVal = this.state.seleted_ord_slot;
      orderSlotTxt = this.state.seleted_ord_slotTxt;
      orderSlotStrTime = this.state.seleted_ord_slot_str;
      orderSlotEndTime = this.state.seleted_ord_slot_end;
    }

    cookie.save("orderSlotVal", orderSlotVal, { path: "/" });
    cookie.save("orderSlotTxt", orderSlotTxt, { path: "/" });
    cookie.save("orderSlotStrTime", orderSlotStrTime, { path: "/" });
    cookie.save("orderSlotEndTime", orderSlotEndTime, { path: "/" });
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  checkOutAuthentication() {
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (avilablityId === cateringId) {
      this.props.history.push("/");
      return false;
    }

    if (orderOutletId === "" || avilablityId === "") {
      showAlert(language.error, language.orderoutletdetailempty);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
      return false;
    }

    if (avilablityId === deliveryId && zoneIdTxt === "") {
      showAlert(language.error, language.orderzonedetailempty);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
      return false;
    }

    var cartDetailsArr = this.state.cartDetails;
    var settingsArr = this.state.globalSettings;
    var zonedetailsArr = this.props.zonedetails;

    if (this.state.cartStatus === "failure") {
      showAlert(language.error, language.yourcartempty);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
      return false;
    }

    if (
      this.state.cartStatus === "success" &&
      Object.keys(cartDetailsArr).length > 0 &&
      Object.keys(zonedetailsArr).length > 0
    ) {
      /*var cartMinAmount = (settingsArr.callcnter_min_amount !== '') ? parseFloat(settingsArr.callcnter_min_amount) : 0;*/

      var cartMinAmount =
        zonedetailsArr[0].zone_min_amount !== ""
          ? parseFloat(zonedetailsArr[0].zone_min_amount)
          : 0;

      var cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);

      var errorMsg =
        "You must have an order with a minimum of " +
        currencySybmol +
        cartMinAmount +
        " to place your order, your current order total is " +
        currencySybmol +
        cartSubTotal +
        ".";

      if (
        cookie.load("defaultAvilablityId") === deliveryId &&
        cartSubTotal < cartMinAmount
      ) {
        /*showCustomAlert('error',errorMsg);*/
        showAlert("Error", errorMsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        this.props.history.push("/");
        return false;
      }
    }

    var UserId =
      cookie.load("UserId") != "" && cookie.load("UserId") != undefined
        ? cookie.load("UserId")
        : "";
    if (UserId === "") {
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return false;
    }

    var UserMobile =
      cookie.load("UserMobile") !== "" &&
      cookie.load("UserMobile") !== undefined &&
      cookie.load("UserMobile") !== null
        ? cookie.load("UserMobile")
        : "";

    if (
      UserId !== "" &&
      (UserMobile === "" || UserMobile === "null" || UserMobile === "undefined")
    ) {
      showAlert(language.error, language.updatemobileno);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/myaccount");
      return false;
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  loadCartOverallData() {
    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      $("#dvLoading").fadeOut(1000);

      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr,
        this.state.delivery_self_collection
      );
      var promotionType = this.state.promotion_type;

      return (
        <div className="checkout-right-body-section">
          <div className="cart_table">
            <div className="cart_body">
              <div className="overall-parent">
                <div className="order-details-with-clear">
                  <div className="product_orders_top">
                    <h5>{language.youritems}</h5>

                    <div className="product_modify_cart">
                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title={language.clearall}
                      >
                        {language.clearall}
                      </a>
                    </div>
                  </div>

                  <div className="mobile-cart">
                    <h5>{language.youritems}</h5>
                    <div className="product_modify_cart">
                      <a
                        href="/menu"
                        className="hclear_cart modify_item"
                        title={language.additems}
                      >
                        {" "}
                        <img src={mofifyimage} alt={language.additems} />{" "}
                        {language.additems}{" "}
                      </a>

                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title={language.clearcart}
                      >
                        <img src={mofifydeleteimage} alt={language.clearcart} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="product-details-parent">
                  {this.loadCartList()}
                </div>
              </div>
            </div>

            {Object.keys(this.props.addonproductlist).length > 0 &&
              Object.keys(this.props.addonproductlist[0].subcategorie).length >
                0 && (
                <div className="check_pg">
                  <div className="container">
                    <div className="check_pg_inner">
                      <div className="chk-title text-center">
                        <h4>{language.wouldlikeadditems}</h4>
                      </div>
                      <OwlCarousel options={settingsourclient}>
                        {this.addonProductListing()}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              )}

            <div className="cart_footer">
              <div className="cart_row">
                <p className="text-uppercase">{language.subtotal}</p>
                <span>
                  {currencySybmol}
                  {calculatedAmount["cartSubTotalAmount"]}
                </span>
              </div>
              {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">{language.delivery}</p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["deliveryCharge"]).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(calculatedAmount["serviceCharge"]) > 0 && (
                <div className="cart_row">
                  {calculatedAmount["servicechrgType"] === "fixed" ? (
                    <p className="text-uppercase">
                      {calculatedAmount["servicechrgDisplaylbl"] !== ""
                        ? calculatedAmount["servicechrgDisplaylbl"]
                        : language.servicecharge}
                    </p>
                  ) : (
                    <p className="text-uppercase">
                      {calculatedAmount["servicechrgDisplaylbl"] !== ""
                        ? calculatedAmount["servicechrgDisplaylbl"]
                        : language.servicecharge}{" "}
                      ({calculatedAmount["servicechargePer"]}%)
                    </p>
                  )}
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["serviceCharge"]).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(calculatedAmount["surCharge"]) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">{language.surcharge}</p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["surCharge"]).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">
                    {language.additionaldelivery}
                  </p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["additionalDelivery"]).toFixed(
                      2
                    )}
                  </span>
                </div>
              )}

              {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                <div className="cart_row promo-cart-row">
                  <p className="text-uppercase">
                    {promotionType === "points"
                      ? language.discount
                      : language.promocode}
                  </p>
                  <span>
                    (-{currencySybmol}
                    {parseFloat(calculatedAmount["promotionAmount"]).toFixed(2)}
                    )
                  </span>
                  <a
                    href="/"
                    onClick={this.removePointsAndPromo.bind(this, "fromclk")}
                    className="cart_remove"
                  >
                    <img src={crossImg} alt={language.removediscount} />
                  </a>
                </div>
              )}
              {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                <div className="cart_row gst-row">
                  <p className="text-uppercase">
                    GST ({calculatedAmount["orderDisplayGst"]} %)
                  </p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                <div className="cart_row gst-row">
                  <p className="text-uppercase">
                    {language.voucherdiscountamount}
                  </p>
                  <span>
                    {currencySybmol}
                    {parseFloat(
                      calculatedAmount["voucherDiscountAmount"]
                    ).toFixed(2)}
                  </span>
                </div>
              )}

              <div className="cart_row grant-total-cls">
                <p className="text-uppercase">{language.total}</p>
                <span>
                  <sup>{currencySybmol}</sup>
                  {calculatedAmount["grandTotalAmount"]}
                </span>
                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div className="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type}{" "}
                    {currencySybmol}
                    {cartDetailsArr.cart_special_discount} {language.applied}
                  </div>
                )}
              </div>

              {calculatedAmount["orderDisplayGstInclusive"] > 0 &&
                calculatedAmount["orderGstInclusiveAmount"] > 0 && (
                  <p className="gst-inclusive-lbl">
                    {calculatedAmount["orderGstInclusiveAmountText"]}
                  </p>
                )}
            </div>

            <div className="checkout_bar">
              {cookie.load("defaultAvilablityId") === deliveryId &&
                this.loadDeliveryPercentageBar()}
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentageBar() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr,
        this.state.delivery_self_collection
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {currencySybmol}
              {remainAmnt} {language.morefreedelivery}
            </p>
          </div>
        </div>
      );
    }
  }

  loadCartList() {
    var cartItemsArr = this.state.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="product-details-container">
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                {product.cart_item_product_image !== "" ? (
                  <img
                    src={product.cart_item_product_image}
                    alt={stripslashes(product.cart_item_product_name)}
                  />
                ) : (
                  <img
                    src={noimage}
                    alt={stripslashes(product.cart_item_product_name)}
                  />
                )}
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>
                <h4>
                  {product.cart_item_voucher_id !== "" &&
                  product.cart_item_voucher_id != null
                    ? "Discount Applied"
                    : ""}{" "}
                </h4>

                {product.cart_item_type === "Gift" ? (
                  <div>
                    <p>
                      {" "}
                      {language.name}:
                      {product.cart_item_product_voucher_gift_name !== "" &&
                      product.cart_item_product_voucher_gift_name != null
                        ? " " + product.cart_item_product_voucher_gift_name
                        : ""}
                    </p>

                    <p>
                      {" "}
                      {language.mobileno}:
                      {product.cart_item_product_voucher_gift_mobile !== "" &&
                      product.cart_item_product_voucher_gift_mobile != null
                        ? " " + product.cart_item_product_voucher_gift_mobile
                        : ""}
                    </p>

                    <p>
                      {" "}
                      {language.email}:
                      {product.cart_item_product_voucher_gift_email !== "" &&
                      product.cart_item_product_voucher_gift_email != null
                        ? " " + product.cart_item_product_voucher_gift_email
                        : ""}
                    </p>

                    <p>
                      {" "}
                      {language.message}:{" "}
                      {product.cart_item_product_voucher_gift_message !== "" &&
                      product.cart_item_product_voucher_gift_message != null
                        ? " " + product.cart_item_product_voucher_gift_message
                        : ""}{" "}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    {currencySybmol} {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type}{" "}
                    {language.discountapplied}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>
                  {currencySybmol}
                  {product.cart_item_total_price}
                </p>
              </div>

              {product.cart_item_voucher_product_free != 1 ? (
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.updateCartQty.bind(this, product, "decr")}
                  >
                    -
                  </span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span
                    className="qty_plus"
                    onClick={this.updateCartQty.bind(this, product, "incr")}
                  >
                    +
                  </span>
                </div>
              ) : (
                <div className="qty_bx free_product">
                  <span className="qty_minus">-</span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span className="qty_plus">+</span>
                </div>
              )}
            </div>

            <a
              href="/"
              onClick={this.deleteCartItm.bind(this, product)}
              className="cart_remove"
            >
              <img src={crossImg} alt="Delete" />
            </a>
          </div>
          {this.showProAvblErrorMgs(product)}
          {this.showProStockErrorMgs(product)}
        </div>
      ));
    }
  }

  showProAvblErrorMgs(cartItms) {
    var errorMgs = "";
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "delivery/pickup";
    if (availability === deliveryId) {
      showtext = "delivery";
    } else if (availability === pickupId) {
      showtext = "pickup";
    }
    if (cartItms.pro_current_datetime_avl === "no") {
      errorMgs = language.checkouterror1;
    } else if (cartItms.pro_fullfill_datetime_avl === "no") {
      errorMgs =
        "This product is not available for " +
        showtext +
        " on the selected date and time. Please refer to the product description.";
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>;
    }
  }

  showProStockErrorMgs(cartItms) {
    var errorMgs = "";
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "delivery/pickup";
    if (availability === deliveryId) {
      showtext = "delivery";
    } else if (availability === pickupId) {
      showtext = "pickup";
    }
    if (cartItms.pro_current_stock_avl === "no") {
      errorMgs = language.checkouterror2;
    } else if (cartItms.pro_fullfill_stock_avl === "no") {
      errorMgs =
        "The selected quantity is not available for " +
        showtext +
        " on the selected date and time.";
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>;
    }
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    this.removePointsAndPromo("frmFunct");
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    this.removePointsAndPromo("frmFunct");
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice =
          modVlPrice > 0 ? " ( + " + currencySybmol + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice =
            comboPrice > 0 ? " ( + " + currencySybmol + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice =
          modValPrice > 0 ? " ( + " + currencySybmol + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;

    if (type === "points") {
      if (parseFloat(reedemPointVal) === 0 || reedemPointVal === "") {
        showAlert(language.errorm, language.invalidredeempoint);
      }
    } else if (type === "promoCode") {
      if (promoCodeVal === "") {
        return false;
      }
    }

    var cartDetailsArr = this.state.cartDetails;
    var cartItemsSateArr = this.state.cartItems;

    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );

    if (type === "points") {
      if (reedemPointVal > 0) {
        showLoader("applypoints_cls", "class");

        var subtotal_grand_total = subTotal;

        var promoTionArr = Array();
        promoTionArr["promotionApplied"] = this.state.promotion_applied;
        promoTionArr["promotionAmount"] = this.state.promotion_amount;
        promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
        var serviceSubChrArr = this.state.servicesurchargeval;
        var calculatedAmount = getCalculatedAmount(
          this.state.globalSettings,
          this.props.zonedetails,
          this.state.cartDetails,
          promoTionArr,
          serviceSubChrArr,
          this.state.delivery_self_collection
        );
        var grandTotalAmount = parseFloat(calculatedAmount["grandTotalAmount"]);

        if (
          this.state.globalSettings.client_rewards_apply_grand_total === "1"
        ) {
          if (
            reedemPointVal > subtotal_grand_total ||
            reedemPointVal === grandTotalAmount
          ) {
            subtotal_grand_total = grandTotalAmount;
          } else {
            subtotal_grand_total = grandTotalAmount;
          }
        } else {
          subtotal_grand_total = subTotal;
        }

        var postObject = {
          app_id: appId,
          reference_id: cookie.load("UserId"),
          redeem_point: reedemPointVal,
          cart_amount: subtotal_grand_total,
        };

        axios
          .post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              promotionApplied = "Yes";
              promotionType = "points";
              promotionAmount = pointDet.points_amount;
              promotionSource = reedemPointVal;
              promoIsDelivery = "";
              promoCodeVal = "";
              usedPoints = reedemPointVal;
              showCustomAlert("success", language.rewardsappliedsuccess);
            } else {
              if (promotionType === "points") {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
                usedPoints = 0;
              }
              showCustomAlert("error", language.rewardsappliedfail);
            }

            hideLoader("applypoints_cls", "class");
            cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
            cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
            cookie.save("promotionApplied", promotionApplied, { path: "/" });
            cookie.save("promotionType", promotionType, { path: "/" });
            cookie.save("promotionAmount", promotionAmount, { path: "/" });
            cookie.save("promotionSource", promotionSource, { path: "/" });
            cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });
            cookie.save("usedPoints", usedPoints, { path: "/" });

            this.setState({
              reward_point_val: reedemPointVal,
              promo_code_val: promoCodeVal,
              promotion_applied: promotionApplied,
              promotion_type: promotionType,
              promotion_amount: promotionAmount,
              promotion_source: promotionSource,
              promoIs_delivery: promoIsDelivery,
              used_reward_point: usedPoints,
            });
          });
      }
    } else if (type === "promoCode") {
      showLoader("applypromo_cls", "class");

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
      var avilablityId = cookie.load("defaultAvilablityId");
      var deliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var AdditionalDeliveryAmnt = parseFloat(
        calculatedAmount["additionalDelivery"]
      );

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        promo_code: promoCodeVal,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: avilablityId,
        outlet_id: cookie.load("orderOutletId"),
        delivery_amount: deliveryAmnt,
        additional_delivery_amount: AdditionalDeliveryAmnt,
        totalDeliveryCharge: deliveryAmnt + AdditionalDeliveryAmnt,
      };

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            reedemPointVal = "";
            usedPoints = 0;
            showCustomAlert("success", language.promotionappliedsuccess);

            if (
              typeof pointDet.prmo_type !== "undefined" &&
              pointDet.prmo_type === "add_products"
            ) {
              cookie.save("promo_type", "add_products");
              this.props.getCartDetail();
            }
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : language.notappliedpromocode;
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            showCustomAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");
          cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
          cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
          cookie.save("promotionApplied", promotionApplied, { path: "/" });
          cookie.save("promotionType", promotionType, { path: "/" });
          cookie.save("promotionAmount", promotionAmount, { path: "/" });
          cookie.save("promotionSource", promotionSource, { path: "/" });
          cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });
          cookie.save("usedPoints", usedPoints, { path: "/" });

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
          });
        });
    }
  }

  removePointsAndPromo(flag, event) {
    if (flag === "fromclk") {
      event.preventDefault();
    }
    var paymentmode = this.state.paymentmodevalue;
    if (paymentmode === "promotion") {
      var settingsArr = this.state.globalSettings;
      var paymentmode = "Stripe";
      if (Object.keys(settingsArr).length > 0) {
        if (
          settingsArr.client_cod_enable == 1 &&
          settingsArr.client_cod_availability == 1
        ) {
          paymentmode = "Cash";
        }

        if (
          settingsArr.client_offline_enable == 1 &&
          settingsArr.client_offline_availability == 1
        ) {
          paymentmode = "Offline";
        }

        if (
          settingsArr.client_omise_enable == 1 &&
          settingsArr.client_omise_availability == 1 &&
          paymentmode !== "Cash"
        ) {
          paymentmode = "Omise";
        }
      }
    }

    removePromoCkValue();
    this.resetPromoProducts();

    this.setState({
      reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      used_reward_point: 0,
      paymentmodevalue: paymentmode,
    });
  }

  resetPromoProducts() {
    if (cookie.load("promo_type") === "add_products") {
      var qs = require("qs");
      let otpObject = {
        app_id: appId,
        customer_id: cookie.load("UserId"),
        product_promotion_type: "add_products",
      };

      axios
        .post(apiUrlV2 + "cart/delete_promo_type", qs.stringify(otpObject))
        .then((response) => {
          cookie.remove("promo_type");
          this.props.getCartDetail();
        });
    }
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  payCash(event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }
    $(".cash_checkout_btn").hide();
    this.setState({ isCashButtonDisabled: true });
    showLoader("cash_checkout_btn", "class");
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    this.postOrder(1, "Yes", "", "", "", "Yes");
  }

  showloaderStripe() {
    showLoader("proceedpayment", "class");
  }

  payOffline(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    this.postOrder(10);
  }

  postPromotionOrder(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
    this.postOrder(4);
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.validateimage} alt="Processing" />
          </div>
          <div className="process_right">
            <h5>{language.processing}</h5>
            <p>{language.validatingcardinfo}</p>
          </div>
        </div>
      );
    }
  }
  /**/

  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} alt="Processing" />
        </div>
        <div className="process_right">
          <h5>{language.processing}</h5>
          <p>{language.placingordernow}</p>
        </div>
      </div>
    );
  }
  /**/

  /* show online payment mode loading */
  amountCaptureLoading() {
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );
    var grandTotal = parseFloat(calculatedAmount["grandTotalAmount"]);

    if (this.state.paymentmodevalue !== "Cash" && grandTotal > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.completingpaymentimage} alt="Processing" />
          </div>
          <div className="process_right">
            <h5>{language.processing}</h5>
            <p>{language.completingonlinepayment}</p>
          </div>
        </div>
      );
    }
  }
  /**/

  makeid(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /* this fuction used to post order to biz panel */
  postOrder(
    paymentMode,
    validation = "No",
    captureID = "",
    payGetWayType = "",
    Paymentpop = "No",
    cashPay = "No",
    allinpay = "No"
  ) {
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return false;
    }

    if (
      cookie.load("defaultAvilablityId") == deliveryId &&
      paymentMode !== "initial"
    ) {
      var unitNoOne = this.state.unitnumber1;
      var unitNoTwo = this.state.unitnumber2;
      if (unitNoOne == "" && unitNoTwo == "") {
        /*showAlert('Error', 'Please enter unit No.');
        $.magnificPopup.open({
          items: {
            src: '.alert_popup'
          },
          type: 'inline'
        });*/

        if (
          validation == "Yes" &&
          typeof cookie.load("uniterror") === "undefined"
        ) {
          cookie.save("uniterror", "yes");
          var res = confirm(language.didnotenterfloor);

          if (!res) {
            //error = "1";
            return false;
          }
        }

        /*   if (paymentMode === 1) {
          $.magnificPopup.close();
        }

        if (Object.keys(this.props.addonproductlist).length > 0) {
          smoothScroll(500, 450);
        } else {
          smoothScroll(500, 270);
        }
        showCustomCenterAlert("error", "Please enter unit No.");
        $("#unit_no1_id").focus();

        return false; */
      }

      var billingAddrsSameas = this.state.billing_addrs_sameas;
      var billingPostcode = this.state.billing_postcode;
      var billingAddrss = this.state.billing_delivery_address;
      if (
        billingAddrsSameas === "no" &&
        (billingPostcode === "" || billingAddrss === "")
      ) {
        showAlert(language.error, language.enterbillingaddress);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var finalcartItems = this.state.cartItems;

    var products = [];
    var row = this.state.cartItems.map(
      function (item, i) {
        var modifierdata = [];
        var comboData = [];
        var ComboLen = item.set_menu_component.length;
        var modifierLen = item.modifiers.length;
        if (item.cart_item_type === "Modifier" && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = [];
            if (item.modifiers[l].modifiers_values[0].cart_modifier_qty > 0) {
              modifierVal.push({
                modifier_value_id:
                  item.modifiers[l].modifiers_values[0].cart_modifier_id,
                modifier_value_qty:
                  item.modifiers[l].modifiers_values[0].cart_modifier_qty,
                modifier_value_name:
                  item.modifiers[l].modifiers_values[0].cart_modifier_name,
                modifier_value_price:
                  item.modifiers[l].modifiers_values[0].cart_modifier_price,
              });
            }
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal,
            });
          }
        } else if (item.cart_item_type === "Component" && ComboLen > 0) {
          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = [];
            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers;

            var combomodifierdata = [];
            if (comboModifier.length > 0) {
              for (var p = 0; p < comboModifier.length; p++) {
                var comboModifierVal = [];
                if (
                  comboModifier[p].modifiers_values[0].cart_modifier_qty > 0
                ) {
                  comboModifierVal.push({
                    modifier_value_id:
                      comboModifier[p].modifiers_values[0].cart_modifier_id,
                    modifier_value_qty:
                      comboModifier[p].modifiers_values[0].cart_modifier_qty,
                    modifier_value_name:
                      comboModifier[p].modifiers_values[0].cart_modifier_name,
                    modifier_value_price:
                      comboModifier[p].modifiers_values[0].cart_modifier_price,
                  });
                }
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal,
                });
              }
            }

            var comborVal = [];
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length;
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                if (
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_qty > 0
                ) {
                  comborVal.push({
                    product_id:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_id,
                    product_name:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_name,
                    product_sku:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_sku,
                    product_qty:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_qty,
                    product_price:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_price,
                    modifiers: combomodifierdata,
                  });
                }
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal,
            });
          }
        }

        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_actual_unit_price: item.cart_item_actual_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          condiments: "",
          modifiers: modifierdata,
          bakers_modifiers: "",
          menu_set_components: comboData,
          baby_pack: "",
          product_special_notes: item.cart_item_special_notes,
          voucher_gift_name: item.cart_item_product_voucher_gift_name,
          voucher_gift_email: item.cart_item_product_voucher_gift_email,
          voucher_gift_mobile: item.cart_item_product_voucher_gift_mobile,
          voucher_gift_message: item.cart_item_product_voucher_gift_message,
          order_item_id:
            item.cart_voucher_order_item_id != "0"
              ? item.cart_voucher_order_item_id
              : "",
          voucher_free_product: item.cart_item_voucher_product_free,
          order_voucher_id: item.cart_item_voucher_id,
        });

        return products;
      }.bind(this)
    );

    /* if  merge order date */
    var orderDate = "";

    if (cookie.load("defaultAvilablityId") !== dineinId) {
      var seletedOrdDate = this.state.seleted_ord_date;
      var seletedOrdTime = this.state.seleted_ord_time;

      /* For Advanced Slot */
      var order_is_timeslot = "No",
        ordSlotStrTm = "",
        ordSlotEndTm = "";
      if (seletedOrdDate !== "" && seletedOrdTime !== "") {
        var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();

        var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

        orderDate = formatedDate + " " + orderTime;
        cookie.save("checkout_order_date", orderDate, { path: "/" });

        if (this.state.isAdvanced === "yes" && this.state.slotType === "2") {
          order_is_timeslot = "Yes";
          ordSlotStrTm = this.state.seleted_ord_slot_str;
          ordSlotEndTm = this.state.seleted_ord_slot_end;
        }
      }

      if (cookie.load("defaultAvilablityId") !== dineinId && orderDate == "") {
        var cookieDate =
          typeof cookie.load("checkout_order_date") === "undefined" ||
          cookie.load("checkout_order_date") === ""
            ? ""
            : cookie.load("checkout_order_date");
        orderDate = cookieDate;
      }

      if (orderDate === "" && paymentMode !== "initial") {
        showAlert(language.error, language.selectorderdatetime);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    if (cookie.load("defaultAvilablityId") == dineinId) {
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime("", orderTAT);
      var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
      var OrderHours = orderDateTmTxt.getHours();
      var OrderMunts = orderDateTmTxt.getMinutes();
      var OrderSecnd = orderDateTmTxt.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      var orderDate = formatedDate + " " + orderTime;
    }

    if (
      this.state.chk_tarms_contn !== "Yes" &&
      this.state.termsAndConditions !== "" &&
      paymentMode !== "initial"
    ) {
      $(".tarms_chkbox_div").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      return false;
    }

    if (paymentMode === "initial") {
      paymentMode = 2;
    }

    /*orderDate = '2019-07-10 10:15';*/

    /* insert customner details */
    if (validation !== "Yes") {
      var addressObject = {};
      var customerAddressId = 0;
      addressObject = {
        app_id: appId,
        customer_first_name: cookie.load("UserFname"),
        customer_last_name: cookie.load("UserLname"),
        customer_phone: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: cookie.load("orderDeliveryAddress"),
        customer_postal_code: cookie.load("orderPostalCode"),
        customer_address_name: this.state.unitnumber1,
        customer_address_name2: this.state.unitnumber2,
        latitude:
          cookie.load("latitude") !== "" &&
          typeof cookie.load("latitude") !== undefined &&
          typeof cookie.load("latitude") !== "undefined"
            ? cookie.load("latitude")
            : "",
        longitude:
          cookie.load("longitude") !== "" &&
          typeof cookie.load("longitude") !== undefined &&
          typeof cookie.load("longitude") !== "undefined"
            ? cookie.load("longitude")
            : "",
        refrence: cookie.load("UserId"),
        customer_status: "A",
        customer_order_status: "order",
      };
      if (cookie.load("defaultAvilablityId") === deliveryId) {
        axios
          .post(
            apiUrl + "customer/secondary_address_add",
            qs.stringify(addressObject)
          )
          .then((res) => {
            if (res.data.status === "ok") {
              customerAddressId = res.data.insert_id;
            }
          });
      }
    }

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    var cartDetailsArr = this.state.cartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );

    var BillingAddress = this.state.billing_delivery_address;
    var BillingPostalCode = this.state.billing_postcode;
    var BillingUnitNo1 = this.state.billunitnumber1;
    var BillingUnitNo2 = this.state.billunitnumber2;

    if (this.state.billing_addrs_sameas === "yes") {
      BillingAddress = cookie.load("orderDeliveryAddress");
      BillingPostalCode = cookie.load("orderPostalCode");
      BillingUnitNo1 = this.state.unitnumber1;
      BillingUnitNo2 = this.state.unitnumber2;
    }

    var custBirthdateUpdate = "",
      custBirthdate = "",
      custGender = "";
    if (this.state.cust_birthdate_update === "yes") {
      custBirthdateUpdate = this.state.cust_birthdate_update;
      var birthdateTxt = this.state.cust_birthdate;
      custBirthdate = format(birthdateTxt, "yyyy-MM-dd");
      custGender = this.state.cust_gender;
      if (validation !== "Yes") {
        cookie.save("UserBirthdate", custBirthdate, { path: "/" });
      }
    }

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    var postObject = {};
    var payment_type = "";
    if (payGetWayType === "OmiseChain") {
      payment_type = "Omise";
    } else if (
      payGetWayType === "OmisePayNow" ||
      payGetWayType === "OmisePayNowChain"
    ) {
      payment_type = "Omise PayNow";
    } else if (payGetWayType === "Gcash") {
      payment_type = "Gcash";
    } else if (payGetWayType === "paymaya") {
      payment_type = "paymaya";
    } else {
      payment_type = payGetWayType;
    }

    var referenceNewId = getReferenceID();
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");

    var table_number = cookie.load("orderTableNo");

    if (
      table_number !== "" &&
      table_number !== "undefined" &&
      table_number !== undefined
    ) {
      table_number = table_number.toUpperCase();
    }

    var redeem_sub_total = parseFloat(
      calculatedAmount["cartSubTotalAmount"]
    ).toFixed(2);

    if (
      this.state.globalSettings.client_rewards_apply_grand_total === "1" &&
      promotionType === "points"
    ) {
      if (
        promotionAmount > parseFloat(calculatedAmount["overall_grand_total"]) ||
        promotionAmount === parseFloat(calculatedAmount["overall_grand_total"])
      ) {
        redeem_sub_total = parseFloat(calculatedAmount["overall_grand_total"]);
      } else {
        redeem_sub_total = parseFloat(calculatedAmount["overall_grand_total"]);
      }
    }

    let paymentGatewayDetails = "";
    if (
      payGetWayType === "OmisePayNow" ||
      payGetWayType === "OmisePayNowChain"
    ) {
      paymentGatewayDetails = {
        payment_type: "Omise PayNow",
        payment_status: "Success",
      };
    } else if (payGetWayType === "Koomipay") {
      paymentGatewayDetails = {
        payment_type: "Koomipay",
        payment_status: "Success",
      };
    }

    postObject = {
      /* cart details */
      app_id: appId,
      availability_id: cookie.load("defaultAvilablityId"),
      sub_total: parseFloat(calculatedAmount["cartSubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      order_voucher_discount_amount:
        parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0
          ? calculatedAmount["voucherDiscountAmount"]
          : "0",
      outlet_id: cookie.load("orderOutletId"),
      zone_id: zoneIdTxt,
      table_number: table_number,
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      reference_new_id: referenceNewId,
      login_type: userAccountType,

      /* For Advanced Slot */
      order_is_timeslot: order_is_timeslot,
      order_pickup_time_slot_from: ordSlotStrTm,
      order_pickup_time_slot_to: ordSlotEndTm,

      order_remarks: this.state.order_specil_note,
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      customer_address_id: customerAddressId,
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: cookie.load("orderDeliveryAddress"),
      customer_address_line2: "",
      customer_postal_code: cookie.load("orderPostalCode"),
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,
      customer_alter_contact_no: this.state.alternative_phoneno,
      latitude:
        cookie.load("latitude") !== "" &&
        typeof cookie.load("latitude") !== undefined &&
        typeof cookie.load("latitude") !== "undefined"
          ? cookie.load("latitude")
          : "",
      longitude:
        cookie.load("longitude") !== "" &&
        typeof cookie.load("longitude") !== undefined &&
        typeof cookie.load("longitude") !== "undefined"
          ? cookie.load("longitude")
          : "",

      customer_birthdate_update: custBirthdateUpdate,
      customer_birthdate: custBirthdate,
      customer_gender: custGender,

      billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir: "test",

      payment_type: payment_type === "Xendit" ? "xendit" : payment_type,
      payment_getway_details: paymentGatewayDetails,
      order_payment_retrieved:
        (payGetWayType === "OmisePayNow" ||
          payGetWayType === "OmisePayNowChain") &&
        captureID !== ""
          ? "Yes"
          : "No",
      omise_log_id:
        (payGetWayType === "OmisePayNow" ||
          payGetWayType === "OmisePayNowChain") &&
        captureID !== ""
          ? this.state.omise_log_id
          : "",
      order_capture_token: captureID,
      order_payment_getway_type: "",
      order_payment_getway_status: "Yes",
      order_local_payment_reference: this.state.payment_ref_id,

      /* additional paras */
      delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),

      /* Sur & Service Chareges */
      order_subcharge_amount: parseFloat(calculatedAmount["surCharge"]).toFixed(
        2
      ),
      order_service_charge: calculatedAmount["servicechargePer"],
      order_service_charge_amount: parseFloat(
        calculatedAmount["serviceCharge"]
      ).toFixed(2),
      order_servicecharge_displaylabel:
        calculatedAmount["servicechrgDisplaylbl"],

      order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),
      order_data_encode: "Yes",

      /* discount */
      order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",

      order_special_discount_amount: cartDetailsArr.cart_special_discount,
      order_special_discount_type: cartDetailsArr.cart_special_discount_type,
      order_customer_send_gift: this.state.order_customer_send_gift,
      order_recipient_name: this.state.recipient_name,
      order_recipient_contact_no: this.state.recipient_contact_no,
      order_gift_message: this.state.gift_message,
      product_leadtime: this.state.product_lead_time,
      delivery_self_collection: this.state.delivery_self_collection,

      /* validation purpose*/
      promotion_applied_text: promotionApplied,
      promo_applied_delivery_text: promoIsDelivery,
      is_freeDelivery_text: promoIsDelivery,
    };

    if (promotionApplied === "Yes" && promoIsDelivery == "Yes") {
      var categoryIdsDet = this.getProductIdsDet(finalcartItems);
      postObject["coupon_applied"] = "Yes";
      postObject["promo_code"] = promotionSource;
      postObject["category_id"] = categoryIdsDet;
    }

    /*  promo code object - start */
    if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
        postObject["redeem_sub_total"] = redeem_sub_total;
      }
    }

    postObject["reward_point_status"] = "Yes";
    postObject["validate_product_specialprice"] = "Yes";

    if (payGetWayType === "Ocbc") {
      if (validation === "Yes") {
        postObject["order_ocbc_reference_id"] = this.makeid(10);
      } else {
        postObject["order_ocbc_reference_id"] = cookie.load("ocbcreferenceID");
      }
      cookie.save("ocbcreferenceID", postObject["order_ocbc_reference_id"], {
        path: "/",
      });
    }

    if (
      payGetWayType === "Gcash" ||
      payGetWayType === "paymaya" ||
      payGetWayType == "Xendit"
    ) {
      if (validation === "Yes") {
        postObject["order_xenditEwallet_reference_id"] = this.makeid(10);
      } else {
        postObject["order_xenditEwallet_reference_id"] = cookie.load(
          "xenditewalletReferenceId"
        );
      }
      cookie.save(
        "xenditewalletReferenceId",
        postObject["order_xenditEwallet_reference_id"],
        { path: "/" }
      );
    }

    if (payGetWayType === "Fomopay") {
      if (validation === "Yes") {
        postObject["order_fomopay_reference_id"] = this.makeid(10);
      } else {
        postObject["order_fomopay_reference_id"] =
          cookie.load("fomopayReferenceId");
      }
      cookie.save(
        "fomopayReferenceId",
        postObject["order_fomopay_reference_id"],
        { path: "/" }
      );
    }

    if (payGetWayType === "Koomipay") {
      if (validation === "Yes") {
        postObject["order_id_for_koomipay"] = "NINJA_" + this.makeid(10);
      } else {
        postObject["order_id_for_koomipay"] = cookie.load("orderIdForKoomipay");
      }
      cookie.save("orderIdForKoomipay", postObject["order_id_for_koomipay"], {
        path: "/",
      });
      if (captureID) {
        postObject["order_koomipay_reference_id"] = captureID;
      }
    }

    window.sessionStorage.setItem("postObject", qs.stringify(postObject));
    this.setState({ orderRequest: postObject });
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes";
    }
    postObject["connect_platform_fee"] = cookie.load("platform_fee");
    postObject["connect_stripe_account_id"] = cookie.load("stripe_account_id");
    postObject["connect_total_amount"] = cookie.load("connect_total_amount");
    if (cookie.load("chopchoptoken") === "Yes") {
      postObject["order_is_promobuddy"] = "Yes";
    }

    const submittedOrder = { ...postObject };

    return axios
      .post(apiUrl + "ordersv1/submit_order", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ orderValidFail: 0 });
          hideLoader("xenditbtn", "class");

          if (validation !== "Yes") {
            var localOrderNo = res.data.common.local_order_no;
            this.setState({ placingorderimage: tickImage });
            /* capture payemnt */
            if (payGetWayType === "Koomipay") {
              this.captureKoomipayAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else if (payGetWayType === "Stripe") {
              // Autocapture
              this.setState({ completingpaymentimage: tickImage });
              // this.showSuccessPage(localOrderNo, res.data.common.order_primary_id);
              this.placeOrder(localOrderNo, res.data.common.order_primary_id);
              // this.captureAmount(
              //   captureID,
              //   res.data.common.order_primary_id,
              //   localOrderNo
              // );
            } else if (payGetWayType === "Omise") {
              this.captureOmiseAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else if (payGetWayType === "Xendit") {
              this.captureXenditAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else if (payGetWayType === "OmisePayNow") {
              this.showSuccessPage(
                localOrderNo,
                res.data.common.order_primary_id
              );
            } else if (payGetWayType === "OmiseChain") {
              this.captureOmiseChainAmount(
                captureID,
                res.data.common.order_primary_id,
                localOrderNo
              );
            } else if (payGetWayType === "OmisePayNowChain") {
              this.showSuccessPage(
                localOrderNo,
                res.data.common.order_primary_id
              );
            } else if (payGetWayType === "Fomopay") {
              this.showSuccessPage(
                localOrderNo,
                res.data.common.order_primary_id
              );
            } else {
              this.showSuccessPage(
                localOrderNo,
                res.data.common.order_primary_id
              );

              if (paymentMode === 1) {
                this.props.destroyCartDetail();
                this.setState({ isCashButtonDisabled: false });
              }
            }
          } else {
            if (
              cookie.load("continuetochekout") === "Yes" &&
              this.state.onlyCOD === 1
            ) {
              console.log(
                cookie.load("continuetochekout"),
                this.state.globalSettings,
                "continuetochekout"
              );
              this.payCash();
              cookie.remove("continuetochekout", { path: "/" });
              return false;
            }

            if (
              validation === "Yes" &&
              cashPay === "Yes" &&
              paymentMode === 1 &&
              this.state.isCashButtonDisabled === true
            ) {
              this.postOrder(1);
              $.magnificPopup.open({
                items: {
                  src: ".processing",
                },
                type: "inline",
                showCloseBtn: false,
                midClick: true,
                closeOnBgClick: false,
              });
            } else if (payGetWayType == "StripeConnect") {
              var grandTotalAmount = parseFloat(
                calculatedAmount["grandTotalAmount"]
              );

              var postObject = {
                app_id: appId,
                paid_amount: parseFloat(grandTotalAmount).toFixed(2),
                availability_id: cookie.load("defaultAvilablityId"),
                orderOutlet_Id: cookie.load("orderOutletId"),
                customer_id: cookie.load("UserId"),
                payment_reference: stripeReference,
              };

              axios
                .post(
                  apiUrl + "paymentv4/stripeClientTokenGenerateNew",
                  qs.stringify(postObject)
                )
                .then((statusRes) => {
                  if (statusRes.data.status === "ok") {
                    this.setState({
                      stripekey: statusRes.data.result_set,
                      clientSecret: statusRes.data.result_set.client_secret,
                    });
                    cookie.save(
                      "clientSecret",
                      statusRes.data.result_set.client_secret
                    );
                    cookie.save(
                      "stripe_account_id",
                      statusRes.data.payment_info.stripe_account_id
                    );
                    cookie.save(
                      "connect_total_amount",
                      statusRes.data.payment_info.amount
                    );
                    cookie.save(
                      "platform_fee",
                      statusRes.data.payment_info.platform_fee
                    );
                    cookie.save(
                      "payment_intent",
                      statusRes.data.result_set.payment_intent
                    );
                    var popupIdTxt = "#pay-stripe-popup";

                    $.magnificPopup.open({
                      items: {
                        src: popupIdTxt,
                      },
                      type: "inline",
                      closeOnBgClick: false,
                    });
                  } else {
                    $.magnificPopup.close();
                    this.paymentFail(language.error, language.error1001);
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                    return false;
                  }
                });
            }
            if (Paymentpop === "Yes") {
              if (this.state.paymentmodevalue == "Offline") {
                this.offlinePaymentPopup();
              } else if (payGetWayType == "OmisePayNow") {
                this.createOmiseSource();
              } else if (payGetWayType == "Ocbc") {
                this.createOcbcSource();
              } else if (payGetWayType == "Fomopay") {
                this.createFomopaySource(
                  parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2)
                );
              } else if (payGetWayType == "Stripe") {
                showLoader("xenditbtn", "class");
                this.createStripeSource(
                  parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2)
                );
              } else if (payGetWayType == "OmiseChain") {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisechainconf-popup",
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              } else if (payGetWayType == "OmisePayNowChain") {
                this.createOmiseChainSource();
              } else if (payGetWayType == "Xendit") {
                $("#three-ds-container").hide();
                $.magnificPopup.open({
                  items: {
                    src: "#pay-xendit-popup",
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              } else if (this.state.paymentmodevalue == "Gcash") {
                $("#three-ds-container").hide();
                $.magnificPopup.open({
                  items: {
                    src: "#pay-gcash-popup",
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              } else if (this.state.paymentmodevalue == "paymaya") {
                $("#three-ds-container").hide();
                $.magnificPopup.open({
                  items: {
                    src: "#pay-paymaya-popup",
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              } else if (this.state.paymentmodevalue === "Koomipay") {
                $("#three-ds-container").hide();
                this.setState({ showKoomipayCheckout: false });
                $.magnificPopup.open({
                  items: {
                    src: "#pay-koomipay-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                });
                this.setState({
                  showKoomipayCheckout: true,
                  validatedOrder: { ...submittedOrder },
                });
              } else if (this.state.paymentmodevalue == "2C2P") {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-conf-2c2p-popup",
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              } else if (this.state.paymentmodevalue == "ALLINPAY") {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-conf-allinpay-popup",
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              } else {
                var popupIdTxt =
                  payGetWayType === "Omise"
                    ? "#pay-omiseconf-popup"
                    : "#pay-conf-popup";

                $.magnificPopup.open({
                  items: {
                    src: popupIdTxt,
                  },
                  type: "inline",
                  closeOnBgClick: false,
                });
              }
            }
            if (allinpay === "Yes") {
              this.confirmAllinpay(submittedOrder);
            }
            return true;
          }
        } else if (res.data.status === "error") {
          hideLoader("xenditbtn", "class");
          hideLoader("cash_checkout_btn", "class");
          // This is used for stripe Auth Capture
          this.setState({ orderValidFail: 1, isCashButtonDisabled: false });
          clearInterval(this.pollingSubmitOrderInterval);
          if (res.data.form_error !== undefined) {
            showAlert(language.error, res.data.form_error);
          } else {
            showAlert(language.error, res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        console.log(error, "errorerror");
        this.setState({ isCashButtonDisabled: false });
        clearInterval(this.pollingSubmitOrderInterval);
        $.magnificPopup.close();
        this.paymentFail(language.error, language.error1004);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  }

  placeOrder(localOrderNo, primaryId) {
    var statusObject = {};
    statusObject = {
      app_id: appId,
      order_primary_id: primaryId,
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      customer_email: cookie.load("UserEmail"),
      payment_session: this.stripeSessionid,
    };

    axios
      .post(apiUrl + "orders/statusUpdateSession", qs.stringify(statusObject))
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ validateimage: tickImage });
          this.showSuccessPage(localOrderNo);
          return;
        } else {
          this.deleteOrderCookie();
          this.setState({
            processingText: language.checkinmyorders,
          });
          setTimeout(function () {
            window.location.href = "/myorders";
          }, 5000);
        }
      })
      .catch(
        function (error) {
          this.deleteOrderCookie();
          this.setState({
            processingText: language.checkinmyorders,
          });
          setTimeout(function () {
            window.location.href = "/myorders";
          }, 5000);
        }.bind(this)
      );
  }

  /* Omise PayNow Start */
  createOmiseSource() {
    if (
      this.state.globalSettings.omise_paynow_public !== "" &&
      typeof this.state.globalSettings.omise_paynow_public !== undefined &&
      typeof this.state.globalSettings.omise_paynow_public !== "undefined"
    ) {
      var payNowID = base64.decode(
        this.state.globalSettings.omise_paynow_public
      );
      Omise.setPublicKey(payNowID);
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var cartDetailsArr = this.state.cartDetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr,
        serviceSubChrArr,
        this.state.delivery_self_collection
      );

      showLoader("omisepaynow_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

  pollingSubmitOrder() {
    if (this.pollingSubmitOrderInterval) {
      clearInterval(this.pollingSubmitOrderInterval)
    }
    // const nextMinute = new Date()
    // nextMinute.setSeconds(0)
    // nextMinute.setMinutes(nextMinute.getMinutes() + 1)
    this.postOrder(2, "Yes");
    this.pollingSubmitOrderInterval =  setInterval(() => {
      this.postOrder(2, "Yes");
    }, 2000)
    // setTimeout(() => {
    // }, nextMinute - Date.now())
  }

  createStripeSource(payAmount) {
    axios
      .get(apiUrl + "callcenterapi/get_payment_reference_id?app_id=" + appId)
      .then((res) => {
        if (res.data.status === "ok") {
          var paymentRef = res.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });
          var grandTotalAmount = payAmount;

          var postObject = {
            app_id: appId,
            paid_amount: parseFloat(grandTotalAmount).toFixed(2),
            availability_id: cookie.load("defaultAvilablityId"),
            orderOutlet_Id: cookie.load("orderOutletId"),
            customer_id: cookie.load("UserId"),
            payment_reference: stripeReference,
            stripe_envir: this.state.globalSettings.stripe_envir,
            site_url: baseUrl,
          };

          axios
            .post(
              apiUrl + "paymentv4/stripeClientTokenGenerateEmbedded",
              qs.stringify(postObject)
            )
            .then((statusRes) => {
              if (statusRes.data.status === "ok") {
                cookie.save(
                  "paymentSession",
                  statusRes.data.result_set.session_id,
                  { path: "/" }
                );
                this.stripeSessionid = statusRes.data.result_set.session_id;
                this.setState(
                  {
                    stripeEmbeddedCheckoutSecret:
                      statusRes.data.result_set.client_secret,
                  },
                  () => {
                    this.pollingSubmitOrder();
                    $.magnificPopup.open({
                      items: { src: "#pay-stripe-embeded" },
                      type: "inline",
                      closeOnBgClick: false,
                      enableEscapeKey: false,
                      callbacks: {
                        close: () => {
                          hideLoader("xenditbtn", "class");
                          this.setState({ stripeEmbeddedCheckoutSecret: null });
                          clearInterval(this.pollingSubmitOrderInterval);
                        },
                      },
                    });
                  }
                );
              } else {
                hideLoader("xenditbtn", "class");
                $.magnificPopup.close();
                this.paymentFail(language.error, language.error1001);
                $.magnificPopup.open({
                  items: {
                    src: ".warining_popup",
                  },
                  type: "inline",
                });
                return false;
              }
            });
        }
      });
  }

  createFomopaySource(payAmount) {
    if (
      this.state.globalSettings.client_fomopay_enable !== "" &&
      typeof this.state.globalSettings.client_fomopay_mid_key !== undefined &&
      typeof this.state.globalSettings.client_fomopay_psk_key !== "undefined"
    ) {
      var currentThis = this;

      var fomopayPostObject = {
        app_id: appId,
        mode: "Hosted",
        customer_id: cookie.load("UserId"),
        order_no: cookie.load("fomopayReferenceId"),
        sub_mid: this.state.globalSettings.client_fomopay_mid_key,
        psk_key: this.state.globalSettings.client_fomopay_psk_key,
        subject: "",
        description: "",
        paid_amount: payAmount,
        currency_code: "SGD",
        site_url: window.location.origin,
      };

      axios
        .post(apiUrl + "fomopay/order_submit", qs.stringify(fomopayPostObject))
        .then((res) => {
          if (res.data.status === "ok") {
            window.sessionStorage.setItem(
              "fomopay_order_id",
              res.data.result_set.id
            );
            window.location.href = res.data.result_set.url;
          } else {
            $.magnificPopup.close();
            this.paymentFail(language.error, res.data.message);
          }
        });
    }
  }

  createOcbcSource() {
    if (
      this.state.globalSettings.client_ocbc_public_key !== "" &&
      typeof this.state.globalSettings.client_ocbc_public_key !== undefined &&
      typeof this.state.globalSettings.client_ocbc_public_key !== "undefined"
    ) {
      Omise.setPublicKey(this.state.globalSettings.client_ocbc_public_key);
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var cartDetailsArr = this.state.cartDetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr,
        serviceSubChrArr,
        this.state.delivery_self_collection
      );

      showLoader("ocbcpaynow_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "mobile_banking_ocbc_pao",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
          platform_type: "ANDROID",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.ocbcPayAnyoneCapture(response.id, response.amount);
          } else {
            hideLoader("ocbcpaynow_btn", "Idtext");
          }
        }
      );
    }
  }

  ocbcPayAnyoneCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: appId,
        source_id: sourceID,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        amount: amount,
        referenceId: cookie.load("ocbcreferenceID"),
        order_request: this.state.orderRequest,
      };

      axios
        .post(
          apiUrl + "paymentv1/ocbcPayAnyoneCapture",
          qs.stringify(omisePostObject)
        )
        .then((res) => {
          hideLoader("ocbcpaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            cookie.save("ocbcCaptureId", res.data.result_set.captureId, {
              path: "/",
            });
            cookie.save("ocbcAmount", amount / 100, { path: "/" });
            this.setState(
              {
                ocbcResponse: res.data.result_set,
                ocbcAmount: amount / 100,
                ocbcTime: 600,
                ocbclink:
                  res.data.result_set.omise_response["\u0000*\u0000_values"]
                    .authorize_uri,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-ocbcconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : language.error1001;
            this.paymentFail(language.error, erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  paynowCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: appId,
        source_id: sourceID,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        amount: amount,
      };
      axios
        .post(apiUrl + "paymentv1/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          hideLoader("omisepaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowPamentStatus(startTimePay);
                    }
                    currentThis.omisePaynowTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(language.error, language.error1002);
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : language.error1001;
            this.paymentFail(language.error, erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
      statusObj = {
        app_id: appId,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(apiUrl + "paymentv1/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(11, "", captureID, "OmisePayNow");
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : language.error1001;
            this.paymentFail(language.error, erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }
  /* Omise PayNow End */

  /* Omise PayNow Chain Start */
  createOmiseChainSource() {
    if (
      this.state.globalSettings.client_enable_omise_chain_paynow !== "" &&
      this.state.globalSettings.client_enable_omise_chain_paynow == "1" &&
      typeof this.state.globalSettings.client_enable_omise_chain_paynow !==
        undefined &&
      typeof this.state.globalSettings.client_enable_omise_chain_paynow !==
        "undefined"
    ) {
      var payNowID = "";

      if (this.state.globalSettings.client_omise_chain_mode === "0") {
        payNowID = this.state.globalSettings.omise_main_acc.omise_public_test;
      } else {
        payNowID = this.state.globalSettings.omise_main_acc.omise_public_live;
      }
      Omise.setPublicKey(payNowID);
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var cartDetailsArr = this.state.cartDetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        this.props.cartItems,
        promoTionArr,
        serviceSubChrArr,
        this.state.delivery_self_collection
      );

      showLoader("omisepaynowchain_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowChainCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

  paynowChainCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: appId,
        source_id: sourceID,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        amount: amount,
      };
      axios
        .post(apiUrl + "omisepay/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          hideLoader("omisepaynowchain_btn", "Idtext");
          if (res.data.status === "ok") {
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowChainTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowChainPamentStatus(
                        startTimePay
                      );
                    }
                    currentThis.omisePaynowChainTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(language.error, language.error1002);
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : language.error1001;
            this.paymentFail(language.error, erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowChainTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowChainPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
      statusObj = {
        app_id: appId,
        customer_id: cookie.load("UserId"),
        outlet_id: cookie.load("orderOutletId"),
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(apiUrl + "omisepay/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(11, "", captureID, "OmisePayNowChain");
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : language.error1001;
            this.paymentFail(language.error, erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }
  /* Omise PayNow End */

  pageReload = () => {
    setTimeout(function () {
      //  window.location.reload();
    }, 15000);
  };

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="button btn-sm btn_yellow popup-modal-dismiss">' +
        language.ok +
        "</a></div></div></div></div></div></div>"
    );
  }

  /* Xendit Capture Payment */
  captureXenditAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    var orderOutlet_Id = cookie.load("orderOutletId");
    cabtureObjects = {
      customer_id: cookie.load("UserId"),
      app_id: appId,
      payment_ref_id: captureID,
      reference_id: cookie.load("xenditewalletReferenceId"),
      amount: this.state.xendit_paid_amount,
      order_id: orderPrimaryId,
      order_request: this.state.orderRequest,
    };
    axios
      .post(
        apiUrl + "xenditpayment/xenditCapture",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo, orderPrimaryId);
            }.bind(this)
          );
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            res.data.message != ""
              ? res.data.message
              : language.ordernotsuccess;
          showAlert(language.error, omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        var magnfPopup = $.magnificPopup.instance;
        var omsMsgTxt = language.somthingwrong;
        showAlert(language.error, omsMsgTxt, magnfPopup);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      });
  }

  /* Capture amount */
  captureAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo;
    cabtureObjects = {
      payment_reference: stripeReference,
      stripe_envir: this.state.globalSettings.stripe_envir,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
    };
    axios
      .post(apiUrl + "payment/captureAmount", qs.stringify(cabtureObjects))
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.showSuccessPage(localOrderNo, orderPrimaryId);
        } else if (captureRes.data.status === "error") {
          showAlert(language.error, language.ordernotsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          showAlert(language.error, language.ordernotsuccess);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {});
  }

  // Koomipay capture amount
  captureKoomipayAmount(captureID, orderPrimaryId, localOrderNo) {
    const captureObject = {
      payment_id: captureID,
      order_id: orderPrimaryId,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      outlet_id: this.state.seletedOutletId,
    };
    axios
      .post(apiUrl + "koomipay/capture", qs.stringify(captureObject))
      .then((res) => {
        // need handle fail to capture later
        $.magnificPopup.close();
        this.showSuccessPage(localOrderNo, orderPrimaryId);
      })
      .catch((err) => {
        console.log(err);
        $.magnificPopup.close();
        this.showSuccessPage(localOrderNo, orderPrimaryId);
      });
  }

  /* Omise Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: cookie.load("orderOutletId"),
    };
    axios
      .post(
        apiUrl + "paymentv1/captureAmountOmise",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo, orderPrimaryId);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : language.ordernotsuccess;
          showAlert(language.error, omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          /*var magnfPopup = $.magnificPopup.instance;
        showAlert('Error', 'Your order was not successful.', magnfPopup);
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });*/

          /*Get Status of Charge ID*/
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        }
      })
      .catch((error) => {
        this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo);
      });
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/retrieve_charge_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo, orderPrimaryId);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId);
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : language.ordernotsuccess;
          showAlert(language.error, msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  /* Omise Chain Capture Payment */
  captureOmiseChainAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {};
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: cookie.load("orderOutletId"),
      chain_acccount: "Yes",
    };
    axios
      .post(
        apiUrl + "omisepay/captureAmountOmise",
        qs.stringify(cabtureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });

          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo, orderPrimaryId);
            }.bind(this)
          );
        } else if (captureRes.data.status === "pending") {
          this.retrieve_chain_charge_details(
            captureID,
            localOrderNo,
            orderPrimaryId
          );
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : language.ordernotsuccess;
          showAlert(language.error, omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          /*Get Status of Charge ID*/
          this.retrieve_chain_charge_details(
            captureID,
            localOrderNo,
            orderPrimaryId
          );
        }
      })
      .catch((error) => {
        this.captureOmiseChainAmount(captureID, orderPrimaryId, localOrderNo);
      });
  }
  retrieve_chain_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "omisepay/retrieve_charge_details",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          this.setState(
            { stop_authentication: "yes" },
            function () {
              this.showSuccessPage(localOrderNo, orderPrimaryId);
            }.bind(this)
          );
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          this.retrieve_chain_charge_details(
            captureID,
            localOrderNo,
            orderPrimaryId
          );
        } else {
          var magnfPopup = $.magnificPopup.instance;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : language.ordernotsuccess;
          showAlert(language.error, msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  sentnoviteepos(orderPrimaryId) {
    var postObject = {
      app_id: appId,
      order_primary_id: orderPrimaryId,
      customer_id: cookie.load("UserId"),
    };

    axios
      .post(apiUrl + "v1/external/push_to_novitee", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
        }
      });
  }

  sentSGdatapos(orderPrimaryId) {
    var urlShringTxt =
      apiUrl +
      "sgdata/sendPOS?app_id=" +
      appId +
      "&order_primary_id=" +
      orderPrimaryId;
    axios.get(urlShringTxt).then((res) => {
      console.log(res.data);
    });
  }

  /* sucess page */
  showSuccessPage(localOrderNo, orderPrimaryId) {
    //this.props.destroyCartDetail();
    this.sentnoviteepos(orderPrimaryId);
    this.sentSGdatapos(orderPrimaryId);
    var defaultAvilablityId = cookie.load("defaultAvilablityId");
    this.deleteOrderCookie();
    /*this.sendMailFun(orderPrimaryId);*/
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    var classThis = this;
    setTimeout(function () {
      $.magnificPopup.close();
      if (
        classThis.state.globalSettings.client_enable_auto_push_delivery !==
          "" &&
        typeof classThis.state.globalSettings
          .client_enable_auto_push_delivery !== undefined &&
        typeof classThis.state.globalSettings
          .client_enable_auto_push_delivery !== "undefined" &&
        defaultAvilablityId === deliveryId
      ) {
        if (
          classThis.state.globalSettings.client_enable_auto_push_delivery ===
          "1"
        ) {
          history.push("/findingdriver/" + localOrderNo);
          return false;
        }
      }
      cookie.remove("orderOutletId", { path: "/" });
      history.push("/thankyou/" + localOrderNo);
    }, 100);
  }

  sendMailFun(orderPrimaryId) {
    if (orderPrimaryId != "") {
      var urlShringTxt =
        apiUrl +
        "ordersv1/order_email?app_id=" +
        appId +
        "&order_primary_id=" +
        orderPrimaryId +
        "&mode=Cron";
      axios.get(urlShringTxt).then((res) => {
        console.log(res.data);
      });
    }
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    //cookie.remove("orderOutletId", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("checkout_order_date", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("chopchoptoken", { path: "/" });
    window.sessionStorage.removeItem("subscriptionavailability");
    window.sessionStorage.removeItem("postObject");
  }

  choosePayment(paymentMode) {
    if (paymentMode === "Stripe" && this.state.isStripeButtonDisabled) {
      return false;
    }

    if (paymentMode === "Stripe") {
      this.setState({ isStripeButtonDisabled: true });
    } else {
      this.setState({ isStripeButtonDisabled: false });
    }

    if (paymentMode === "Cash") {
      this.setState({ paymentmodevalue: "Cash" });
    } else if (paymentMode === "Offline") {
      this.setState({ paymentmodevalue: "Offline" });
      this.postOrder(2, "Yes", "", "", "Yes");
    } else if (paymentMode === "Stripe") {
      this.setState({ paymentmodevalue: "Stripe" });
      this.postOrder(2, "Yes", "", paymentMode, "");
    } else if (paymentMode === "Koomipay") {
      this.setState({ paymentmodevalue: "Koomipay" });
    } else if (paymentMode === "Omise") {
      this.setState({ paymentmodevalue: "Omise" });
    } else if (paymentMode === "OmisePayNow") {
      this.setState({ paymentmodevalue: "OmisePayNow" });
    } else if (paymentMode === "OmiseChain") {
      this.setState({ paymentmodevalue: "OmiseChain" });
    } else if (paymentMode === "OmisePayNowChain") {
      this.setState({ paymentmodevalue: "OmisePayNowChain" });
    } else if (paymentMode === "Xendit") {
      this.setState({ paymentmodevalue: "Xendit" });
      this.postOrder(2, "Yes", "", "", "");
    } else if (paymentMode === "Ocbc") {
      this.setState({ paymentmodevalue: "Ocbc" });
    } else if (paymentMode === "StripeConnect") {
      this.setState({ clientSecret: "" });
      this.setState({ paymentmodevalue: "StripeConnect" });
      this.postOrder(3, "Yes", "", "StripeConnect", "");
    } else if (paymentMode === "Gcash") {
      this.setState({ paymentmodevalue: "Gcash" });
      this.postOrder(2, "Yes", "", "", "");
    } else if (paymentMode === "paymaya") {
      this.setState({ paymentmodevalue: "paymaya" });
      this.postOrder(2, "Yes", "", "", "");
    } else if (paymentMode === "Fomopay") {
      this.setState({ paymentmodevalue: "Fomopay" });
      this.postOrder(2, "Yes", "", paymentMode, "");
    } else if (paymentMode === "2C2P") {
      this.setState({ paymentmodevalue: "2C2P" });
      this.postOrder(2, "Yes", "", paymentMode, "");
    } else if (paymentMode === "ALLINPAY") {
      this.setState({ paymentmodevalue: "ALLINPAY" });
      this.postOrder(2, "Yes", "", paymentMode, "");
    } else {
      this.setState({ paymentmodevalue: "promotion" });
    }
    if (
      paymentMode !== "StripeConnect" &&
      paymentMode !== "Offline" &&
      paymentMode !== "Gcash" &&
      paymentMode !== "paymaya" &&
      paymentMode !== "Xendit" &&
      paymentMode !== "Fomopay" &&
      paymentMode !== "Stripe"
    ) {
      this.postOrder(2, "Yes");
    }
  }

  /* stripe description  */
  stripeDescription() {
    //userName
    if (
      typeof cookie.load("UserLname") !== "undefined" &&
      cookie.load("UserLname") !== ""
    ) {
      return cookie.load("UserFname") + " " + cookie.load("UserLname");
    } else {
      return cookie.load("UserLname");
    }
  }

  /* post stripe account */
  onToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    });

    axios
      .get(apiUrl + "callcenterapi/get_payment_reference_id?app_id=" + appId)
      .then((res) => {
        if (res.data.status === "ok") {
          var paymentRef = res.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });
          var promoTionArr = Array();
          promoTionArr["promotionApplied"] = this.state.promotion_applied;
          promoTionArr["promotionAmount"] = this.state.promotion_amount;
          promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
          var serviceSubChrArr = this.state.servicesurchargeval;
          var calculatedAmount = getCalculatedAmount(
            this.state.globalSettings,
            this.props.zonedetails,
            this.state.cartDetails,
            promoTionArr,
            serviceSubChrArr,
            this.state.delivery_self_collection
          );

          var payAmount = parseFloat(
            calculatedAmount["grandTotalAmount"]
          ).toFixed(2);

          /*  load process html */
          var postObject = {};
          postObject = {
            app_id: appId,
            token: token.id,
            stripe_envir: this.state.globalSettings.stripe_envir,
            stripe_key: this.state.globalSettings.stripe_public_key,
            customer_id: cookie.load("UserId"),
            paid_amount: payAmount,
            outlet_name:
              typeof cookie.load("orderOutletName") !== "undefined"
                ? cookie.load("orderOutletName") + " - " + paymentRef
                : "",
            payment_reference: stripeReference,
          };

          this.postOrder(2, "Yes"); // To validate the order

          if (this.state.orderValidFail === 0) {
            axios
              .post(apiUrl + "payment/stripeTokenPay", qs.stringify(postObject))
              .then((res) => {
                if (res.data.status === "ok") {
                  var captureID = res.data.result_set.payment_reference_1;
                  this.setState({ validateimage: tickImage });
                  this.postOrder(3, "", captureID, "Stripe");
                } else if (res.data.status === "error") {
                  $.magnificPopup.close();
                  this.paymentFail(language.error, language.error1001);
                  $.magnificPopup.open({
                    items: {
                      src: ".warining_popup",
                    },
                    type: "inline",
                  });
                  return false;
                }
              })
              .catch((error) => {
                $.magnificPopup.close();
                this.paymentFail(language.error, language.error1002);
                $.magnificPopup.open({
                  items: {
                    src: ".warining_popup",
                  },
                  type: "inline",
                });
                return false;
              });
          } else {
            $.magnificPopup.close();
            this.paymentFail(language.error, language.error1003);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });

            return false;
          }
        }
      });
  };

  orderPayValidaion(payGetWayType, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }
    showLoader("xenditbtn", "class");
    if (payGetWayType == "Stripe") {
      this.postOrder(2, "Yes", "", payGetWayType, "Yes");
    } else {
      this.postOrder(2, "Yes", "", payGetWayType, "Yes");
    }
  }

  changeAddrrFun(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-popup",
      },
      type: "inline",
    });
    return false;
  }

  CheckTarmsContnChk() {
    var tarmsContn = this.state.chk_tarms_contn;
    var chkBox = false;
    if (tarmsContn === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }

  changeTarmsContnChk() {
    var tarmsContn = this.state.chk_tarms_contn;
    if (tarmsContn === "Yes") {
      this.setState({ chk_tarms_contn: "No" });
    } else {
      this.setState({ chk_tarms_contn: "Yes" });
    }
    $(".tarms_chkbox_div").removeClass("err_tarms_chk");
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
      this.getServiceChargeAmt(OrdDateTimeArr);
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
      this.getServiceChargeAmt(OrdDateTimeArr);
    }
  };

  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-div no-stock-product " + proSlug;
    if (Stock > 0) {
      returnText = "products-single-div " + proSlug;
    }

    return returnText;
  }

  /* Addon product Listing */
  addonProductListing() {
    var addonProductlst = this.props.addonproductlist;
    var clientProductStock = "";
    if (Object.keys(this.state.globalSettings).length > 0) {
      clientProductStock = this.state.globalSettings.client_product_stock;
    }
    if (Object.keys(addonProductlst).length > 0) {
      if (Object.keys(addonProductlst[0].subcategorie).length > 0) {
        var slugType = "category";
        var slugValue = addonProductlst[0].subcategorie[0].pro_cate_slug;
        var addonProlstOnly = addonProductlst[0].subcategorie[0].products;
        var addonCommonImg = this.props.addonproductcommon.product_image_source;
        var tagImageSource = this.props.addonproductcommon.tag_image_source;
        const Phtml = addonProlstOnly.map((product, index) => (
          <div
            className={this.chkProStockCls(
              product.product_slug,
              product.product_stock,
              clientProductStock
            )}
            id={"proIndex-" + product.product_primary_id}
            key={index}
          >
            <div className="products-image-div">
              {product.product_thumbnail !== "" ? (
                <img
                  src={addonCommonImg + "/" + product.product_thumbnail}
                  alt={
                    product.product_alias !== ""
                      ? stripslashes(product.product_alias)
                      : stripslashes(product.product_name)
                  }
                />
              ) : (
                <img
                  src={noimage}
                  alt={
                    product.product_alias !== ""
                      ? stripslashes(product.product_alias)
                      : stripslashes(product.product_name)
                  }
                />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>
                    {product.product_alias !== ""
                      ? stripslashes(product.product_alias)
                      : stripslashes(product.product_name)}
                  </h3>
                </div>
                {Object.keys(product.product_tag).length > 0 ? (
                  <div className="product-tag-list">
                    <ul>
                      {product.product_tag.map((producttag, index1) => {
                        if (producttag.pro_tag_image !== "") {
                          return (
                            <li key={index1}>
                              <img
                                src={tagImageSource + producttag.pro_tag_image}
                                alt="Tag"
                              />
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="product-price">
                <h3>{showPriceValue(product.product_price)}</h3>
              </div>

              <div className="product-short-description"></div>

              <div className="products-ordernow-action">
                {product.product_stock > 0 || product.product_stock === null ? (
                  product.product_type === "1" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        product,
                        "initial"
                      )}
                    >
                      {language.addtocart}
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, product)}
                      title="Product Details"
                      id={"comboPro-" + product.product_slug}
                      className="button order_nowdiv compo_product_lk"
                    >
                      {language.addtocart}
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    {language.soldout}
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                  <button
                    className="button btn_black"
                    onClick={this.addToCartSimple.bind(this, product, "done")}
                  >
                    {language.done}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ));

        return Phtml;
      }
    } else {
      return false;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);

    if (actionFlg === "decr" && proqtyInput == 1) {
      $("#proIndex-" + indxFlg)
        .find(".smiple_product_lk")
        .show();
      $("#proIndex-" + indxFlg)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + indxFlg).removeClass("add-cart");
    }
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      $("#proIndex-" + IndexFlg).addClass("add-cart");
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      // var availabilityName =
      //   availabilityId === deliveryId ? "Delivery" : "Pickup";

      if (availabilityId === deliveryId) {
        var availabilityName = "Delivery";
      } else if (availabilityId === dineinId) {
        var availabilityName = "Dine In";
      } else {
        var availabilityName = "Pickup";
      }

      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var userAccountType =
        typeof cookie.load("userAccountType") === "undefined"
          ? ""
          : cookie.load("userAccountType");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        login_type: userAccountType,
        reference_new_id: getReferenceID(),
      };

      axios
        .post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject))
        .then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + IndexFlg).removeClass("add-cart");
          if (res.data.status === "ok") {
            this.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert("success", language.addtocartsuccess);
            /*showCartLst();*/
            this.handleShowAlertFun(
              language.success,
              language.addtocartsuccess
            );
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : language.addtocartfaild;
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun(language.error, errMsgtxt);
          }

          return false;
        });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    this.setState({ cust_birthdate_update: "yes", cust_birthdate: datevalue });
  }

  onGenderChange(event) {
    this.setState({ cust_gender: event.target.value });
  }

  /* Xendit Payment Gateway Start */
  handleXenditChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "xenditCardNumber") {
      var cardnumberLenght = event.target.value.length;
      if (cardnumberLenght <= 16) {
        this.setState({ [item]: event.target.value });
        this.cardValidation(event.target.value);
      }
    } else if (item == "xendit_expiration_month") {
      if (
        event.target.value === "" ||
        (re.test(event.target.value) && event.target.value.length <= 2)
      ) {
        this.setState({ [item]: event.target.value });
      }
    } else if (item == "xendit_expiration_year") {
      if (
        event.target.value === "" ||
        (re.test(event.target.value) && event.target.value.length <= 4)
      ) {
        this.setState({ [item]: event.target.value });
      }
    } else if (item == "xendit_security_code") {
      if (
        event.target.value === "" ||
        (re.test(event.target.value) && event.target.value.length <= 3)
      ) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }
  };

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/;
    if (item == "cardNumber" || item == "chain_cardNumber") {
      if (event.target.value === "" || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value);
        var cardnumberLenght = cardnumber.length;
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber });
          this.cardValidation(cardnumber);
        }
      }
    } else if (
      item == "expiration_month" ||
      item == "expiration_year" ||
      item == "security_code" ||
      item == "chain_expiration_month" ||
      item == "chain_expiration_year" ||
      item == "chain_security_code"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value });
      }
    } else {
      this.setState({ [item]: event.target.value });
    }

    this.setState({
      omisenameerror: "",
      omisecardrror: "",
      omisemontherror: "",
      omiseyearerror: "",
      omisecodeerror: "",

      omise_chain_nameerror: "",
      omise_chain_cardrror: "",
      omise_chain_montherror: "",
      omise_chain_yearerror: "",
      omise_chain_codeerror: "",
    });
  };

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  cardValidation(carnumber) {
    var imagename = "";
    if (carnumber != "") {
      var single = carnumber.substring(0, 1);
      var double = carnumber.substring(0, 2);

      if (single == 4) {
        imagename = "visa";
      } else if (double == 34 || double == 37) {
        imagename = "american";
      } else if (double >= 51 && double <= 55) {
        imagename = "master";
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover";
      }
    }

    this.setState({ cardImage: imagename });
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = "",
      omisecardrror = "",
      omisemontherror = "",
      omiseyearerror = "",
      omisecodeerror = "";
    if (this.state.holdername == "") {
      errorFlg++;
      omisenameerror = "omise-form-error";
    }
    if (this.state.cardNumber == "") {
      errorFlg++;
      omisecardrror = "omise-form-error";
    }
    if (this.state.expiration_month == "") {
      errorFlg++;
      omisemontherror = "omise-form-error";
    }
    if (this.state.expiration_year == "") {
      errorFlg++;
      omiseyearerror = "omise-form-error";
    }
    if (this.state.security_code == "") {
      errorFlg++;
      omisecodeerror = "omise-form-error";
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror,
    });

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      Omise.setPublicKey(this.state.globalSettings.omise_public_key);
      var current = this;
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function (statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : "Invalid card details.";
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            /*current.onProcessOmiseToken(response);*/
            current.setState(
              {
                omise_tokken_response: response,
                omise_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== "" ? response.message : language.omiseerror;
          current.showOmiselert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  getXenditToken() {
    var errorFlg = 0,
      xenditnameerror = "",
      xenditcardrror = "",
      xenditmontherror = "",
      xendityearerror = "",
      xenditcodeerror = "";
    if (this.state.xenditholdername == "") {
      errorFlg++;
      xenditnameerror = "omise-form-error";
    }
    if (this.state.xenditCardNumber == "") {
      errorFlg++;
      xenditcardrror = "omise-form-error";
    }
    if (this.state.xendit_expiration_month == "") {
      errorFlg++;
      xenditmontherror = "omise-form-error";
    }
    if (this.state.xendit_expiration_year == "") {
      errorFlg++;
      xendityearerror = "omise-form-error";
    }
    if (
      this.state.xendit_expiration_year != "" &&
      this.state.xendit_expiration_year.length !== 4
    ) {
      errorFlg++;
      xendityearerror = "omise-form-error";
    }
    if (this.state.xendit_security_code == "") {
      errorFlg++;
      xenditcodeerror = "omise-form-error";
    }
    this.setState({
      xenditnameerror: xenditnameerror,
      xenditcardrror: xenditcardrror,
      xenditmontherror: xenditmontherror,
      xendityearerror: xendityearerror,
      xenditcodeerror: xenditcodeerror,
    });

    if (errorFlg > 0) {
      return false;
    } else {
      var xenditpublickey = this.state.globalSettings.client_xendit_public_key;
      Xendit.setPublishableKey(xenditpublickey);
      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        this.state.cartDetails,
        promoTionArr,
        serviceSubChrArr,
        this.state.delivery_self_collection
      );
      var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(
        2
      );
      payAmount = payAmount * 100;
      var current = this;
      var cardObj = {
        amount: payAmount,
        card_number: this.state.xenditCardNumber,
        card_exp_month: this.state.xendit_expiration_month,
        card_exp_year: this.state.xendit_expiration_year,
        card_cvn: this.state.xendit_security_code,
        is_multiple_use: false,
        should_authenticate: true,
      };
      showLoader("xendit-pay-btn", "class");
      Xendit.card.createToken(cardObj, function (err, creditCardToken) {
        if (creditCardToken.status === "VERIFIED") {
          // Get the token ID:
          var token = creditCardToken.id;
          $(".xendit_pay_body").show();
          $("#three-ds-container").hide();

          current.setState(
            {
              xendit_tokken_response: creditCardToken,
              xendit_tokken_id: token,
              xendit_authentication_id: creditCardToken.authentication_id,
            },
            () => {
              hideLoader("xendit-pay-btn", "class");
              current.onProcessXenditToken(creditCardToken);
            }
          );
        } else if (creditCardToken.status === "IN_REVIEW") {
          window.open(
            creditCardToken.payer_authentication_url,
            "sample-inline-frame"
          );
          $(".xendit_pay_body").hide();
          $("#three-ds-container").show();
          $("#three-ds-container").find("iframe").show();
          $("#three-ds-container").find(".sample-inline-frame").show();
        }
      });
    }
  }

  removePopup() {
    $.magnificPopup.close();
  }

  /*create customer for Gcash */
  getXenditEwallet(channel_code) {
    showLoader("ewallet-btn", "class");
    var xenditpublickey = this.state.globalSettings.client_xendit_public_key;
    Xendit.setPublishableKey(xenditpublickey);
    var currenttimestamp = Math.floor(Date.now() / 1000);
    var current = this;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    if (
      typeof cookie.load("orderOutletName") != "undefined" &&
      cookie.load("orderOutletName").split("-").length > 1
    ) {
      var outlet_name_ref = cookie.load("orderOutletName").split("-")[1].trim();
    } else {
      var outlet_name_ref = cookie.load("orderOutletName");
    }
    var ewalletObj = {
      app_id: appId,
      reference_id:
        typeof cookie.load("orderOutletName") != "undefined"
          ? outlet_name_ref +
            "_" +
            channel_code +
            "_" +
            cookie.load("xenditewalletReferenceId")
          : channel_code + "_" + cookie.load("xenditewalletReferenceId"),
      redirect_reference_id: cookie.load("xenditewalletReferenceId"),
      customer_id: cookie.load("UserId"),
      outlet_id: cookie.load("orderOutletId"),
      amount: payAmount,
      channel_code: channel_code,
      order_request: this.state.orderRequest,
    };
    axios
      .post(apiUrl + "xenditpayment/xenditEwallet", qs.stringify(ewalletObj))
      .then((res) => {
        if (res.data.status === "ok") {
          var ewalletID = res.data.result_set.id;
          var ewalletRedirectUrl =
            res.data.result_set.actions.desktop_web_checkout_url;
          window.location.href = ewalletRedirectUrl;
        } else if (res.data.status === "error") {
          $.magnificPopup.close();
          hideLoader("ewallet-btn", "class");
          var errerTxt =
            res.data.message !== "" ? res.data.message : language.error1001;
          this.paymentFail(language.error, errerTxt);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        hideLoader("ewallet-btn", "class");
        var errerTxt = language.somthingwrong;
        this.paymentFail(language.error, errerTxt);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
      });
  }

  /* Authentication For Payment */
  onProcessXenditToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);

    var orderOutlet_Id = cookie.load("orderOutletId");
    if (
      typeof cookie.load("orderOutletName") != "undefined" &&
      cookie.load("orderOutletName").split("-").length > 1
    ) {
      var outlet_name_ref = cookie.load("orderOutletName").split("-")[1].trim();
    } else {
      var outlet_name_ref = cookie.load("orderOutletName");
    }
    var postObject = {};
    postObject = {
      app_id: appId,
      token: token.id,
      authentication_id: token.authentication_id,
      customer_id: cookie.load("UserId"),
      reference_id:
        typeof cookie.load("orderOutletName") != "undefined"
          ? outlet_name_ref + "_card_" + cookie.load("xenditewalletReferenceId")
          : "card_" + cookie.load("xenditewalletReferenceId"),
      paid_amount: payAmount,
      cvntxt: this.state.xendit_security_code,
      outlet_id: orderOutlet_Id,
    };
    axios
      .post(apiUrl + "xenditpayment/xenditAuth", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.result_set.id;
          this.setState(
            { validateimage: tickImage, xendit_paid_amount: payAmount },
            function () {
              this.postOrder(3, "", captureID, "Xendit");
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          $.magnificPopup.close();
          var errerTxt =
            res.data.message !== "" ? res.data.message : language.error1001;
          this.paymentFail(language.error, errerTxt);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        } else {
          $.magnificPopup.close();
          var errerTxt = language.somthingwrong;
          this.paymentFail(language.error, errerTxt);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        var errerTxt = language.somthingwrong;
        this.paymentFail(language.error, errerTxt);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
      });
  };

  showOmiselert(msgArr) {
    var dataTimeoutId = $(".omise-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $(".omise-error-info-div").hide();
    $(".omise-error-msg").html(msgArr["message"]);
    $("#omise-error-info-div").fadeIn();
    var timeoutid = setTimeout(function () {
      $(".omise-error-info-div").hide();
    }, 6000);
    $(".omise-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );

    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    var requestType =
      "ninjaent-" + cookie.load("orderOutletId") + "-" + cookie.load("UserId");

    axios
      .get(apiUrl + "paymentv1/getPaymentReferenceId?app_id=" + appId)
      .then((topRes) => {
        if (topRes.data.status === "ok") {
          var paymentRef = topRes.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });
          var redirectUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/checkout?callback_omise=1";

          /*  load process html */
          var postObject = {};
          postObject = {
            app_id: appId,
            token: token.id,
            customer_id: cookie.load("UserId"),
            outlet_id: cookie.load("orderOutletId"),
            paid_amount: payAmount,
            request_type: requestType,
            outlet_name:
              typeof cookie.load("orderOutletName") === "undefined"
                ? cookie.load("orderOutletName")
                : "",
            redirect_url: redirectUrl,
          };

          this.postOrder(2, "Yes"); // To validate the order

          if (this.state.orderValidFail === 0) {
            axios
              .post(apiUrl + "paymentv1/authOmise", qs.stringify(postObject))
              .then((res) => {
                if (res.data.status === "ok") {
                  if (res.data.result_set.authorize_uri != "") {
                    cookie.save(
                      "omise_capture_id",
                      res.data.result_set.payment_reference_1
                    );
                    window.location.href = res.data.result_set.authorize_uri;
                  } else {
                    var captureID = res.data.result_set.payment_reference_1;
                    var omiseLogId = res.data.result_set.log_id;
                    this.setState(
                      { validateimage: tickImage, omise_log_id: omiseLogId },
                      function () {
                        this.postOrder(3, "", captureID, "Omise");
                      }.bind(this)
                    );
                  }
                } else if (res.data.status === "error") {
                  if (res.data.message == "token was already used") {
                    // this.omise_search_history();
                  } else {
                    $.magnificPopup.close();
                    var errerTxt =
                      res.data.message !== ""
                        ? res.data.message
                        : language.error1001;
                    this.paymentFail(language.error, errerTxt);
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                    return false;
                  }
                } else {
                  var currents = this;
                  setTimeout(
                    function () {
                      // currents.omise_search_history();
                    }.bind(this),
                    2000
                  );
                }
              })
              .catch((error) => {
                /*$.magnificPopup.close();
        this.paymentFail('Error', "Error code: 1002 Oops! Unable to connect to server. Please try again.");
                $.magnificPopup.open({
                    items: {
                        src: '.warining_popup'
                    },
                    type: 'inline'
                });
                return false;*/

                var currentcatch = this;
                setTimeout(
                  function () {
                    // currentcatch.omise_search_history();
                  }.bind(this),
                  2000
                );
              });
          } else {
            $.magnificPopup.close();
            this.paymentFail(language.error, language.error1003);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });

            return false;
          }
        } else {
          $.magnificPopup.close();
          this.paymentFail(language.error, language.error1003);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });

          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(language.error, language.error1002);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  };

  omeiseSecureCallback() {
    var redirectUrl =
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/checkout?callback_omise=1";
    var currentThis = this;
    var StartTime = this.state.omise3dsTimecallbackTime;
    if (
      this.props.location.search != "undefined" &&
      this.props.location.search != undefined &&
      this.props.location.search === "?callback_omise=1" &&
      cookie.load("omise_capture_id") != undefined &&
      cookie.load("omise_capture_id") != ""
    ) {
      var captureID = cookie.load("omise_capture_id");
      currentThis.retrieve_three_d_charges(captureID);
    }
  }

  retrieve_three_d_charges = (captureID) => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      charge_id: captureID,
      app_id: appId,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(
        apiUrl + "paymentv1/retrieve_three_d_charges",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (
          res.data.status === "ok" &&
          res.data.payment_status === "successful"
        ) {
          $.magnificPopup.open({
            items: {
              src: ".processing",
            },
            type: "inline",
            showCloseBtn: false,
            midClick: true,
            closeOnBgClick: false,
          });
          var captureID = cookie.load("omise_capture_id");
          this.postOrder(3, "", captureID, "Omise");
          cookie.save("omise_capture_id", "");
        } else if (
          res.data.status === "ok" &&
          res.data.payment_status === "pending"
        ) {
          $.magnificPopup.open({
            items: {
              src: ".processing",
            },
            type: "inline",
            showCloseBtn: false,
            midClick: true,
            closeOnBgClick: false,
          });
          var captureID = cookie.load("omise_capture_id");
          this.postOrder(3, "", captureID, "Omise");
          cookie.save("omise_capture_id", "");
        } else {
          clearInterval(startTimePay);
          var redirectUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/checkout";
          window.location.href = redirectUrl;
          var msgTxt =
            res.data.message != ""
              ? res.data.message
              : language.ordernotsuccess;
          var magnfPopup = $.magnificPopup.instance;
          showAlert(language.error, msgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  };

  omise_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(apiUrl + "paymentv1/omise_search_details", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.postOrder(3, "", captureID, "Omise");
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response);
        }
      });
  };

  /* Omise Chain Payment Start */
  getOmiseChainToken() {
    var errorFlg = 0,
      omise_chain_nameerror = "",
      omise_chain_cardrror = "",
      omise_chain_montherror = "",
      omise_chain_yearerror = "",
      omise_chain_codeerror = "";
    if (this.state.chain_holdername == "") {
      errorFlg++;
      omise_chain_nameerror = "omise-form-error";
    }
    if (this.state.chain_cardNumber == "") {
      errorFlg++;
      omise_chain_cardrror = "omise-form-error";
    }
    if (this.state.chain_expiration_month == "") {
      errorFlg++;
      omise_chain_montherror = "omise-form-error";
    }
    if (this.state.chain_expiration_year == "") {
      errorFlg++;
      omise_chain_yearerror = "omise-form-error";
    }
    if (this.state.chain_security_code == "") {
      errorFlg++;
      omise_chain_codeerror = "omise-form-error";
    }

    this.setState({
      omise_chain_nameerror: omise_chain_nameerror,
      omise_chain_cardrror: omise_chain_cardrror,
      omise_chain_montherror: omise_chain_montherror,
      omise_chain_yearerror: omise_chain_yearerror,
      omise_chain_codeerror: omise_chain_codeerror,
    });

    if (errorFlg === 0) {
      showLoader("omise-pay-btn", "class");
      Omise.setPublicKey(this.state.omise_chain_public_key);
      var current = this;
      var card = {
        name: this.state.chain_holdername,
        number: this.state.chain_cardNumber,
        expiration_month: this.state.chain_expiration_month,
        expiration_year: this.state.chain_expiration_year,
        security_code: this.state.chain_security_code,
        livemode: false,
      };
      Omise.createToken("card", card, function (statusCode, response) {
        hideLoader("omise-pay-btn", "class");
        if (statusCode === 200) {
          if (
            response.object == "error" ||
            !response.card.security_code_check
          ) {
            var msgArr = Array();
            msgArr["message"] =
              response.object == "error"
                ? response.message
                : "Invalid card details.";
            current.showOmiselert(msgArr);
          } else {
            $.magnificPopup.close();
            /*current.onProcessOmiseToken(response);*/
            current.setState(
              {
                omise_chain_tokken_response: response,
                omise_chain_tokken_card_id: response.card.id,
                omise_tokken_id: response.id,
              },
              () => {
                current.onProcessOmiseChainToken(response);
              }
            );
          }
        } else {
          var msgArr = Array();
          msgArr["message"] =
            response.message !== "" ? response.message : language.omiseerror;
          current.showOmiseChainlert(msgArr);
        }
      });
    } else {
      return false;
    }
  }

  showOmiseChainlert(msgArr) {
    var dataTimeoutId = $(".omise-chain-error-info-div").attr("data-timeoutid");
    clearTimeout(dataTimeoutId);
    $("#omise-chain-error-info-div").hide();
    $("#omise-chain-error-info-div .omise-error-msg").html(msgArr["message"]);
    $("#omise-chain-error-info-div").fadeIn();
    var timeoutid = setTimeout(function () {
      $("#omise-chain-error-info-div").hide();
    }, 6000);
    $("#omise-chain-error-info-div").attr("data-timeoutid", timeoutid);
  }

  /* post stripe account */
  onProcessOmiseChainToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );

    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    var requestType =
      "ninjaent-" + cookie.load("orderOutletId") + "-" + cookie.load("UserId");

    axios
      .get(apiUrl + "paymentv1/getPaymentReferenceId?app_id=" + appId)
      .then((topRes) => {
        if (topRes.data.status === "ok") {
          var paymentRef = topRes.data.payment_ref_id;
          this.setState({ payment_ref_id: paymentRef });
          /*  load process html */
          var postObject = {};
          postObject = {
            app_id: appId,
            token: token.id,
            customer_id: cookie.load("UserId"),
            outlet_id: cookie.load("orderOutletId"),
            paid_amount: payAmount,
            request_type: requestType,
            outlet_name:
              typeof cookie.load("orderOutletName") === "undefined"
                ? cookie.load("orderOutletName")
                : "",
          };

          this.postOrder(2, "Yes"); // To validate the order

          if (this.state.orderValidFail === 0) {
            axios
              .post(apiUrl + "omisepay/authOmise", qs.stringify(postObject))
              .then((res) => {
                if (res.data.status === "ok") {
                  var captureID = res.data.result_set.payment_reference_1;
                  var omiseLogId = res.data.result_set.log_id;
                  this.setState(
                    { validateimage: tickImage, omise_log_id: omiseLogId },
                    function () {
                      this.postOrder(3, "", captureID, "OmiseChain");
                    }.bind(this)
                  );
                } else if (res.data.status === "error") {
                  if (res.data.message == "token was already used") {
                    this.omise_chain_search_history();
                  } else {
                    $.magnificPopup.close();
                    var errerTxt =
                      res.data.message !== ""
                        ? res.data.message
                        : language.error1001;
                    this.paymentFail(language.error, errerTxt);
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                    return false;
                  }
                } else {
                  var currents = this;
                  setTimeout(
                    function () {
                      currents.omise_chain_search_history();
                    }.bind(this),
                    2000
                  );
                }
              })
              .catch((error) => {
                var currentcatch = this;
                setTimeout(
                  function () {
                    currentcatch.omise_chain_search_history();
                  }.bind(this),
                  2000
                );
              });
          } else {
            $.magnificPopup.close();
            this.paymentFail(language.error, language.error1003);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });

            return false;
          }
        } else {
          $.magnificPopup.close();
          this.paymentFail(language.error, language.error1003);
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });

          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(language.error, language.error1002);
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  };

  omise_chain_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId");
    var postObject = {};
    postObject = {
      app_id: appId,
      card_id: this.state.omise_chain_tokken_card_id,
      token_id: this.state.omise_chain_tokken_id,
      outlet_id: orderOutlet_Id,
    };

    axios
      .post(apiUrl + "omisepay/omise_search_details", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.captureID;
          var omiseLogId = res.data.log_id;
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function () {
              this.postOrder(3, "", captureID, "OmiseChain");
            }.bind(this)
          );
        } else if (res.data.status === "error") {
          /* Reset poup message -  start */
          this.onProcessOmiseChainToken(this.state.omise_chain_tokken_response);
        } else {
          this.onProcessOmiseChainToken(this.state.omise_chain_tokken_response);
        }
      });
  };
  /* Omise Chain Payment End */

  changeDeliverySelfCollection() {
    if (this.state.delivery_self_collection === 1) {
      this.setState({ delivery_self_collection: 0 });
    } else {
      this.setState({ delivery_self_collection: 1 });
    }
  }

  /* 2C2P Capture Payment*/
  orderProceedTwoCTwoP(event) {
    event.preventDefault();
    /*$.magnificPopup.open({
        items: {
          src: ".processing",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        closeOnBgClick: false,
      });
  
      this.postOrder(3, "", "", "2C2P");*/
    this.captureTwoCTwoPAmount("", "", "");
  }

  captureTwoCTwoPAmount() {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;

    var cartDetailsArr = this.state.cartDetails;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );
    var orderRequest = this.orderRequest(3, "No", "", "2C2P");

    var delivery = parseFloat(calculatedAmount["deliveryCharge"]).toFixed(2);
    var captureObjects = {
      payment_reference: stripeReference,
      customer_id: cookie.load("UserId"),
      app_id: appId,
      grand_total: calculatedAmount["grandTotalAmount"],
      outlet_id: cookie.load("orderOutletId"),
      return_url: apiUrl + "payment2c2p/returnTwoCTwoP",
      order_request: orderRequest,
    };
    showLoader("proceed2c2ppayment");
    axios
      .post(
        apiUrl + "payment2c2p/captureAmountTwoCTwoP",
        qs.stringify(captureObjects)
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          /*append form*/
          cookie.save(
            "2c2preferenceID",
            captureRes.data.result_set.referenceId,
            {
              path: "/",
            }
          );
          $("#two-c-two-p-div").html(captureRes.data.result_set.form_html);
          setTimeout(function () {
            $("body").find("#two-c-two-p-form").submit();
          }, 500);

          return;
        } else if (captureRes.data.status === "error") {
          hideLoader("proceed2c2ppayment");
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : language.ordernotsuccess;
          showAlert(language.error, omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  /* this fuction used to get */
  orderRequest(
    paymentMode,
    validation = "No",
    captureID = "",
    payGetWayType = ""
  ) {
    var finalcartItems = this.state.cartItems;

    var products = [];
    this.state.cartItems.map(
      function (item) {
        var modifierdata = [];
        var comboData = [];
        var ComboLen = item.set_menu_component.length;
        var modifierLen = item.modifiers.length;
        if (item.cart_item_type === "Modifier" && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = [];
            if (item.modifiers[l].modifiers_values[0].cart_modifier_qty > 0) {
              modifierVal.push({
                modifier_value_id:
                  item.modifiers[l].modifiers_values[0].cart_modifier_id,
                modifier_value_qty:
                  item.modifiers[l].modifiers_values[0].cart_modifier_qty,
                modifier_value_name:
                  item.modifiers[l].modifiers_values[0].cart_modifier_name,
                modifier_value_price:
                  item.modifiers[l].modifiers_values[0].cart_modifier_price,
              });
            }
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal,
            });
          }
        } else if (item.cart_item_type === "Component" && ComboLen > 0) {
          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = [];
            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers;

            var combomodifierdata = [];
            if (comboModifier.length > 0) {
              for (var p = 0; p < comboModifier.length; p++) {
                var comboModifierVal = [];
                if (
                  comboModifier[p].modifiers_values[0].cart_modifier_qty > 0
                ) {
                  comboModifierVal.push({
                    modifier_value_id:
                      comboModifier[p].modifiers_values[0].cart_modifier_id,
                    modifier_value_qty:
                      comboModifier[p].modifiers_values[0].cart_modifier_qty,
                    modifier_value_name:
                      comboModifier[p].modifiers_values[0].cart_modifier_name,
                    modifier_value_price:
                      comboModifier[p].modifiers_values[0].cart_modifier_price,
                  });
                }
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal,
                });
              }
            }

            var comborVal = [];
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length;
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                if (
                  item.set_menu_component[m].product_details[j]
                    .cart_menu_component_product_qty > 0
                ) {
                  comborVal.push({
                    product_id:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_id,
                    product_name:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_name,
                    product_sku:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_sku,
                    product_qty:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_qty,
                    product_price:
                      item.set_menu_component[m].product_details[j]
                        .cart_menu_component_product_price,
                    modifiers: combomodifierdata,
                  });
                }
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal,
            });
          }
        }

        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_actual_unit_price: item.cart_item_actual_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          condiments: "",
          modifiers: modifierdata,
          bakers_modifiers: "",
          menu_set_components: comboData,
          baby_pack: "",
          product_special_notes: item.cart_item_special_notes,
          voucher_gift_name: item.cart_item_product_voucher_gift_name,
          voucher_gift_email: item.cart_item_product_voucher_gift_email,
          voucher_gift_mobile: item.cart_item_product_voucher_gift_mobile,
          voucher_gift_message: item.cart_item_product_voucher_gift_message,
          order_item_id:
            item.cart_voucher_order_item_id != "0"
              ? item.cart_voucher_order_item_id
              : "",
          voucher_free_product: item.cart_item_voucher_product_free,
          order_voucher_id: item.cart_item_voucher_id,
        });

        return products;
      }.bind(this)
    );

    /* if  merge order date */
    var orderDate = "";

    if (cookie.load("defaultAvilablityId") !== dineinId) {
      var seletedOrdDate = this.state.seleted_ord_date;
      var seletedOrdTime = this.state.seleted_ord_time;

      /* For Advanced Slot */
      var order_is_timeslot = "No",
        ordSlotStrTm = "",
        ordSlotEndTm = "";
      if (seletedOrdDate !== "" && seletedOrdTime !== "") {
        var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();

        var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

        orderDate = formatedDate + " " + orderTime;
        cookie.save("checkout_order_date", orderDate, { path: "/" });

        if (this.state.isAdvanced === "yes" && this.state.slotType === "2") {
          order_is_timeslot = "Yes";
          ordSlotStrTm = this.state.seleted_ord_slot_str;
          ordSlotEndTm = this.state.seleted_ord_slot_end;
        }
      }

      if (cookie.load("defaultAvilablityId") !== dineinId && orderDate == "") {
        var cookieDate =
          typeof cookie.load("checkout_order_date") === "undefined" ||
          cookie.load("checkout_order_date") === ""
            ? ""
            : cookie.load("checkout_order_date");
        orderDate = cookieDate;
      }
    }

    if (cookie.load("defaultAvilablityId") == dineinId) {
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime("", orderTAT);
      var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
      var OrderHours = orderDateTmTxt.getHours();
      var OrderMunts = orderDateTmTxt.getMinutes();
      var OrderSecnd = orderDateTmTxt.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      var orderDate = formatedDate + " " + orderTime;
    }

    if (
      this.state.chk_tarms_contn !== "Yes" &&
      this.state.termsAndConditions !== "" &&
      paymentMode !== "initial"
    ) {
      $(".tarms_chkbox_div").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      return false;
    }

    if (paymentMode === "initial") {
      paymentMode = 2;
    }

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    var cartDetailsArr = this.state.cartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = promotionApplied;
    promoTionArr["promotionAmount"] = promotionAmount;
    promoTionArr["promoIsDelivery"] = promoIsDelivery;
    var serviceSubChrArr = this.state.servicesurchargeval;
    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      cartDetailsArr,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );

    var BillingAddress = this.state.billing_delivery_address;
    var BillingPostalCode = this.state.billing_postcode;
    var BillingUnitNo1 = this.state.billunitnumber1;
    var BillingUnitNo2 = this.state.billunitnumber2;

    if (this.state.billing_addrs_sameas === "yes") {
      BillingAddress = cookie.load("orderDeliveryAddress");
      BillingPostalCode = cookie.load("orderPostalCode");
      BillingUnitNo1 = this.state.unitnumber1;
      BillingUnitNo2 = this.state.unitnumber2;
    }

    var custBirthdateUpdate = "",
      custBirthdate = "",
      custGender = "";
    if (this.state.cust_birthdate_update === "yes") {
      custBirthdateUpdate = this.state.cust_birthdate_update;
      var birthdateTxt = this.state.cust_birthdate;
      custBirthdate = format(birthdateTxt, "yyyy-MM-dd");
      custGender = this.state.cust_gender;
      if (validation !== "Yes") {
        cookie.save("UserBirthdate", custBirthdate, { path: "/" });
      }
    }

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    var postObject = {};
    var payment_type = payGetWayType;

    var referenceNewId = getReferenceID();
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");

    var table_number = cookie.load("orderTableNo");

    if (
      table_number !== "" &&
      table_number !== "undefined" &&
      table_number !== undefined
    ) {
      table_number = table_number.toUpperCase();
    }

    var redeem_sub_total = parseFloat(
      calculatedAmount["cartSubTotalAmount"]
    ).toFixed(2);

    if (
      this.state.globalSettings.client_rewards_apply_grand_total === "1" &&
      promotionType === "points"
    ) {
      if (
        promotionAmount > parseFloat(calculatedAmount["overall_grand_total"]) ||
        promotionAmount === parseFloat(calculatedAmount["overall_grand_total"])
      ) {
        redeem_sub_total = parseFloat(calculatedAmount["overall_grand_total"]);
      } else {
        redeem_sub_total = parseFloat(calculatedAmount["overall_grand_total"]);
      }
    }

    postObject = {
      /* cart details */
      app_id: appId,
      availability_id: cookie.load("defaultAvilablityId"),
      sub_total: parseFloat(calculatedAmount["cartSubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      order_voucher_discount_amount:
        parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0
          ? calculatedAmount["voucherDiscountAmount"]
          : "0",
      outlet_id: cookie.load("orderOutletId"),
      zone_id: zoneIdTxt,
      table_number: table_number,
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      reference_new_id: referenceNewId,
      login_type: userAccountType,

      /* For Advanced Slot */
      order_is_timeslot: order_is_timeslot,
      order_pickup_time_slot_from: ordSlotStrTm,
      order_pickup_time_slot_to: ordSlotEndTm,

      order_remarks: this.state.order_specil_note,
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      customer_address_id: "",
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: cookie.load("orderDeliveryAddress"),
      customer_address_line2: "",
      customer_postal_code: cookie.load("orderPostalCode"),
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,
      customer_alter_contact_no: this.state.alternative_phoneno,
      latitude:
        cookie.load("latitude") !== "" &&
        typeof cookie.load("latitude") !== undefined &&
        typeof cookie.load("latitude") !== "undefined"
          ? cookie.load("latitude")
          : "",
      longitude:
        cookie.load("longitude") !== "" &&
        typeof cookie.load("longitude") !== undefined &&
        typeof cookie.load("longitude") !== "undefined"
          ? cookie.load("longitude")
          : "",

      customer_birthdate_update: custBirthdateUpdate,
      customer_birthdate: custBirthdate,
      customer_gender: custGender,

      billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir: "test",

      payment_type: payment_type === "Xendit" ? "xendit" : payment_type,
      payment_getway_details:
        payGetWayType === "OmisePayNow" || payGetWayType === "OmisePayNowChain"
          ? { payment_type: "Omise PayNow", payment_status: "Success" }
          : "",
      order_payment_retrieved:
        (payGetWayType === "OmisePayNow" ||
          payGetWayType === "OmisePayNowChain") &&
        captureID !== ""
          ? "Yes"
          : "No",
      omise_log_id:
        (payGetWayType === "OmisePayNow" ||
          payGetWayType === "OmisePayNowChain") &&
        captureID !== ""
          ? this.state.omise_log_id
          : "",
      order_capture_token: captureID,
      order_payment_getway_type: "",
      order_payment_getway_status: "Yes",
      order_local_payment_reference: this.state.payment_ref_id,
      /* additional paras */
      delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),

      /* Sur & Service Chareges */
      order_subcharge_amount: parseFloat(calculatedAmount["surCharge"]).toFixed(
        2
      ),
      order_service_charge: calculatedAmount["servicechargePer"],
      order_service_charge_amount: parseFloat(
        calculatedAmount["serviceCharge"]
      ).toFixed(2),
      order_servicecharge_displaylabel:
        calculatedAmount["servicechrgDisplaylbl"],

      order_tat_time:
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT"),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),
      order_data_encode: "Yes",

      /* discount */
      order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",

      order_special_discount_amount: cartDetailsArr.cart_special_discount,
      order_special_discount_type: cartDetailsArr.cart_special_discount_type,
      order_customer_send_gift: this.state.order_customer_send_gift,
      order_recipient_name: this.state.recipient_name,
      order_recipient_contact_no: this.state.recipient_contact_no,
      order_gift_message: this.state.gift_message,
      product_leadtime: this.state.product_lead_time,
      delivery_self_collection: this.state.delivery_self_collection,

      /* validation purpose*/
      promotion_applied_text: promotionApplied,
      promo_applied_delivery_text: promoIsDelivery,
      is_freeDelivery_text: promoIsDelivery,
    };

    if (promotionApplied === "Yes" && promoIsDelivery == "Yes") {
      var categoryIdsDet = this.getProductIdsDet(finalcartItems);
      postObject["coupon_applied"] = "Yes";
      postObject["promo_code"] = promotionSource;
      postObject["category_id"] = categoryIdsDet;
    }

    /*  promo code object - start */
    if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
        postObject["redeem_sub_total"] = redeem_sub_total;
      }
    }

    postObject["reward_point_status"] = "Yes";
    postObject["validate_product_specialprice"] = "Yes";

    if (payGetWayType === "Ocbc") {
      if (validation === "Yes") {
        postObject["order_ocbc_reference_id"] = this.makeid(10);
      } else {
        postObject["order_ocbc_reference_id"] = cookie.load("ocbcreferenceID");
      }
      cookie.save("ocbcreferenceID", postObject["order_ocbc_reference_id"], {
        path: "/",
      });
    }

    if (
      payGetWayType === "Gcash" ||
      payGetWayType === "paymaya" ||
      payGetWayType == "Xendit"
    ) {
      if (validation === "Yes") {
        postObject["order_xenditEwallet_reference_id"] = this.makeid(10);
      } else {
        postObject["order_xenditEwallet_reference_id"] = cookie.load(
          "xenditewalletReferenceId"
        );
      }
      cookie.save(
        "xenditewalletReferenceId",
        postObject["order_xenditEwallet_reference_id"],
        { path: "/" }
      );
    }

    if (payGetWayType === "Fomopay") {
      if (validation === "Yes") {
        postObject["order_fomopay_reference_id"] = this.makeid(10);
      } else {
        postObject["order_fomopay_reference_id"] =
          cookie.load("fomopayReferenceId");
      }
      cookie.save(
        "fomopayReferenceId",
        postObject["order_fomopay_reference_id"],
        { path: "/" }
      );
    }
    postObject["validation_only"] = "No";
    postObject["connect_platform_fee"] = cookie.load("platform_fee");
    postObject["connect_stripe_account_id"] = cookie.load("stripe_account_id");
    postObject["connect_total_amount"] = cookie.load("connect_total_amount");
    if (cookie.load("chopchoptoken") === "Yes") {
      postObject["order_is_promobuddy"] = "Yes";
    }
    return postObject;
  }
  /* 2C2P Payment End */
  /* Allinpay Payment Start */
  orderProceedAllinpay(event) {
    event.preventDefault();
    showLoader("proceedallinpaypayment");
    this.postOrder(2, "Yes", "", "", "", "", "Yes");
  }
  confirmAllinpay(captureObjects) {
    axios
      .post(
        apiUrl + "paymentAllinpay/captureAmount",
        qs.stringify(captureObjects)
      )
      .then((captureRes) => {
        hideLoader("proceedallinpaypayment");
        if (captureRes.data.status === "ok") {
          $.magnificPopup.close();
          window.location.href = captureRes.data.result_set.redirect_url;
        } else {
          var magnfPopup = $.magnificPopup.instance;
          showAlert("Error", captureRes.data.message, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      });
  }
  /* Allinpay Payment End */

  render() {
    if (Object.keys(this.state.globalSettings).length > 0) {
      if (
        this.state.globalSettings.stripe_user_id &&
        this.state.globalSettings.stripe_express_enable == 1 &&
        this.state.globalSettings.stripe_express_connect_public_key != ""
      ) {
        stripePromise = loadStripe(
          this.state.globalSettings.stripe_express_connect_public_key
        );
      }
    }
    var clientSecret = this.state.clientSecret;
    const options = {
      clientSecret,
    };

    var showRdmPoints =
      parseFloat(this.state.reward_point_count) -
      parseFloat(this.state.used_reward_point);
    showRdmPoints = showRdmPoints.toFixed(2);
    var InptRdmPoints =
      parseFloat(this.state.reward_point_count) > 0
        ? this.state.reward_point_count
        : 0;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
    var serviceSubChrArr = this.state.servicesurchargeval;

    var calculatedAmount = getCalculatedAmount(
      this.state.globalSettings,
      this.props.zonedetails,
      this.state.cartDetails,
      promoTionArr,
      serviceSubChrArr,
      this.state.delivery_self_collection
    );

    var subTotalAmount = parseFloat(calculatedAmount["cartSubTotalAmount"]);
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    /* For Advanced Slot */
    var advancedTimeslotEnable = "0";
    if (Object.keys(this.state.globalSettings).length > 0) {
      advancedTimeslotEnable =
        this.state.globalSettings.client_advanced_timeslot_enable;
    }

    var settingsAddons = {
      infinite: false,
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    var birthdate = this.state.cust_birthdate;
    var gender = this.state.cust_gender;
    var genderHtml =
      "<option value=''> " +
      language.yourgender +
      " </option><option value='M'> " +
      language.male +
      " </option><option value='F'> " +
      language.female +
      " </option><option value='O'> " +
      language.unspecified +
      " </option>";
    var genderDropDown = Parser(genderHtml);

    var userBirthdate =
      cookie.load("UserBirthdate") != "" &&
      typeof cookie.load("UserBirthdate") != undefined &&
      typeof cookie.load("UserBirthdate") != "undefined"
        ? cookie.load("UserBirthdate")
        : "";
    if (userBirthdate === "") {
      userBirthdate =
        cookie.load("birthdate") != "" && cookie.load("birthdate") != undefined
          ? cookie.load("birthdate")
          : "";
    }

    var title = language.selfcollectiondetail;

    if (cookie.load("defaultAvilablityId") === deliveryId) {
      title = "Delivery Details";
    } else if (cookie.load("defaultAvilablityId") === dineinId) {
      title =
        appId === "D93083AF-FD2F-4037-AD20-44BD976DC5E0"
          ? language.instoreorderingdetails
          : language.dineindetails;
    }

    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");
    var orderDateTmTxt = getOrderDateTime("", orderTAT);

    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" ? (
            <div className="common-inner-banner">
              <p>{language.review}</p>
              <p className="chkout-para">{language.scrolldowncheckout}</p>
            </div>
          ) : (
            <div className="common-inner-banner">
              <p>{language.yourorderdetails}</p>
              <p className="chkout-para">{language.scrolldowncheckout}</p>
            </div>
          )}
        </div>

        {/* container - start */}
        <div className="container">
          {/* Birthday Info div - start */}
          {cookie.load("userAccountType") !== "2" && (
            <div className="birthday-info-div">
              <div className="birthday-info-top">
                <h3>
                  <div>
                    <span>{language.welcome}</span>
                    <br />
                    {cookie.load("UserFname")}{" "}
                  </div>
                  <img src={hiHndImage} alt="Welcome" />
                </h3>
              </div>
            </div>
          )}

          {/* Birthday Info div - end */}

          {/* check_out_fourcommon - start */}
          <div className="check_out_fourcommon">
            <div className="container-one">
              {/* checkoutpage_form_outer - start */}
              <div className="checkoutpage_form_outer">
                <form
                  id="checkoutpage_form"
                  className=" form_sec checkout-total"
                  method="post"
                  acceptCharset="utf-8"
                >
                  <div className="cdd-details">
                    {/* cdd-details-lhs - start */}
                    <div className="cdd-details-lhs fl">
                      <div className="text-center checkout-heading">
                        <span className="text-uppercase">
                          {/* {cookie.load("defaultAvilablityId") === deliveryId
                            ? "Delivery Details"
                            : "Self Collection Details"} */}
                          {title}
                        </span>
                      </div>
                      <div className="checkout-body-section">
                        {cookie.load("defaultAvilablityId") === deliveryId && (
                          <div className="checkout-delivery-details">
                            <div className="checkout-delivery-lhs">
                              <label className="chk_hea">
                                {language.orderhandled}
                              </label>
                              <p>{cookie.load("orderOutletName")}</p>
                              <p>{cookie.load("orderHandledByText")}</p>
                            </div>
                            <div className="checkout-delivery-rhs">
                              <div className="checkout-control-group-top">
                                <label className="chk_hea">
                                  {language.deliveryloc}
                                </label>
                                {mapcountry === "sg" ? (
                                  <p>
                                    {this.state.order_delivery_address},{" "}
                                    {CountryTxt} {this.state.order_postcode}
                                  </p>
                                ) : (
                                  <p>{this.state.order_delivery_address}</p>
                                )}
                                <div className="form-group controls-three-small">
                                  <span>#</span>

                                  <div className="cthree-small-inner">
                                    <div
                                      className={
                                        this.state.unitnumber1 != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <input
                                        placeholder={language.unitno1}
                                        type="text"
                                        id="unit_no1_id"
                                        name="unit_no1"
                                        value={this.state.unitnumber1}
                                        className="form-control input-focus"
                                        onChange={this.handleAddrChange.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                    <div
                                      className={
                                        this.state.unitnumber2 != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <input
                                        placeholder={language.unitno2}
                                        type="text"
                                        name="unit_no2"
                                        value={this.state.unitnumber2}
                                        className="form-control input-focus"
                                        onChange={this.handleAddrChange.bind(
                                          this
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div
                                    className={
                                      this.state.alternative_phoneno != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <input
                                      placeholder={language.altphoneno}
                                      type="text"
                                      id="alternative_phoneno_id"
                                      name="alternative_phoneno"
                                      value={this.state.alternative_phoneno}
                                      className="form-control input-focus"
                                      onChange={this.handleAddrChange.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                                <a
                                  href="/"
                                  onClick={this.changeAddrrFun.bind(this)}
                                  className=""
                                >
                                  {language.changedeliveryloc}
                                </a>
                              </div>
                            </div>
                          </div>
                        )}

                        {cookie.load("defaultAvilablityId") === pickupId && (
                          <div className="checkout-control-group-top">
                            <div className="checkout-control-group-top">
                              <label className="chk_hea">
                                {language.pickuploc}
                              </label>
                              <div className="col-xs-cls">
                                <p>
                                  {cookie.load("orderOutletName")} <br />
                                  {cookie.load("orderHandledByText")}
                                </p>
                              </div>
                              <a
                                href="/"
                                onClick={this.changeAddrrFun.bind(this)}
                                className="change-pickup-location"
                              >
                                {language.changepickuploc}
                              </a>
                            </div>
                          </div>
                        )}

                        {cookie.load("defaultAvilablityId") === dineinId && (
                          <div className="checkout-control-group-top">
                            <label className="chk_hea">
                              {appId === "D93083AF-FD2F-4037-AD20-44BD976DC5E0"
                                ? language.instoreordering
                                : language.dinein}{" "}
                              {language.location}
                            </label>
                            <div className="col-xs-cls">
                              <p>{cookie.load("orderHandledByText")}</p>
                            </div>

                            <label className="chk_hea">
                              {appId === "D93083AF-FD2F-4037-AD20-44BD976DC5E0"
                                ? language.instoreordering
                                : language.dinein}{" "}
                              {language.datetime}:{" "}
                              {moment(orderDateTmTxt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </label>
                            <label className="chk_hea">
                              {language.tableno}: {cookie.load("orderTableNo")}
                            </label>
                          </div>
                        )}

                        {(cookie.load("defaultAvilablityId") === deliveryId ||
                          cookie.load("defaultAvilablityId") === pickupId) && (
                          <div className="checkout-control-group-middle">
                            <div className="form-group1234">
                              {advancedTimeslotEnable === "1" ? (
                                <OrderAdvancedDatetimeSlot
                                  {...this.props}
                                  hdrState={this.state}
                                  setdateTimeFlg={this.setdateTimeFlg}
                                  indutualText={true}
                                />
                              ) : (
                                <OrderdatetimeSlot
                                  {...this.props}
                                  hdrState={this.state}
                                  setdateTimeFlg={this.setdateTimeFlg}
                                />
                              )}
                              <div className="ordrdatetime_error"></div>
                            </div>
                          </div>
                        )}

                        {cookie.load("defaultAvilablityId") === deliveryId && (
                          <>
                            <div className="checkout-billing-address">
                              <label className="chk_hea">
                                {language.billingaddress}{" "}
                                <span>
                                  {language.samedeliveryaddress}{" "}
                                  <div className="custom_checkbox">
                                    <input
                                      type="checkbox"
                                      name="bill_chk"
                                      className="addon_list_single"
                                      onChange={this.changeBillingAddrChk.bind(
                                        this
                                      )}
                                      checked={this.CheckBillingAddrChk(
                                        "checkbox"
                                      )}
                                    />
                                    <span></span>
                                  </div>
                                </span>
                              </label>
                              <div
                                className="check-billing"
                                style={{
                                  display: this.CheckBillingAddrChk("display"),
                                }}
                              >
                                <div className="form-group controls-three">
                                  <div className="form-group">
                                    <div
                                      className={
                                        this.state.billing_postcode != ""
                                          ? "focus-out focused"
                                          : "focus-out"
                                      }
                                    >
                                      <label>{language.postalcode}</label>
                                      <input
                                        type="text"
                                        name="billing_postcode"
                                        value={this.state.billing_postcode}
                                        onChange={this.changePostalCode.bind(
                                          this,
                                          "bill"
                                        )}
                                        onKeyPress={(e) =>
                                          this.validateIntval(e)
                                        }
                                        className="form-control input-focus"
                                      />
                                    </div>
                                  </div>
                                  <span>#</span>
                                  <div
                                    className={
                                      this.state.billunitnumber1 != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label className="unit-num">
                                      {language.unitno1}
                                    </label>
                                    <input
                                      type="text"
                                      name="bill_unit_no1"
                                      value={this.state.billunitnumber1}
                                      className="form-control input-focus"
                                      onChange={this.handleAddrChange.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.billunitnumber2 != ""
                                        ? "focus-out focused"
                                        : "focus-out"
                                    }
                                  >
                                    <label className="unit-num">
                                      {language.unitno2}
                                    </label>
                                    <input
                                      type="text"
                                      name="bill_unit_no2"
                                      value={this.state.billunitnumber2}
                                      className="form-control input-focus"
                                      onChange={this.handleAddrChange.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out controls-single">
                                    <input
                                      type="text"
                                      className="form-control input-focus"
                                      placeholder={language.address}
                                      readOnly
                                      value={
                                        this.state.billing_delivery_address
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.globalSettings.client_gift_enable ===
                              "1" &&
                              appId !==
                                "5AF0459E-1CD7-45A8-8B49-7202E2A401FC" && (
                                <div className="checkout-billing-address send-as-gift">
                                  <label className="chk_hea">
                                    {language.sendasgift}{" "}
                                    <span>
                                      <div className="custom_checkbox">
                                        <input
                                          type="checkbox"
                                          name="bill_chk"
                                          className="addon_list_single"
                                          onChange={this.changeGiftChk.bind(
                                            this
                                          )}
                                          checked={
                                            this.state
                                              .order_customer_send_gift ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                        />
                                        <span></span>
                                      </div>
                                    </span>
                                  </label>
                                  <div
                                    className="check-billing"
                                    style={{
                                      display:
                                        this.state.order_customer_send_gift ===
                                        "No"
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <div className="form-group controls-two">
                                      <div className="form-group">
                                        <div
                                          className={
                                            this.state.recipient_name != ""
                                              ? "focus-out focused"
                                              : "focus-out"
                                          }
                                        >
                                          <label>
                                            {language.recipientname}
                                          </label>
                                          <input
                                            type="text"
                                            name="recipient_name"
                                            value={this.state.recipient_name}
                                            onChange={this.handleAddrChange.bind(
                                              this
                                            )}
                                            className="form-control input-focus"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          this.state.recipient_contact_no != ""
                                            ? "focus-out focused"
                                            : "focus-out"
                                        }
                                      >
                                        <label>
                                          {language.recipientnamerecipientname}
                                        </label>
                                        <input
                                          type="text"
                                          name="recipient_contact_no"
                                          value={
                                            this.state.recipient_contact_no
                                          }
                                          onChange={this.handleAddrChange.bind(
                                            this
                                          )}
                                          maxLength="8"
                                          className="form-control input-focus"
                                          onKeyPress={(e) =>
                                            this.validateIntval(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="focus-out controls-single">
                                        <textarea
                                          className=""
                                          placeholder={language.giftmessagehere}
                                          name="gift_message"
                                          value={this.state.gift_message}
                                          onChange={this.handleAddrChange.bind(
                                            this
                                          )}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </>
                        )}

                        {cookie.load("defaultAvilablityId") === dineinId && (
                          <div className="checkout-control-group-bottom hidden">
                            <label className="chk_hea">
                              <span>
                                <div className="custom_checkbox">
                                  <input
                                    type="checkbox"
                                    /* name="bill_chk" */
                                    className="addon_list_single"
                                    onChange={this.changeDeliverySelfCollection.bind(
                                      this
                                    )}
                                    checked={
                                      this.state.delivery_self_collection === 1
                                        ? true
                                        : false
                                    }
                                  />
                                  <span></span>
                                </div>
                                <label>{language.liketoselfcollect} </label>
                              </span>
                            </label>
                          </div>
                        )}

                        <div className="checkout-control-group-bottom">
                          {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" &&
                            cookie.load("defaultAvilablityId") != dineinId && (
                              <>
                                <label className="chk_hea">
                                  {language.specialinstruction}
                                </label>
                                <textarea
                                  placeholder={language.enterspecialmsg}
                                  name="specil_note"
                                  value={this.state.order_specil_note}
                                  onChange={this.handleAddrChange.bind(this)}
                                  id="orderRemarks"
                                ></textarea>
                              </>
                            )}
                          {appId != "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" && (
                            <>
                              <label className="chk_hea">
                                {appId == "5AF0459E-1CD7-45A8-8B49-7202E2A401FC"
                                  ? language.remarks
                                  : language.specialinstruction}
                              </label>
                              <textarea
                                placeholder={
                                  appId ==
                                  "5AF0459E-1CD7-45A8-8B49-7202E2A401FC"
                                    ? language.entermessage
                                    : language.entermessage
                                }
                                name="specil_note"
                                value={this.state.order_specil_note}
                                onChange={this.handleAddrChange.bind(this)}
                                id="orderRemarks"
                              ></textarea>
                            </>
                          )}

                          {this.state.termsAndConditions !== "" && (
                            <div>
                              <div className="checkout-terms-and-condition">
                                {this.state.termsAndConditions}
                              </div>
                              <div className="custom_checkbox tarms_chkbox_div">
                                <input
                                  type="checkbox"
                                  name="tarms_chk"
                                  onClick={this.changeTarmsContnChk.bind(this)}
                                  defaultChecked={this.CheckTarmsContnChk()}
                                />
                                <span>{language.readacceptterms}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* cdd-details-lhs - end */}

                    {/* cdd-details-rhs - start */}
                    <div className="cdd-details-rhs fl">
                      <div className="chekout_cart_bar">
                        <div className="cart-header">
                          <div className="text-center checkout-heading">
                            <span className="text-uppercase">
                              {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2"
                                ? language.whatyouenjoy
                                : language.yourorderdetails}
                            </span>
                          </div>
                        </div>

                        {this.loadCartOverallData()}
                      </div>
                    </div>
                    {/* cdd-details-rhs - end */}
                  </div>
                </form>
              </div>
              {/* checkoutpage_form_outer - end */}

              {/* redeem div - start */}
              {appId !== "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" && (
                <div className="redeem">
                  <div className="redeem-row">
                    <div className="redeem-col">
                      <div className="redeem-item">
                        <div className="redeem-item-hea">
                          <div className="redeem-item-hea-inner">
                            <h4>{language.redeempoints}</h4>
                            {/*<span>Redeem your points here</span>*/}
                          </div>
                          {parseFloat(this.state.reward_point_count) > 0 ? (
                            <Link to={"/rewards"} className="points">
                              You have <b>{showRdmPoints} points</b> available
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="redeem_apply focus-out applypoints_cls">
                          <div
                            className={
                              this.state.used_reward_point != ""
                                ? "focus-out focused"
                                : "focus-out"
                            }
                          >
                            <label>
                              {"You Can Redeem " + InptRdmPoints + " Points"}
                            </label>
                            <input
                              type="text"
                              name="reward_point"
                              value={this.state.reward_point_val}
                              onKeyPress={(e) => this.validateFloatval(e)}
                              onChange={this.handleAddrChange.bind(this)}
                              id="pointValue"
                              className="form-control input-focus"
                            />
                          </div>
                          {this.state.promotion_applied === "Yes" &&
                          this.state.promotion_type === "points" ? (
                            <button
                              className="button btn_minwid applyPoints"
                              onClick={this.removePointsAndPromo.bind(
                                this,
                                "fromclk"
                              )}
                            >
                              {language.remove}
                            </button>
                          ) : (
                            <button
                              className="button btn_minwid applyPoints"
                              onClick={this.applyPointsAndPromo.bind(
                                this,
                                "points"
                              )}
                            >
                              {language.applynow}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="redeem-col redeem-col-right">
                      <div className="redeem-item">
                        <div className="redeem-item-hea">
                          <div className="redeem-item-hea-inner">
                            <h4>{language.promocode}</h4>
                            {/*<span>Apply your promo code here</span>*/}
                          </div>
                          {parseFloat(this.state.promotion_count) > 0 ? (
                            <Link to={"/mypromotions"} className="points">
                              You have{" "}
                              <b>{this.state.promotion_count} Promotions</b>{" "}
                              available
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="redeem_apply focus-out applypromo_cls">
                          <div
                            className={
                              this.state.promo_code_val != ""
                                ? "focus-out focused"
                                : "focus-out"
                            }
                          >
                            <label>{language.addpromocodehere}</label>
                            <input
                              name="promo_code"
                              value={this.state.promo_code_val}
                              id="promocodeValue"
                              type="text"
                              className="form-control input-focus"
                              onChange={this.handleAddrChange.bind(this)}
                            />
                          </div>
                          {this.state.promotion_applied === "Yes" &&
                          this.state.promotion_type === "promoCode" ? (
                            <button
                              className="button btn_minwid promobtn applyPromo"
                              onClick={this.removePointsAndPromo.bind(
                                this,
                                "fromclk"
                              )}
                            >
                              {language.remove}
                            </button>
                          ) : (
                            <button
                              className="button btn_minwid promobtn applyPromo"
                              onClick={this.applyPointsAndPromo.bind(
                                this,
                                "promoCode"
                              )}
                            >
                              {language.applynow}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* redeem div - end */}
              {/* Birthday div - Start */}
              {userBirthdate === "" && (
                <div className="birthday-info-move">
                  <div className="birthday-info-middle">
                    <p>{language.getexcitingpromotions}</p>
                  </div>

                  <div className="birthday-info-bottom">
                    <div className="birthday-inpt-act">
                      <div className="left-input-div">
                        <div className="react_datepicker">
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control"
                            selected={birthdate}
                            placeholderText={language.yourbirthday}
                            maxDate={this.state.Maxdate}
                            dateFormat="dd/MM/yyyy"
                            onChange={this.handleChangeDate}
                          />
                        </div>
                      </div>
                      <div className="right-input-div">
                        <div className="narml_select">
                          <select
                            value={this.state.cust_gender}
                            name="cust_gender"
                            className="form-control"
                            onChange={this.onGenderChange.bind(this)}
                          >
                            {genderDropDown}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Birthday div - end */}
              {/* chk-payment div - end */}
              <div className="chk-payment">
                <div className="chk-payment-row">
                  <div className="chk-payment-title">
                    {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" &&
                    cookie.load("defaultAvilablityId") === dineinId ? (
                      <h3>{language.payatthecounter}</h3>
                    ) : appId == "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380" &&
                      cookie.load("defaultAvilablityId") === dineinId ? (
                      <h3>{language.paymentatcashier}</h3>
                    ) : (
                      <h3>{language.selectpaymentmethod}</h3>
                    )}
                  </div>
                  <div className="chk-payment-col">
                    <form action="#">
                      {this.state.paymentmodevalue !== "promotion" &&
                        parseFloat(payAmount) > 0 && (
                          <ul className="chk-payment-col-radio">
                            {this.state.cod_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Cash"
                                      ? "checked"
                                      : ""
                                  }
                                  id="cash"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Cash"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="cash"></label>
                                <div className="radio_con">
                                  {appId ==
                                    "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2" &&
                                  cookie.load("defaultAvilablityId") ===
                                    dineinId ? (
                                    <img
                                      src={paybylaterhans}
                                      className="pay_by_cash_hans"
                                      alt={language.paybycod}
                                    />
                                  ) : appId ==
                                      "BB027A8D-0A12-4F6D-A9EA-BB8A18F86380" &&
                                    cookie.load("defaultAvilablityId") ===
                                      dineinId ? (
                                    <img
                                      src={paybycod2}
                                      alt={language.paybycod}
                                    />
                                  ) : (
                                    <img
                                      src={paybycod}
                                      alt={language.paybycod}
                                    />
                                  )}
                                </div>
                              </li>
                            )}
                            {this.state.stripe_payment_enable === 1 &&
                              this.state.stripe_express_payment_enable == 0 && (
                                <li className="custom_checkbox">
                                  <input
                                    type="radio"
                                    defaultChecked={
                                      this.state.paymentmodevalue == "Stripe"
                                        ? "checked"
                                        : ""
                                    }
                                    id="card"
                                    onClick={this.choosePayment.bind(
                                      this,
                                      "Stripe"
                                    )}
                                    name="radio-group"
                                  />
                                  <label htmlFor="card"></label>
                                  <div className="radio_con">
                                    <img
                                      src={paybystripe}
                                      alt={language.paybystripe}
                                    />
                                  </div>
                                </li>
                              )}
                            {this.state.koomipay_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Koomipay"
                                      ? "checked"
                                      : ""
                                  }
                                  id="card"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Koomipay"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="card"></label>
                                <div className="radio_con">
                                  <img
                                    src={paybykoomipay}
                                    alt={language.paybykoomipay}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.stripe_express_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue ==
                                    "StripeConnect"
                                      ? "checked"
                                      : ""
                                  }
                                  id="card"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "StripeConnect"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="card"></label>
                                <div className="radio_con">
                                  <img
                                    src={paybystripe}
                                    alt={language.paybystripeconnect}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.omise_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Omise"
                                      ? "checked"
                                      : ""
                                  }
                                  id="omscard"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Omise"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="omscard"></label>
                                <div className="radio_con">
                                  <img
                                    src={paybyomise}
                                    alt={language.paybyomise}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.omisepaynow_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "OmisePayNow"
                                      ? "checked"
                                      : ""
                                  }
                                  id="omspaynowcard"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "OmisePayNow"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="omspaynowcard"></label>
                                <div className="radio_con">
                                  <img
                                    src={paybyomisepaynow}
                                    alt={language.paybyomisepaynow}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.omise_chain_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "OmiseChain"
                                      ? "checked"
                                      : ""
                                  }
                                  id="omschaincard"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "OmiseChain"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="omschaincard"></label>
                                <div className="radio_con">
                                  <img
                                    src={paybyomise}
                                    alt={language.paybyomisechain}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.omisepaynow_chain_payment_enable ===
                              1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue ==
                                    "OmisePayNowChain"
                                      ? "checked"
                                      : ""
                                  }
                                  id="omspaynowchaincard"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "OmisePayNowChain"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="OmisePayNowChain"></label>
                                <div className="radio_con">
                                  <img
                                    src={paybyomisepaynow}
                                    alt={language.paybyomisepaynow}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.xendit_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Xendit"
                                      ? "checked"
                                      : ""
                                  }
                                  id="xendit"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Xendit"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="xendit"></label>
                                <div className="radio_con xendit-img">
                                  <img
                                    src={xendit}
                                    alt={language.paybyxendit}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.offline_payment_enable === 1 && (
                              <li
                                className="custom_checkbox"
                                onClick={this.choosePayment.bind(
                                  this,
                                  "Offline"
                                )}
                              >
                                <input
                                  type="radio"
                                  id="Offline"
                                  name="radio-group"
                                />
                                <label htmlFor="Offline"></label>
                                <div className="radio_con xendit-img">
                                  <img
                                    src={
                                      appId ===
                                      "9648B00B-467E-4AF0-A03B-014C81945EE5"
                                        ? paymentoff_rbliciouz
                                        : paymentoffdel
                                    }
                                    alt={language.paybyoffline}
                                  />
                                </div>
                              </li>
                            )}

                            {this.state.ocbc_payment_enable === 1 && (
                              <li className="custom_checkbox pay_anyone">
                                <div className="pay_anyone_inner">
                                  <input
                                    type="radio"
                                    defaultChecked={
                                      this.state.paymentmodevalue == "Ocbc"
                                        ? "checked"
                                        : ""
                                    }
                                    id="omspaynowcard"
                                    onClick={this.choosePayment.bind(
                                      this,
                                      "Ocbc"
                                    )}
                                    name="radio-group"
                                  />
                                  <label htmlFor="omspaynowcard"></label>
                                  <div className="radio_con">
                                    <img
                                      src={payanyonenow}
                                      alt={language.paybyanyone}
                                    />
                                  </div>
                                </div>
                              </li>
                            )}

                            {this.state.gcash_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Gcash"
                                      ? "checked"
                                      : ""
                                  }
                                  id="xendit"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Gcash"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="Gcash"></label>
                                <div className="radio_con xendit-img">
                                  <img src={gcash} alt={language.paybygcash} />
                                </div>
                              </li>
                            )}

                            {this.state.paymaya_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "paymaya"
                                      ? "checked"
                                      : ""
                                  }
                                  id="xendit"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "paymaya"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="paymaya"></label>
                                <div className="radio_con xendit-img">
                                  <img
                                    src={paymaya}
                                    alt={language.paybypaymaya}
                                  />
                                </div>
                              </li>
                            )}

                            {this.state.fomopay_payment_enable === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "Fomopay"
                                      ? "checked"
                                      : ""
                                  }
                                  id="fomopay"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "Fomopay"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="fomopay"></label>
                                <div className="radio_con xendit-img">
                                  <img
                                    src={fomopay}
                                    alt={language.paybyfomopay}
                                  />
                                </div>
                              </li>
                            )}

                            {this.state.enabletwoctwop === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "2C2P"
                                      ? "checked"
                                      : ""
                                  }
                                  id="2c2p"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "2C2P"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="2c2p"></label>
                                <div className="radio_con xendit-img">
                                  <img
                                    src={twoctwop}
                                    alt={language.payby2c2p}
                                  />
                                </div>
                              </li>
                            )}
                            {this.state.enableallinpay === 1 && (
                              <li className="custom_checkbox">
                                <input
                                  type="radio"
                                  defaultChecked={
                                    this.state.paymentmodevalue == "ALLINPAY"
                                      ? "checked"
                                      : ""
                                  }
                                  id="ALLINPAY"
                                  onClick={this.choosePayment.bind(
                                    this,
                                    "ALLINPAY"
                                  )}
                                  name="radio-group"
                                />
                                <label htmlFor="ALLINPAY"></label>
                                <div className="radio_con xendit-img">
                                  <img
                                    src={allinpay}
                                    alt={language.paybyaLLINPAY}
                                  />
                                </div>
                              </li>
                            )}
                          </ul>
                        )}

                      {this.state.paymentmodevalue === "promotion" &&
                        parseFloat(payAmount) === 0 &&
                        subTotalAmount > 0 && (
                          <ul className="chk-payment-col-radio promotion-ul-topcls">
                            <li className="custom_checkbox">
                              <input
                                type="radio"
                                defaultChecked={
                                  this.state.paymentmodevalue == "promotion"
                                    ? "checked"
                                    : ""
                                }
                                id="promotion"
                                name="radio-group"
                              />
                              <label htmlFor="promotion"></label>
                              <div className="radio_con">
                                {language.promotion}
                              </div>
                            </li>
                          </ul>
                        )}
                    </form>

                    <div
                      id="otherpayment-popup"
                      className="white-popup mfp-hide popup_sec login-popup"
                    >
                      <div className="pop-whole">
                        <div className="inside-dine-popup-rhs">
                          <div className="pop-whole-lhs-inner home-popup-head">
                            {this.state.qrCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chk-payment-col"></div>
                </div>

                {/* chk-payment div - end */}

                {/* chk-payment-btn div - end */}
                <div className="chk-payment-btn">
                  {this.state.paymentmodevalue !== "promotion" &&
                    parseFloat(payAmount) > 0 && (
                      <div className="chk-payment-btn-row">
                        {this.state.paymentmodevalue == "Cash" &&
                          this.state.cod_payment_enable === 1 && (
                            <a
                              className="button cash_checkout_btn"
                              title={language.checkoutnow}
                              onClick={this.payCash.bind(this)}
                              disabled={this.state.isCashButtonDisabled}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {/* Offline Payment */}

                        {this.state.paymentmodevalue == "Offline" &&
                          this.state.offline_payment_enable === 1 && (
                            <a
                              href="/"
                              className="button"
                              title={language.checkoutnow}
                              onClick={this.payOffline.bind(this)}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.paymentmodevalue == "Gcash" &&
                          this.state.gcash_payment_enable === 1 && (
                            <a
                              href="/"
                              className="button xenditbtn"
                              title={language.checkoutnow}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Gcash"
                              )}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.paymentmodevalue == "paymaya" &&
                          this.state.paymaya_payment_enable === 1 && (
                            <a
                              href="/"
                              className="button xenditbtn"
                              title={language.checkoutnow}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "paymaya"
                              )}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.globalSettings.stripe_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Stripe" &&
                          this.state.stripe_payment_enable === 1 &&
                          this.state.stripe_express_payment_enable === 0 && (
                            <a
                              href="/"
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Stripe"
                              )}
                              className="button xenditbtn"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.globalSettings.koomipay_client_key &&
                          this.state.paymentmodevalue == "Koomipay" &&
                          this.state.koomipay_payment_enable === 1 && (
                            <a
                              href="/"
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Koomipay"
                              )}
                              className="button"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.globalSettings.omise_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Omise" &&
                          this.state.omise_payment_enable === 1 && (
                            <a
                              href="/"
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Omise"
                              )}
                              className="button"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}
                        {this.state.globalSettings.omise_paynow_public &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "OmisePayNow" &&
                          this.state.omisepaynow_payment_enable === 1 && (
                            <a
                              href={void 0}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "OmisePayNow"
                              )}
                              className="button"
                              id="omisepaynow_btn"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}
                        {this.state.globalSettings.client_ocbc_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Ocbc" &&
                          this.state.ocbc_payment_enable === 1 && (
                            <a
                              href={void 0}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Ocbc"
                              )}
                              className="button"
                              id="ocbcpaynow_btn"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.omise_chain_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "OmiseChain" && (
                            <a
                              href={void 0}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "OmiseChain"
                              )}
                              className="button"
                              id="omisepaynow_btn"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}
                        {this.state.omise_paynow_chain_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "OmisePayNowChain" && (
                            <a
                              href={void 0}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "OmisePayNowChain"
                              )}
                              className="button"
                              id="omisepaynowchain_btn"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}
                        {this.state.globalSettings.client_xendit_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Xendit" &&
                          this.state.xendit_payment_enable === 1 && (
                            <a
                              href={void 0}
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Xendit"
                              )}
                              className="button xenditbtn"
                              id="xenditpay_btn"
                              title={language.checkoutnow}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.paymentmodevalue == "Fomopay" &&
                          this.state.fomopay_payment_enable === 1 && (
                            <a
                              href="/"
                              className="button"
                              title={language.checkoutnow}
                              id="fomopay_btn"
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "Fomopay"
                              )}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.paymentmodevalue == "2C2P" &&
                          this.state.enabletwoctwop === 1 &&
                          this.state.twoctwopMerchantID !== "" && (
                            <a
                              href="/"
                              className="button"
                              title={language.checkoutnow}
                              id="2cep_btn"
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "2C2P"
                              )}
                            >
                              {language.checkoutnow}
                            </a>
                          )}

                        {this.state.paymentmodevalue == "ALLINPAY" &&
                          this.state.enableallinpay === 1 &&
                          this.state.allinpayMerchantID !== "" && (
                            <a
                              href="/"
                              className="button"
                              title={language.checkoutnow}
                              id="allinpay_btn"
                              onClick={this.orderPayValidaion.bind(
                                this,
                                "ALLINPAY"
                              )}
                            >
                              {language.checkoutnow}
                            </a>
                          )}
                      </div>
                    )}

                  {this.state.paymentmodevalue === "promotion" &&
                    parseFloat(payAmount) === 0 &&
                    subTotalAmount > 0 && (
                      <div className="chk-payment-btn-row">
                        <a
                          href="/"
                          className="button"
                          title={language.checkoutnow}
                          onClick={this.postPromotionOrder.bind(this)}
                        >
                          {language.checkoutnow}
                        </a>
                      </div>
                    )}
                </div>
              </div>
              {/* chk-payment-btn div - end */}
            </div>
          </div>
          {/* check_out_fourcommon - end */}
        </div>
        {/* container - end */}

        {/*Payment confirm popup Start*/}
        <div
          id="pay-conf-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.alert}</div>
                <div className="alert_body">
                  <p>{language.awesomeallset}</p>
                  <div className="alt_btns">
                    {this.state.globalSettings.stripe_public_key &&
                      cookie.load("UserMobile") != "" &&
                      this.state.paymentmodevalue == "Stripe" &&
                      this.state.stripe_payment_enable === 1 && (
                        <a
                          href={void 0}
                          onClick={this.showloaderStripe.bind(this)}
                          className="button proceedpayment"
                          title={language.proceedtopayment}
                        >
                          {language.proceedtopayment}
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment confirm popup - End*/}

        {/*Payment omise confirm popup Start*/}
        <div
          id="pay-omiseconf-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">{language.somethingwrong}</p>
            </div>
          </div>

          {this.state.globalSettings.omise_envir === "dev" && (
            <div className="omisepay-mode-option">{language.testmode}</div>
          )}

          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img
                      className="makisan-logo-img"
                      src={stripeImage}
                      alt={companyname}
                    />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                <div className="omise_pay_body">
                  <div className="omisepop_in">
                    <div className="form_sec">
                      <div className={"uname-box " + this.state.omisenameerror}>
                        <i className="fa fa-user" data-unicode="f007"></i>
                        <input
                          type="text"
                          id="holdername"
                          className="form-control"
                          placeholder={language.cardholdername}
                          value={this.state.holdername}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "holdername"
                          )}
                        />
                      </div>

                      <div
                        className={"card-numbr-div " + this.state.omisecardrror}
                      >
                        <i
                          className="fa fa-credit-card"
                          data-unicode="f09d"
                        ></i>
                        <input
                          type="text"
                          className={"form-control " + this.state.cardImage}
                          placeholder={language.cardnumber}
                          value={this.state.cardNumber}
                          id="omise_card_number"
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "cardNumber"
                          )}
                        />
                        <span className="card"></span>
                      </div>

                      <div className="form_expire_row">
                        <div className="expire_row_inner">
                          <div
                            className={
                              "expire_left " + this.state.omisemontherror
                            }
                          >
                            <i
                              className="fa fa-calendar-o"
                              data-unicode="f133"
                            ></i>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              id="expiration_month"
                              placeholder={language.mm}
                              value={this.state.expiration_month}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_month"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_mdl " + this.state.omiseyearerror
                            }
                          >
                            <input
                              type="text"
                              placeholder={language.yyyy}
                              className="form-control"
                              maxLength="4"
                              id="expiration_year"
                              value={this.state.expiration_year}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "expiration_year"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_right " + this.state.omisecodeerror
                            }
                          >
                            <i className="fa fa-lock" data-unicode="f023"></i>
                            <input
                              type="password"
                              maxLength="3"
                              className="form-control"
                              id="security_code"
                              placeholder={language.cvv}
                              value={this.state.security_code}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "security_code"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="alt_btns">
                        {this.state.globalSettings.omise_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Omise" &&
                          this.state.omise_payment_enable === 1 && (
                            <button
                              onClick={this.getOmiseToken.bind(this)}
                              className="button omise-pay-btn"
                            >
                              {language.pay} {stripeCurrency}{" "}
                              {parseFloat(payAmount).toFixed(2)}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment omise confirm popup - End*/}

        {/*Payment confirm popup Start*/}
        <div
          id="pay-stripe-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.alert}</div>
                <div className="alert_body">
                  <p>{language.awesomeallset}</p>
                  <div className="alt_btns">
                    <div className="paymentsection cccard_paysec">
                      <div className="we-accept">
                        <span>{language.weaccept}</span>
                        <img src={paybystripe} />
                      </div>
                      <div className="stripe_from">
                        {this.state.globalSettings
                          .stripe_express_connect_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "StripeConnect" &&
                          this.state.stripe_express_payment_enable === 1 &&
                          parseFloat(payAmount) > 0 &&
                          typeof this.state.clientSecret != "undefined" &&
                          this.state.clientSecret != "" && (
                            <Elements options={options} stripe={stripePromise}>
                              <CheckoutForm />
                            </Elements>
                          )}
                      </div>
                    </div>
                    <div
                      className="paymentloading loading"
                      style={{ display: "none" }}
                    >
                      <img src={loadingImage} />{" "}
                      <span className="loading_text">
                        {language.proccesing}...
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment confirm popup - End*/}

        <div
          id="pay-stripe-embeded"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          {this.state.globalSettings.stripe_public_key &&
            this.state.stripeEmbeddedCheckoutSecret && (
              <EmbeddedCheckoutProvider
                stripe={loadStripe(this.state.globalSettings.stripe_public_key)}
                options={{
                  clientSecret: this.state.stripeEmbeddedCheckoutSecret,
                  onComplete: () => {
                    $.magnificPopup.close();
                    $.magnificPopup.open({
                      items: {
                        src: ".processing",
                      },
                      type: "inline",
                      midClick: true,
                      closeOnBgClick: false,
                    });
                    this.postOrder(3, "", "", "Stripe");
                  },
                }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            )}
        </div>

        {/* Koomipay payment confirm popup Start */}
        <div
          id="pay-koomipay-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup koomipay-popup"
        >
          {this.state.showKoomipayCheckout && (
            <KoomipayCheckout
              clientKey={this.state.globalSettings.koomipay_client_key}
              paymentAmount={payAmount}
              order={this.state.validatedOrder}
              onComplete={({ paymentID } = {}) => {
                $.magnificPopup.open({
                  items: {
                    src: ".processing",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                });
                this.postOrder(3, "", paymentID || "", "Koomipay");
              }}
            />
          )}
        </div>
        {/* Koomipay payment confirm popup End */}

        {/*Payment omise Pay Now confirm popup Start*/}
        <div
          id="pay-ocbcconf-popup"
          className="white-popup mfp-hide popup_sec pay-omisepaynowconf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                {Object.keys(this.state.ocbcResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_in">
                        <p className="omise-pay-now-notes">
                          {language.clickbuttonpay}
                        </p>
                        <p className="omise-paynow-amount-to-paid">
                          <span>$ {this.state.ocbcAmount.toFixed("2")}</span>
                        </p>
                        <a
                          className="button order_nowdiv omise_link smiple_product_lk"
                          href={this.state.ocbclink}
                        >
                          {language.paynow}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}

        {/*Payment xendit confirm popup Start*/}
        <div
          id="pay-xendit-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup pay-xendit-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">{language.somethingwrong}</p>
            </div>
          </div>
          {this.state.globalSettings.client_xendit_envir === "dev" && (
            <div className="omisepay-mode-option">{language.testmode}</div>
          )}
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img
                      className="makisan-logo-img"
                      src={stripeImage}
                      alt={companyname}
                    />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>

                <div className="omise_pay_body xendit_pay_body">
                  <div className="omisepop_in">
                    <div className="form_sec">
                      <div
                        className={"uname-box " + this.state.xenditnameerror}
                      >
                        <i className="fa fa-user" data-unicode="f007"></i>
                        <input
                          type="text"
                          id="xenditholdername"
                          className="form-control"
                          placeholder={language.cardholdername}
                          value={this.state.xenditholdername}
                          onChange={this.handleXenditChangeTxt.bind(
                            this,
                            "xenditholdername"
                          )}
                        />
                      </div>
                      <div
                        className={
                          "card-numbr-div " + this.state.xenditcardrror
                        }
                      >
                        <i
                          className="fa fa-credit-card"
                          data-unicode="f09d"
                        ></i>
                        <input
                          type="text"
                          className={"form-control " + this.state.cardImage}
                          placeholder={language.cardnumber}
                          value={this.state.xenditCardNumber}
                          id="omise_card_number"
                          onChange={this.handleXenditChangeTxt.bind(
                            this,
                            "xenditCardNumber"
                          )}
                        />
                        <span className="card"></span>
                      </div>
                      <div className="form_expire_row">
                        <div className="expire_row_inner">
                          <div
                            className={
                              "expire_left " + this.state.xenditmontherror
                            }
                          >
                            <i
                              className="fa fa-calendar-o"
                              data-unicode="f133"
                            ></i>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              id="xendit_expiration_month"
                              placeholder={language.mm}
                              value={this.state.xendit_expiration_month}
                              onChange={this.handleXenditChangeTxt.bind(
                                this,
                                "xendit_expiration_month"
                              )}
                            />
                          </div>
                          <div
                            className={
                              "expire_mdl " + this.state.xendityearerror
                            }
                          >
                            <input
                              type="text"
                              placeholder={language.yyyy}
                              className="form-control"
                              maxLength="4"
                              id="xendit_expiration_year"
                              value={this.state.xendit_expiration_year}
                              onChange={this.handleXenditChangeTxt.bind(
                                this,
                                "xendit_expiration_year"
                              )}
                            />
                          </div>
                          <div
                            className={
                              "expire_right " + this.state.xenditcodeerror
                            }
                          >
                            <i className="fa fa-lock" data-unicode="f023"></i>
                            <input
                              type="password"
                              maxLength="3"
                              className="form-control"
                              id="xendit_security_code"
                              placeholder={language.cvv}
                              value={this.state.xendit_security_code}
                              onChange={this.handleXenditChangeTxt.bind(
                                this,
                                "xendit_security_code"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="alt_btns">
                        {this.state.globalSettings.client_xendit_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Xendit" &&
                          this.state.xendit_payment_enable === 1 && (
                            <button
                              onClick={this.getXenditToken.bind(this)}
                              className="button xendit-pay-btn"
                            >
                              {language.pay} {stripeCurrency}{" "}
                              {parseFloat(payAmount).toFixed(2)}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div id="three-ds-container">
                  <iframe name="sample-inline-frame"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment xendit confirm popup - End*/}

        {/*Payment xendit Gcash confirm popup Start*/}
        <div
          id="pay-gcash-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup pay-gcash-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">{language.somethingwrong}</p>
            </div>
          </div>
          {this.state.globalSettings.client_xendit_envir === "dev" && (
            <div className="omisepay-mode-option">
              {language.somethingwrong}
            </div>
          )}
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img
                      className="makisan-logo-img"
                      src={stripeImage}
                      alt={companyname}
                    />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                <div className="omise_pay_body xendit_pay_body">
                  <div className="omisepop_in">
                    <img src={gcash} id="paynow-qr-image" />
                    <div className="form_sec">
                      <div className="alt_btns">
                        {this.state.globalSettings.client_xendit_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "Gcash" &&
                          this.state.xendit_payment_enable === 1 && (
                            <>
                              <button
                                onClick={this.getXenditEwallet.bind(
                                  this,
                                  "gcash"
                                )}
                                className="button xendit-pay-btn ewallet-btn"
                              >
                                {language.pay} {stripeCurrency}{" "}
                                {parseFloat(payAmount).toFixed(2)}
                              </button>

                              <button
                                onClick={this.removePopup.bind(this)}
                                className="button xendit-pay-btn"
                              >
                                {language.cancel}
                              </button>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment xendit Gcash confirm popup - End*/}

        {/*Payment xendit paymaya confirm popup Start*/}
        <div
          id="pay-paymaya-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup pay-paymaya-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">{language.somethingwrong}</p>
            </div>
          </div>
          {this.state.globalSettings.client_xendit_envir === "dev" && (
            <div className="omisepay-mode-option">{language.testmode}</div>
          )}
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img
                      className="makisan-logo-img"
                      src={stripeImage}
                      alt={companyname}
                    />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                <div className="omise_pay_body xendit_pay_body">
                  <div className="omisepop_in">
                    <img src={paymaya} id="paynow-qr-image" />
                    <div className="form_sec">
                      <div className="alt_btns">
                        {this.state.globalSettings.client_xendit_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "paymaya" &&
                          this.state.xendit_payment_enable === 1 && (
                            <>
                              <button
                                onClick={this.getXenditEwallet.bind(
                                  this,
                                  "paymaya"
                                )}
                                className="button xendit-pay-btn ewallet-btn"
                              >
                                {language.pay} {stripeCurrency}{" "}
                                {parseFloat(payAmount).toFixed(2)}
                              </button>

                              <button
                                onClick={this.removePopup.bind(this)}
                                className="button xendit-pay-btn"
                              >
                                {language.cancel}
                              </button>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment xendit Gcash confirm popup - End*/}

        {/*Payment omise Pay Now confirm popup Start*/}
        <div
          id="pay-omisepaynowconf-popup"
          className="white-popup mfp-hide popup_sec pay-omisepaynowconf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                {Object.keys(this.state.omisePayNowResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_in">
                        <p className="omise-pay-now-notes">
                          {language.scanqrcodepay}
                        </p>
                        {this.state.omisePayNowResponse.download_uri !== "" && (
                          <img
                            src={this.state.omisePayNowResponse.download_uri}
                            id="paynow-qr-image"
                          />
                        )}
                        <p className="omise-paynow-amount-to-paid">
                          <span>
                            $ {this.state.omisepaynowAmount.toFixed("2")}
                          </span>
                        </p>
                        <p className="paynow-seconds">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>&nbsp;{this.state.omisePaynowTime} sec</span>
                        </p>
                        <div className="paynow-notes">
                          <h2>Instructions:</h2>
                          <ul>
                            {appId ===
                              "0A12F35E-5D3E-4A6F-892C-F4DD05AF230A" && (
                              <li>
                                <img src={backImg} />
                                {language.paynwoins}
                              </li>
                            )}

                            <li>
                              <img src={backImg} />
                              {language.paynwoins1}
                            </li>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins2}
                            </li>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins3}
                            </li>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins4}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}

        {/*Payment omise confirm popup Start*/}
        <div
          id="pay-omisechainconf-popup"
          className="white-popup mfp-hide popup_sec pay-omiseconf-popup"
        >
          <div
            className="omise-error-info-div"
            id="omise-chain-error-info-div"
            data-timeoutid=""
            style={{ display: "none" }}
          >
            <div className="container">
              <span className="omise-error-icon">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </span>
              <a
                href="#"
                className="omise_alert_close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={(evt) => {
                  evt.preventDefault();
                }}
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </a>
              <p className="omise-error-msg">{language.somethingwrong}</p>
            </div>
          </div>

          {this.state.globalSettings.omise_envir === "dev" && (
            <div className="omisepay-mode-option">{language.testmode}</div>
          )}

          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img
                      className="makisan-logo-img"
                      src={stripeImage}
                      alt={companyname}
                    />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                <div className="omise_pay_body">
                  <div className="omisepop_in">
                    <div className="form_sec">
                      <div
                        className={
                          "uname-box " + this.state.omise_chain_nameerror
                        }
                      >
                        <i className="fa fa-user" data-unicode="f007"></i>
                        <input
                          type="text"
                          id="chain_holdername"
                          className="form-control"
                          placeholder={language.cardholdername}
                          value={this.state.chain_holdername}
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "chain_holdername"
                          )}
                        />
                      </div>

                      <div
                        className={
                          "card-numbr-div " + this.state.omise_chain_cardrror
                        }
                      >
                        <i
                          className="fa fa-credit-card"
                          data-unicode="f09d"
                        ></i>
                        <input
                          type="text"
                          className={"form-control " + this.state.cardImage}
                          placeholder={language.cardnumber}
                          value={this.state.chain_cardNumber}
                          id="omise_chain_card_number"
                          onChange={this.handleChangeTxt.bind(
                            this,
                            "chain_cardNumber"
                          )}
                        />
                        <span className="card"></span>
                      </div>

                      <div className="form_expire_row">
                        <div className="expire_row_inner">
                          <div
                            className={
                              "expire_left " + this.state.omise_chain_montherror
                            }
                          >
                            <i
                              className="fa fa-calendar-o"
                              data-unicode="f133"
                            ></i>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              id="chain_expiration_month"
                              placeholder={language.mm}
                              value={this.state.chain_expiration_month}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "chain_expiration_month"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_mdl " + this.state.omise_chain_yearerror
                            }
                          >
                            <input
                              type="text"
                              placeholder={language.yyyy}
                              className="form-control"
                              maxLength="4"
                              id="chain_expiration_year"
                              value={this.state.chain_expiration_year}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "chain_expiration_year"
                              )}
                            />
                          </div>

                          <div
                            className={
                              "expire_right " + this.state.omise_chain_codeerror
                            }
                          >
                            <i className="fa fa-lock" data-unicode="f023"></i>
                            <input
                              type="password"
                              maxLength="3"
                              className="form-control"
                              id="chain_security_code"
                              placeholder={language.cvv}
                              value={this.state.chain_security_code}
                              onChange={this.handleChangeTxt.bind(
                                this,
                                "chain_security_code"
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="alt_btns">
                        {this.state.omise_chain_public_key &&
                          cookie.load("UserMobile") != "" &&
                          this.state.paymentmodevalue == "OmiseChain" &&
                          this.state.omise_chain_payment_enable === 1 && (
                            <button
                              onClick={this.getOmiseChainToken.bind(this)}
                              className="button omise-pay-btn"
                            >
                              {language.pay} {stripeCurrency}{" "}
                              {parseFloat(payAmount).toFixed(2)}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*payment omise confirm popup - End*/}

        {/*Payment omise Pay Now confirm popup Start*/}
        <div
          id="pay-omisepaynowchainconf-popup"
          className="white-popup mfp-hide popup_sec pay-omisepaynowchainconf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <span className="omise-pay-img">
                    <img className="makisan-logo-img" src={stripeImage} />
                  </span>
                  <span className="omise-pay-title">{companyname}</span>
                </div>
                {Object.keys(this.state.omisePayNowResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_in">
                        <p className="omise-pay-now-notes">
                          {language.scanqrcodepay}
                        </p>
                        {this.state.omisePayNowResponse.download_uri !== "" && (
                          <img
                            src={this.state.omisePayNowResponse.download_uri}
                            id="paynow-qr-image"
                          />
                        )}
                        <p className="omise-paynow-amount-to-paid">
                          <span>
                            $ {this.state.omisepaynowAmount.toFixed("2")}
                          </span>
                        </p>
                        <p className="paynow-seconds">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>
                            &nbsp;{this.state.omisePaynowTime} {language.sec}
                          </span>
                        </p>
                        <div className="paynow-notes">
                          <h2>{language.instructions}:</h2>
                          <ul>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins1}
                            </li>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins2}
                            </li>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins3}
                            </li>
                            <li>
                              <img src={backImg} />
                              {language.paynwoins4}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}

        {/*2C2P Payment confirm popup Start*/}
        <div
          id="pay-conf-2c2p-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.alert}</div>
                <div className="alert_body">
                  <p>{language.awesomeallset}</p>
                  <div className="alt_btns">
                    {this.state.paymentmodevalue == "2C2P" &&
                      this.state.enabletwoctwop === 1 &&
                      this.state.twoctwopMerchantID !== "" && (
                        <a
                          href="/"
                          className="button"
                          id="proceed2c2ppayment"
                          onClick={this.orderProceedTwoCTwoP.bind(this)}
                          title={language.proceedtopayment}
                        >
                          {language.proceedtopayment}
                        </a>
                      )}
                    <div id="two-c-two-p-div" style={{ display: "none" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*2C2P payment confirm popup - End*/}

        {/*Allinpay Payment confirm popup Start*/}
        <div
          id="pay-conf-allinpay-popup"
          className="white-popup mfp-hide popup_sec pay-conf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">{language.alert}</div>
                <div className="alert_body">
                  <p>{language.awesomeallset}</p>
                  <div className="alt_btns">
                    {this.state.paymentmodevalue == "ALLINPAY" &&
                      this.state.enableallinpay === 1 &&
                      this.state.allinpayMerchantID !== "" && (
                        <a
                          href="/"
                          className="button"
                          id="proceedallinpaypayment"
                          onClick={this.orderProceedAllinpay.bind(this)}
                          title={language.proceedtopayment}
                        >
                          {language.proceedtopayment}
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Allinpay payment confirm popup - End*/}

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{language.processingorder}</h3>
            <div className="process_inner">
              {this.onlinePaymentLoading()}
              {this.orderBCLoading()}
              {this.amountCaptureLoading()}
            </div>
          </div>
        </div>

        <div id="dvLoading"></div>

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  var product_lead_time = 0;

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      product_lead_time = resultSetArr.product_lead_time;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var activityCountArr = Array();
  if (Object.keys(state.activitycount).length > 0) {
    if (state.activitycount[0].status === "ok") {
      activityCountArr = state.activitycount[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var addonProArr = Array();
  var addonCommonArr = Array();
  if (Object.keys(state.addonproduct).length > 0) {
    if (
      state.addonproduct[0].status === "ok" &&
      Object.keys(state.addonproduct[0].result_set).length > 0
    ) {
      addonProArr = state.addonproduct[0].result_set;
      addonCommonArr = state.addonproduct[0].common;
    }
  }

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  return {
    activitycountArr: activityCountArr,
    settingsArr: globalSettings,
    staticblack: blacksArr,
    zonedetails: zonedetailArr,
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    addonproductlist: addonProArr,
    addonproductcommon: addonCommonArr,
    product_lead_time: product_lead_time,
    updateCartResponse: updateCartResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getAddonProList: (outletId) => {
      dispatch({ type: GET_ADDONPRODUCT, outletId });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Checkout);
