import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import qs from "querystring";
import { language } from "../Helpers/Config";

function KoomipayComplete(props) {
  const { location } = props;
  const query = qs.parse(location && location.search.slice(1));

  useEffect(() => {
    if (typeof window !== "undefined" && window.top) {
      window.top.postMessage({
        type: "3DS-complete",
        ...query,
      });
    }
  }, [query]);

  return (
    <div className="w-full px-4 py-3 mt-8 text-lg font-bold text-center">
      {language.processing}...
    </div>
  );
}

export default withRouter(KoomipayComplete);
