/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import MenuNavigationStick from "./MenuNavigationStick";
import ProductList from "./ProductList";
import MenuDNavigation from "./MenuDNavigation";

import {
  cateringId,
  deliveryId,
  apiUrl,
  appId,
  language,
} from "../Helpers/Config";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_STATIC_BLOCK,
  GET_ZONE_DETAIL,
} from "../../actions";
const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedNavigationFilter: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      productload: "Yes",
      cartList: [],
      cartItemsLength: 0,
      selectedMainCatId:
        cookie.load("selectedMainCatId") !== "" &&
        cookie.load("selectedMainCatId") !== undefined &&
        cookie.load("selectedMainCatId") !== "undefined"
          ? cookie.load("selectedMainCatId")
          : "",
      selectedMainCat:
        cookie.load("selectedMainCat") !== "" &&
        cookie.load("selectedMainCat") !== undefined &&
        cookie.load("selectedMainCat") !== "undefined"
          ? cookie.load("selectedMainCat")
          : "",
      startFilter: "No",
      sort_By_PriceName: "Sort By",
      sort_By_Price: "",
      stopFilterPrice: "No",
      showShortByPrice: false,
      showShortByCategory: false,
      showShortByTag: false,
      showShortByBrandTag: false,
      searchKeyWord:
        cookie.load("searchKeyWord") !== "" &&
        cookie.load("searchKeyWord") !== undefined
          ? cookie.load("searchKeyWord")
          : "",
      emptysearchKey: 0,
      proTagList: [],
      brandTagList: [],
      filterTag: [],
      setFiltetTag: "No",
      filterBrandTag: [],
      setFiltetBrandTag: "No",
      staticblacks: [],
      prodDescr: "",
    };

    cookie.remove("selectedMainCat", { path: "/" });
    cookie.remove("selectedMainCatId", { path: "/" });

    var site_maintenance_verify =
      cookie.load("site_maintenance_verify") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_verify") !== undefined
        ? cookie.load("site_maintenance_verify")
        : "";

    var site_maintenance_popup_open =
      cookie.load("site_maintenance_popup_open") !== "" &&
      cookie.load("site_maintenance_verify") !== "undefined" &&
      cookie.load("site_maintenance_popup_open") !== undefined
        ? cookie.load("site_maintenance_popup_open")
        : "";

    if (
      site_maintenance_popup_open === "1" &&
      (site_maintenance_verify === "" || site_maintenance_verify === "0")
    ) {
      this.props.history.push("/");
      return;
    }

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      this.props.history.push("/");
      return;
    }

    var outltIdTxt = cookie.load("orderOutletId");

    var orderOutletId = cookie.load("orderOutletId");
    var home_page_add_to_cart_availability = cookie.load(
      "home_page_add_to_cart_availability"
    );
    console.log(
      home_page_add_to_cart_availability,
      "home_page_add_to_cart_availability"
    );

    if (
      home_page_add_to_cart_availability !== "" &&
      home_page_add_to_cart_availability === "1"
    ) {
      cookie.save("orderPopuptrigger", "No", { path: "/" });
    } else {
      if (orderOutletId === "" || orderOutletId === undefined) {
        cookie.save("orderPopuptrigger", "Yes", { path: "/" });
        this.props.history.push("/");
        return;
      }
    }

    this.props.getSettings();
    var availbty = cookie.load("defaultAvilablityId");

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
    this.props.getMenuNavigationList(outltIdTxt);

    this.loadTagList();
    this.loadBrandTagList();
    this.props.getStaticBlock();
  }

  componentDidMount() {
    // showLoader("productlist-main-div", "class");
    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });
    $(".dvLoadrCls").fadeOut(2000);

    // $('html, body').animate({scrollTop: $(".productlist-main-div").offset().top - 122 }, 2000);
    // $(window).scrollTo($(".productlist-main-div"), 100);

    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();
        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });

    let myInterval = setInterval(() => {
      if ($(".products-list-ulmain li").length > 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    var currentthis = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".sort_by_price, .sort_by_price * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filter_by_tag, .filter_by_tag * ")) {
        currentthis.setState({ showShortByTag: false });
      }
      if (!$(e.target).is(".filter_by_brand_tag, .filter_by_brand_tag * ")) {
        currentthis.setState({ showShortByBrandTag: false });
      }
    });

    $(".product_search_result").hide();
  }

  componentDidUpdate() {
    // $(window).scrollTo($(".pantry-category-parent"), 0);
    // $('html, body').animate({scrollTop: $(".productlist-main-div").offset().top - 122 }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.staticblack !== this.state.staticblacks) {
      var prodDescr = "";

      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "product-page-description") {
            prodDescr = data.staticblocks_description;
            return "";
          }
        });
      }
      prodDescr =
        prodDescr !== "" && prodDescr !== null ? Parser(prodDescr) : prodDescr;

      this.setState({
        staticblacks: nextProps.staticblack,
        prodDescr: prodDescr,
      });
    }

    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
      this.setState({
        startFilter: "Yes",
        selectedNavigationFilter: "",
        sort_By_PriceName: "Sort By",
        sort_By_Price: "",
        showShortByPrice: false,
      });
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        /* setTimeout(function () {
          $(window).scrollTo($("." + proValue), 100);
        }, 2000); */
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      if ($(".productlist-main-div.loader-main-cls").length === 0) {
        // showLoader("productlist-main-div", "class");
      }
      if (proValue === "") {
      }

      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        if (navIndex > 0) {
          var categoryNameTxt =
            Object.keys(nextProps.navigateMenu).length > 0
              ? nextProps.navigateMenu[navIndex].category_name
              : nextProps.selectedCatry;
        }
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
      });
    }
  }

  loadTagList() {
    axios
      .get(apiUrl + "products/product_tags?app_id=" + appId)
      .then((result) => {
        if (result.data.status === "ok") {
          this.setState({ proTagList: result.data.result_set });
        }
      });
  }
  loadBrandTagList() {
    axios.get(apiUrl + "products/brand_tag?app_id=" + appId).then((result) => {
      if (result.data.status === "ok") {
        this.setState({ brandTagList: result.data.result_set });
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
    if (field === "setFiltetBrandTag" && value !== "") {
      this.setState({
        setFiltetBrandTag: value,
      });
    }
    if (field === "cartList") {
      this.setState({ cartList: value });
    }
    if (field === "cartItemsLength") {
      this.setState({ cartItemsLength: value });
    }
    if (field === "categorySlug") {
      this.setState({ selectedNavigationFilter: value });
    }
    if (field === "startFilter") {
      this.setState({ startFilter: value });
    }
    if (field === "stopFilterPrice") {
      this.setState({ stopFilterPrice: value });
    }
    if (field === "emptysearchKey") {
      this.setState({ emptysearchKey: value });
    }

    if (field === "selectedMainCat") {
      this.setState({ selectedMainCat: value });
      cookie.save("selectedMainCat", value, { path: "/" });
    }

    if (field === "selectedMainCatId") {
      this.setState({ selectedMainCatId: value });
      cookie.save("selectedMainCatId", value, { path: "/" });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    // showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  handleChange(section, event) {
    let filterTag = section.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    section.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }
  selectMainCategory(selectedMainCat, selectedMainCatId, event) {
    event.preventDefault();
    cookie.save("selectedMainCat", selectedMainCat, { path: "/" });
    cookie.save("selectedMainCatId", selectedMainCatId, { path: "/" });
    this.setState(
      {
        selectedMainCat: selectedMainCat,
        selectedMainCatId: selectedMainCatId,
      },
      function () {
        this.props.history.push($(".owl-stage a").attr("href"));
      }
    );
  }

  applyCategoryFilter(nav_data, event) {
    event.preventDefault();
    if (nav_data) {
      this.setState({ showShortByCategory: false });
      let cat_url = "/menu/category/" + nav_data.pro_cate_slug;
      this.props.history.push(cat_url);
    }
  }

  sortByPrice(sort, event) {
    event.preventDefault();
    var sort_By_PriceName = "";
    if (sort === "ASC") {
      sort_By_PriceName = "Price Low to High";
    } else {
      sort_By_PriceName = "Price High to Low";
    }
    this.setState({
      sort_By_Price: sort,
      sort_By_PriceName: sort_By_PriceName,
      stopFilterPrice: "Yes",
    });
  }
  applyTagFilter(tag_id, event) {
    event.preventDefault();
    var filterTag = this.state.filterTag;
    if (filterTag.indexOf(tag_id) >= 0) {
      var index = filterTag.indexOf(tag_id);
      filterTag.splice(index, 1);
    } else {
      filterTag.push(tag_id);
    }
    this.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }
  applyBrandTagFilter(tag_id, event) {
    event.preventDefault();
    var filterBrandTag = this.state.filterBrandTag;
    if (filterBrandTag.indexOf(tag_id) >= 0) {
      var index = filterBrandTag.indexOf(tag_id);
      filterBrandTag.splice(index, 1);
    } else {
      filterBrandTag.push(tag_id);
    }
    this.setState({ filterBrandTag: filterBrandTag, setFiltetBrandTag: "Yes" });
  }

  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({
          showShortByPrice: true,
          showShortByCategory: false,
          showShortByTag: false,
        });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({
          showShortByCategory: true,
          showShortByPrice: false,
          showShortByTag: false,
        });
      }
    } else if (type === "tag") {
      if (this.state.showShortByTag === true) {
        this.setState({ showShortByTag: false });
      } else {
        this.setState({
          showShortByTag: true,
          showShortByCategory: false,
          showShortByPrice: false,
        });
      }
    } else if (type === "brandtag") {
      if (this.state.showShortByBrandTag === true) {
        this.setState({ showShortByBrandTag: false });
      } else {
        this.setState({
          showShortByBrandTag: true,
          showShortByTag: false,
          showShortByCategory: false,
          showShortByPrice: false,
        });
      }
    }
  }
  searchProKeyPress = (event) => {
    this.setState({ searchKeyWord: event.target.value });
    if (event.target.value === "") {
      this.setState({ emptysearchKey: 1 });
    }
  };

  render() {
    var categoryNav = "";
    let navigateCommon = "";
    if (this.props.navigateMenu.length > 0) {
      navigateCommon = this.props.navigateCommon;
      if (
        this.state.catNavIndex !== "" &&
        typeof this.state.catNavIndex !== undefined &&
        typeof this.state.catNavIndex !== "undefined"
      ) {
        if (
          typeof this.props.navigateMenu[this.state.catNavIndex] !==
            undefined &&
          typeof this.props.navigateMenu[this.state.catNavIndex] !== "undefined"
        ) {
          categoryNav = this.props.navigateMenu[this.state.catNavIndex];
        }
      }
    }
    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        {/* Header End */}

        <section className="product-cms-content">
          <div className="product-cms-content-top">
            <div className="container">
              <h3>{language.menu}</h3>
              {this.state.prodDescr}
            </div>
          </div>
          <div className="product-cms-content-middle">
            <div className="container">
              <div className="normal-menu-nav" id="normal_view">
                <div className="product-cconmid-rhs">
                  <MenuDNavigation
                    {...this.props}
                    productState={this.state}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>
              <div className="sticky-menu-nav" id="responsive_view">
                <MenuNavigationStick
                  {...this.props}
                  productState={this.state}
                  sateValChange={this.sateValChange}
                  searchFunction={this.searchProKeyPress}
                />
              </div>
            </div>
          </div>
          <div className="menu-nav-section sticky-menu-nav">
            <div className="product-menu">
              <div className="container">
                <MenuNavigation
                  {...this.props}
                  productState={this.state}
                  sateValChange={this.sateValChange}
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="product-menu-listing"
          ref={(ref) => (this.myRef = ref)}
        >
          <div className="container">
            <div className="pantry-category-parent">
              <ProductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
              />
            </div>
          </div>
        </section>
        <div id="dvLoading" className="dvLoadrCls"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.productmenunav).length > 0) {
    var tempArr = !("menuNavigation" in state.productmenunav[0])
      ? Array()
      : state.productmenunav[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: (outletID) => {
      dispatch({ type: GET_MENU_NAVIGATION, outletID });
    },
    getOutletCategories: () => {
      dispatch({ type: GET_OUTLETCATEGORIES });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
