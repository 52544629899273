const tempDynamicText = {
  "Our Story": {
    zh: "我们的故事",
  },
  Promotions: {
    zh: "促销",
  },
  Blog: {
    zh: "博客",
  },
  FAQ: {
    zh: "常问问题",
  },
  "Contact Us": {
    zh: "联系我们",
  },
  "Delivery": {
    zh: "送货"
  },
  "Pickup": {
    zh: "捡起"
  },
  "Sort By": {
    zh: "排序方式"
  },
  "Price Low to High": {
    zh: "价格从低到高"
  },
  "Price High to Low": {
    zh: "价格从高到低"
  },
  "Shop By": {
    zh: "类别"
  }
}

module.exports = {
  homeMetaTitle: "Muthu`s Curry",
  dynamicTextTranslate(text, currentLang) {
    if (currentLang !== "en" && tempDynamicText[text] && tempDynamicText[text][currentLang]) {
      return tempDynamicText[text][currentLang]
    }
    console.log(text)
    return text
  },
}
